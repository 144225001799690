import React, { useEffect, useState } from "react";

import axios from "axios";

import { useReducer } from "react";
import { Divider } from "antd";
import NotSubscribedPopUp from "./NotSubscribedPopUp";
import { Paper } from "@material-ui/core";
import { Category, Class, Save } from "@material-ui/icons";

// const API_URL = "http://localhost:8000/demographic/";
const API_URL = "https://data.merd.online:8000/demographic/";

const excelIcon = require("../../img/excel-icon.png");

const ACTIONS = {
  KNOW_FILE: "know-file",
};

function downloadBlob(blob, name) {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  console.log(blobUrl);
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

function handleDownload(table_name, filename) {
  axios
    .get(`${API_URL}?table_name=${table_name}`, {
      responseType: "blob",
    })
    .then((res) => {
      // const blob = new Blob([res.data], { type: "application/pdf" });
      // this.downloadBlob(blob, "test.pdf");
      console.log(res);
      downloadBlob(res.data, filename);
    })
    .catch((err) => {
      console.log(err);
    });
}

function getNames(shortname, type) {
  if (type === "type") {
    switch (shortname) {
      case "income":
        return "Income Levels";

      case "population":
        return "Population";
      case "age":
        return "Age Distribution";
      default:
        throw "invalid type";
    }
  } else if (type === "mode") {
    switch (shortname) {
      case "nat":
        return "By Nationality";

      case "zone":
        return "By Zone";
      case "zonenat":
        return "By Nationality By Zone";
      default:
        throw "invalid mode";
    }
  }
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.KNOW_FILE:
      let [city, type, mode] = action.file.replace(".xlsx", "").split("_");
      return {
        city,
        type: getNames(type, "type"),
        mode: getNames(mode, "mode"),
      };
    default:
      throw "Invalid Action";
  }
}

export default function Card({ file, user, postObject, handleCancel }) {
  const [state, dispatch] = useReducer(reducer, {
    city: null,
    mode: null,
    type: null,
  });
  const [subPopUpOpen, setSubPopUpOpen] = useState(false);
  useEffect(() => {
    dispatch({ type: ACTIONS.KNOW_FILE, file });
  }, []);

  return (
    <Paper className="excel-card">
      <NotSubscribedPopUp
        open={subPopUpOpen}
        setOpen={setSubPopUpOpen}
        user={user}
        postObject={postObject}
        handleCancel={handleCancel}
      />
      <div
        className="save-btn w-full"
        onClick={
          user.username &&
          user.demographicCities.findIndex((city) => city === state.city) !== -1
            ? () =>
                handleDownload(
                  file,
                  `${state.city}_${state.type}_${state.mode}.xlsx`
                )
            : () => setSubPopUpOpen(true)
        }
      >
        <div className="flex items-center font-bold cursor-pointer rounded-lg bg-green-900 text-white ">
          <div className="flex items-center p-2 m-2">
            <Save fontSize="large" style={{ color: "#83fca8" }} />
          </div>
          Save As Excel
        </div>
      </div>
      <div className="w-full text-lg font-bold">
        <div className="flex items-center">
          <div className="flex items-center rounded-full bg-sky-100 p-2 m-2">
            <Category style={{ color: "#7703fc" }} />
          </div>
          {state.type}
        </div>

        <div className="flex items-center">
          <div className="flex items-center rounded-full bg-sky-100 p-2 m-2">
            <Class style={{ color: "#0599fc" }} />
          </div>
          {state.mode}
        </div>
      </div>
    </Paper>
  );
}
