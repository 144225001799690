import { useState } from "react";
import { Checkbox, Divider, Radio, Table } from "antd";
import { Modal } from "@material-ui/core";

import { v4 as uuid } from "uuid";
import { Paper } from "@material-ui/core";

import TitleHeader from "../TitleHeader";
import {
  ArrowRight,
  Close,
  NavigateNext,
  AvTimer,
  Poll,
} from "@material-ui/icons";
import ZoneWiseColumnChart from "./Charts/ZoneWiseColumnChart";
import { isArray, round } from "lodash";
import getExcel from "../../../utils/getExcel";

function MainTable({
  data,
  nationalities,
  year,
  monthsSelected,
  allCategories,
  allData,
  toolTipOpen,
  setToolTipOpen,
}) {
  console.log(allCategories);

  const [isMarketSize, setIsMarketSize] = useState(true);
  const [yearlyModalOpen, setYearlyModalOpen] = useState(false);
  const [catgModalOpen, setCatgModalOpen] = useState(false);
  const [comparisonType, setComparisonType] = useState(null);
  const [yearlyComparisonOptions, setYearlyComparisonOptions] = useState({
    years: allData.map((year) => year.year).slice(0, 2),
    category: allCategories[0]?.id,
  });
  const [catgComparisonOptions, setCatgComparisonOptions] = useState({
    year,
    categories: allCategories.map((cat) => cat.id).slice(0, 2),
  });
  const [errors, setErrors] = useState({});
  const columns = [
    {
      title: <h4 style={{ fontWeight: 700, textAlign: "center" }}>Region</h4>,
      dataIndex: "name",
      key: "region",
      titleText: "Region",
      rSpan: allCategories.length > 1 ? 2 : 1,

      width: "400",
      align: "center",
      fixed: "left",
      onCell: (cell) =>
        cell.name === "Total" ? { className: "region-last-row" } : "",
      render: (x) =>
        x ? <span style={{ fontWeight: "500 " }}>{x}</span> : "N/A",
    },
    {
      title: (
        <h4 style={{ fontWeight: 700, textAlign: "center" }}>Population</h4>
      ),
      dataIndex: "population",
      key: "population",
      align: "center",
      numFmt: "#,##0",
      titleText: "Population",
      rSpan: allCategories.length > 1 ? 2 : 1,

      render: (x) =>
        x ? (
          <span style={{ fontWeight: "500 " }}>
            {x.toLocaleString("en", { useGrouping: true })}
          </span>
        ) : (
          "N/A"
        ),
    },
    ...(allCategories.length > 1
      ? [
          {
            title: (
              <h4 style={{ fontWeight: 700, textAlign: "center" }}>
                Market Size (USD)
              </h4>
            ),
            dataIndex: "market_size",
            key: "market_size",
            titleText: "Market Size (USD)",
            cSpan: allCategories.length,
            children: allCategories.map((cat) => ({
              title: cat.name,
              numFmt: "$#,##0",
              titleText: cat.name,
              dataIndex: `ms_${cat.id}`,
              key: uuid(),
              align: "center",

              render: (x) =>
                x ? (
                  <span style={{ fontWeight: "500" }}>
                    ${x.toLocaleString("en", { useGrouping: true })}
                  </span>
                ) : (
                  "N/A"
                ),
            })),
          },
          {
            title: (
              <h4 style={{ fontWeight: 700, textAlign: "center" }}>
                Market Size/Capita (USD)
              </h4>
            ),
            titleText: "Market Size/Capita (USD)",
            key: "msCapita",
            cSpan: allCategories.length,

            children: allCategories.map((cat) => ({
              title: cat.name,
              titleText: cat.name,
              dataIndex: `mscap_${cat.id}`,
              key: uuid(),
              align: "center",

              style: { textAlign: "center" },
              render: (x) =>
                x ? (
                  <span style={{ fontWeight: "500" }}>
                    ${x.toLocaleString("en", { useGrouping: true })}
                  </span>
                ) : (
                  "N/A"
                ),
            })),
          },
        ]
      : [
          {
            title: (
              <h4 style={{ fontWeight: 700, textAlign: "center" }}>
                Market Size (USD)
              </h4>
            ),
            titleText: "Market Size (USD)",
            dataIndex: `ms_${allCategories[0].id}`,
            key: `ms_${allCategories[0].id}`,
            align: "center",
            numFmt: "$#,##0",

            render: (x) =>
              x ? (
                <span style={{ fontWeight: "500" }}>
                  ${x.toLocaleString("en", { useGrouping: true })}
                </span>
              ) : (
                "N/A"
              ),
          },
          {
            title: (
              <h4 style={{ fontWeight: 700, textAlign: "center" }}>
                Market Size/Capita (USD)
              </h4>
            ),
            numFmt: "$#,##0",

            titleText: " Market Size/Capita (USD)",
            key: `mscap_${allCategories[0].id}`,
            dataIndex: `mscap_${allCategories[0].id}`,
            align: "center",

            render: (x) =>
              x ? (
                <span style={{ fontWeight: "500" }}>
                  ${x.toLocaleString("en", { useGrouping: true })}
                </span>
              ) : (
                "N/A"
              ),
          },
        ]),
  ];
  console.log(data);

  function getDataSource(rawData) {
    const dataSource = [];
    let totalPopulation = 0;
    let totalMarketSize = {};
    let finalMSCap = {};
    for (let cat of allCategories) {
      totalMarketSize[`ms_${cat.id}`] = 0;
    }
    for (let zone of rawData.zones) {
      const idx = dataSource.findIndex(
        (region) => region.id === zone.region_id
      );
      if (idx === -1) {
        const newRegion = {
          id: zone.region_id,
          key: uuid(),
          name: zone.region_name,
          population: zone.population,
          zones: [zone],
        };
        for (let cat of allCategories) {
          const reqdCategory = zone.market_size_data.find(
            (category) => category.name === cat.name
          );
          if (reqdCategory) {
            newRegion[`ms_${cat.id}`] = round(reqdCategory.market_size, -3);
            totalMarketSize[`ms_${cat.id}`] += round(
              reqdCategory.market_size,
              -3
            );
          } else {
            newRegion[`ms_${cat.id}`] = 0;
          }
          newRegion[`mscap_${cat.id}`] = 0;
        }
        dataSource.push(newRegion);
      } else {
        const region = dataSource[idx];
        region.population += zone.population;
        for (let cat of allCategories) {
          const reqdCategory = zone.market_size_data.find(
            (category) => category.name === cat.name
          );
          if (reqdCategory) {
            region[`ms_${cat.id}`] += round(reqdCategory.market_size, -3);
            totalMarketSize[`ms_${cat.id}`] += round(
              reqdCategory.market_size,
              -3
            );
          }
        }
        region.zones.push(zone);
      }
    }

    for (let region of dataSource) {
      for (let cat of Object.keys(region)) {
        if (cat.includes("ms_")) {
          let catMscapTag = cat.replace("ms", "mscap");
          const catMsCap = round(region[cat] / region.population) || 0;
          region[catMscapTag] = catMsCap;
        }
      }
      totalPopulation += region.population;
    }

    for (let cat of Object.keys(totalMarketSize)) {
      let catMscapTag = cat.replace("ms", "mscap");
      const msCap = round(totalMarketSize[cat] / totalPopulation) || 0;
      finalMSCap[catMscapTag] = msCap;
    }
    console.log(totalMarketSize, finalMSCap);

    dataSource.sort((reg1, reg2) => reg1.id - reg2.id);

    dataSource.push({
      name: "Total",
      population: totalPopulation,
      ...totalMarketSize,
      ...finalMSCap,
    });
    console.log("DDDDDDDDDDDDDDD", dataSource);
    return dataSource;
  }

  const allDataFormatted = {};
  for (let year of allData) {
    for (let city of year.data) {
      if (city.city_id === data.city_id) {
        allDataFormatted[year.year] = getDataSource(city);
        console.log(year.year);
      }
    }
  }

  console.log(allDataFormatted);

  function getZoneDataOfRegion(row) {
    const dataSource = [];
    for (let zone of row.zones) {
      const newZone = {
        name: zone.zone,
        id: zone.zone_id,
        population: zone.population,
      };
      for (let cat of allCategories) {
        const reqdCategory = zone.market_size_data.find(
          (category) => category.name === cat.name
        );
        if (reqdCategory) {
          newZone[`ms_${cat.id}`] = round(reqdCategory.market_size, -3);
          const msCap = isFinite(
            round(reqdCategory.market_size, -3) / zone.population
          )
            ? round(reqdCategory.market_size, -3) / zone.population
            : 0;
          newZone[`mscap_${cat.id}`] = round(msCap);
        } else {
          newZone[`ms_${cat.id}`] = 0;
          newZone[`mscap_${cat.id}`] = 0;
        }
      }
      dataSource.push(newZone);
    }
    const lastRow = {
      name: "Total",
      population: row.population,
    };
    for (let cat of Object.keys(row)) {
      if (cat.includes("ms")) {
        lastRow[cat] = row[cat];
      }
    }
    dataSource.sort((zon1, zon2) => zon1.id - zon2.id);
    dataSource.push(lastRow);
    return dataSource;
  }
  function displaySubTable(row) {
    const columns = [
      {
        title: `Zone`,
        dataIndex: "name",
        key: "zone",
        onCell: (cell) =>
          cell.name === "Total" ? { className: "region-last-row" } : "",
        width: 200,
        fixed: "left",
        align: "center",
      },
      {
        title: "Population",
        dataIndex: "population",
        key: "population",
        align: "center",

        render: (x) =>
          x ? x.toLocaleString("en", { useGrouping: true }) : "N/A",
      },

      ...(allCategories.length > 1
        ? [
            {
              title: "Market Size (USD)",
              dataIndex: "market_size",
              key: "market_size",
              align: "center",
              children: allCategories.map((cat) => ({
                title: cat.name,
                dataIndex: `ms_${cat.id}`,
                key: uuid(),
                align: "center",
                render: (x) =>
                  x
                    ? `$${x.toLocaleString("en", { useGrouping: true })}`
                    : "N/A",
              })),
            },
            {
              title: "Market Size/Capita (USD)",
              dataIndex: "msCapita",
              key: "msCapita",
              align: "center",
              children: allCategories.map((cat) => ({
                title: cat.name,
                dataIndex: `mscap_${cat.id}`,
                key: uuid(),
                align: "center",
                style: { textAlign: "center" },
                render: (x) =>
                  x
                    ? `$${x.toLocaleString("en", { useGrouping: true })}`
                    : "N/A",
              })),
            },
          ]
        : [
            {
              title: "Market Size (USD)",
              dataIndex: `ms_${allCategories[0].id}`,
              key: "market_size",
              align: "center",

              render: (x) =>
                x ? `$${x.toLocaleString("en", { useGrouping: true })}` : "N/A",
            },
            {
              title: "Market Size/Capita (USD)",
              dataIndex: `mscap_${allCategories[0].id}`,
              key: "msCapita",
              align: "center",

              render: (x) =>
                x ? `$${x.toLocaleString("en", { useGrouping: true })}` : "N/A",
            },
          ]),
    ];

    const dataSource = getZoneDataOfRegion(row);

    return (
      <Table
        columns={columns}
        bordered
        pagination={false}
        dataSource={dataSource}
        scroll={{ x: allCategories.length * 400 + 800, y: 240 }}
        rowClassName={(row, idx) =>
          row.name === "Total"
            ? "zone-last-row"
            : idx % 2
            ? "zone-light-row"
            : "zone-dark-row"
        }
      />
    );
  }

  function chooseCatForYearly(categoryId) {
    if (yearlyComparisonOptions.category === categoryId) return;
    const newYearComparisonOptions = { ...yearlyComparisonOptions };
    newYearComparisonOptions.category = categoryId;
    setYearlyComparisonOptions(newYearComparisonOptions);
  }

  function chooseYearForYearly(year) {
    const newYearComparisonOptions = { ...yearlyComparisonOptions };
    const idx = newYearComparisonOptions.years.findIndex(
      (yearin) => yearin === year
    );
    if (idx === -1) {
      newYearComparisonOptions.years.push(year);
    } else {
      newYearComparisonOptions.years.splice(idx, 1);
    }
    if (newYearComparisonOptions.years.length >= 1) {
      const newErrors = { ...errors };
      if (newErrors.hasOwnProperty("yearlyError")) {
        delete newErrors["yearlyError"];
        setErrors(newErrors);
      }
    }

    setYearlyComparisonOptions(newYearComparisonOptions);
  }

  function compareYearly(options) {
    if (options.years.length < 1) {
      const newErrors = { ...errors };
      newErrors["yearlyError"] = "Please Select Atleast 1 Year.";
      setErrors(newErrors);
      return;
    }
    if (options.years.length >= 1) {
      const newErrors = { ...errors };
      if (newErrors.hasOwnProperty("yearlyError")) {
        delete newErrors["yearlyError"];
      }
      setErrors(newErrors);
    }
    setComparisonType("yearly");
    setYearlyModalOpen(false);
  }

  function chooseYearForCatg(yearChosen) {
    const newCatgComparisonOptions = { ...catgComparisonOptions };
    if (yearChosen === newCatgComparisonOptions) return;
    newCatgComparisonOptions.year = yearChosen;
    setCatgComparisonOptions(newCatgComparisonOptions);
  }

  function chooseCatForCatg(catId) {
    const newCatgComparisonOptions = { ...catgComparisonOptions };
    const idx = newCatgComparisonOptions.categories.findIndex(
      (cat) => cat === catId
    );
    if (idx === -1) newCatgComparisonOptions.categories.push(catId);
    else newCatgComparisonOptions.categories.splice(idx, 1);
    if (
      newCatgComparisonOptions.categories.length >= 1 &&
      errors.hasOwnProperty("catgError")
    ) {
      const newErrors = { ...errors };
      delete newErrors["catgError"];
      setErrors(newErrors);
    }
    setCatgComparisonOptions(newCatgComparisonOptions);
  }

  function compareCatgData(options) {
    const newErrors = { ...errors };
    if (options.categories.length < 1) {
      newErrors["catgError"] = "Please Select Atleast 1 Category.";
      setErrors(newErrors);
      return;
    }
    if (newErrors.hasOwnProperty("catgError")) {
      delete newErrors["catgError"];
      setErrors(newErrors);
    }
    setComparisonType("categories");
    setCatgModalOpen(false);
  }

  function YearComparisonModal({ open }) {
    return (
      <Modal open={open}>
        <div className="view-option-modal">
          <div className="top-header">
            <span>
              <AvTimer
                style={{
                  marginRight: "10px",
                  fontSize: "2rem",
                  color: "royalblue",
                  borderRadius: "50%",
                  backgroundColor: "#bbf7fc",
                }}
              />
              Compare Yearly Data
            </span>
            <Close
              onClick={() => setYearlyModalOpen(false)}
              className="modal-close-button"
            />
          </div>
          <div className="display-options">
            {errors.hasOwnProperty("yearlyError") && (
              <div className="pop-top-error">{errors.yearlyError}</div>
            )}

            <div style={{ fontSize: "1.25rem" }}>Select Any Two Years:</div>
            {allData.map((year) =>
              yearlyComparisonOptions.years.length >= 2 ? (
                yearlyComparisonOptions.years.includes(year.year) ? (
                  <div className="options-styled">
                    <Checkbox
                      className="w-100"
                      checked={true}
                      onClick={() => chooseYearForYearly(year.year)}
                    >
                      {year.year}
                    </Checkbox>
                  </div>
                ) : (
                  <div className="options-styled disabled-year">
                    <Checkbox className="w-100" disabled={true}>
                      {year.year}
                    </Checkbox>
                  </div>
                )
              ) : yearlyComparisonOptions.years.includes(year.year) ? (
                <div className="options-styled">
                  <Checkbox
                    className="w-100"
                    checked={true}
                    onClick={() => chooseYearForYearly(year.year)}
                  >
                    {year.year}
                  </Checkbox>
                </div>
              ) : (
                <div className="options-styled">
                  <Checkbox
                    className="w-100"
                    onClick={() => chooseYearForYearly(year.year)}
                  >
                    {year.year}
                  </Checkbox>
                </div>
              )
            )}
            <Divider />
            <div style={{ fontSize: "1.25rem" }}>Select Any One Category:</div>
            {allCategories.map((cat) => (
              <div className="options-styled">
                <Radio
                  className="w-100"
                  checked={yearlyComparisonOptions.category === cat.id}
                  onClick={() => chooseCatForYearly(cat.id)}
                >
                  {cat.name}
                </Radio>
              </div>
            ))}
          </div>
          <div className="submit-btn-div">
            <div onClick={() => compareYearly(yearlyComparisonOptions)}>
              Compare Data <NavigateNext />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function CatgComparisonModal({ open }) {
    return (
      <Modal open={open}>
        <div className="view-option-modal">
          <div className="top-header">
            <span>
              <Poll
                style={{
                  marginRight: "10px",
                  fontSize: "2rem",
                  color: "royalblue",
                  borderRadius: "50%",
                  backgroundColor: "#bbf7fc",
                }}
              />
              Compare Categories Data
            </span>
            <Close
              onClick={() => setCatgModalOpen(false)}
              className="modal-close-button"
            />
          </div>
          <div className="display-options">
            {errors.hasOwnProperty("catgError") && (
              <div className="pop-top-error">{errors.catgError}</div>
            )}

            <div style={{ fontSize: "1.25rem" }}>Select Any One Year:</div>
            {allData.map((year) => (
              <div className="options-styled">
                <Radio
                  className="w-100"
                  checked={catgComparisonOptions.year === year.year}
                  onClick={() => chooseYearForCatg(year.year)}
                >
                  {year.year}
                </Radio>
              </div>
            ))}
            <Divider />
            <div style={{ fontSize: "1.25rem" }}>Select Any One Category:</div>
            {allCategories.map((cat) =>
              catgComparisonOptions.categories.length >= 2 ? (
                catgComparisonOptions.categories.includes(cat.id) ? (
                  <div className="options-styled">
                    <Checkbox
                      className="w-100"
                      checked={true}
                      onClick={() => chooseCatForCatg(cat.id)}
                    >
                      {cat.name}
                    </Checkbox>
                  </div>
                ) : (
                  <div className="options-styled disabled-year">
                    <Checkbox className="w-100" disabled={true}>
                      {cat.name}
                    </Checkbox>
                  </div>
                )
              ) : catgComparisonOptions.categories.includes(cat.id) ? (
                <div className="options-styled">
                  <Checkbox
                    className="w-100"
                    checked={true}
                    onClick={() => chooseCatForCatg(cat.id)}
                  >
                    {cat.name}
                  </Checkbox>
                </div>
              ) : (
                <div className="options-styled">
                  <Checkbox
                    className="w-100"
                    onClick={() => chooseCatForCatg(cat.id)}
                  >
                    {cat.name}
                  </Checkbox>
                </div>
              )
            )}
          </div>
          <div className="submit-btn-div">
            <div onClick={() => compareCatgData(catgComparisonOptions)}>
              Compare Data <NavigateNext />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  const reqdDataSource = getDataSource(data);

  const title = `Months: ${
    monthsSelected.length === 12 || !isArray(monthsSelected)
      ? "The Whole Year"
      : monthsSelected.join(", ")
  }, Categories: ${allCategories.map((cat) => cat.name).join(", ")}, City: ${
    data.city_name
  }, Nationalities: ${
    isArray(nationalities) ? nationalities.join(", ") : "Nationalities Selected"
  }`;

  return (
    <>
      <Paper key={uuid()} elevation={5} className="tables-paper">
        <Table
          columns={columns}
          title={() => (
            <TitleHeader
              toolTipOpen={toolTipOpen}
              setToolTipOpen={setToolTipOpen}
              main={"Region-Wise Market Size"}
              city={data.city_name}
              year={year}
              categories={allCategories.map((cat) => cat.name)}
              nationalities={nationalities}
              months={monthsSelected}
              handleExcelDownload={() =>
                getExcel(
                  columns,
                  allDataFormatted,
                  title,
                  getZoneDataOfRegion,
                  null,
                  null
                )
              }
            />
          )}
          bordered
          pagination={false}
          dataSource={reqdDataSource}
          scroll={{ x: allCategories.length * 400 + 800, y: 240 }}
          expandable={{
            expandedRowRender: displaySubTable,
            rowExpandable: (row) => row.name !== "Total",
          }}
          rowClassName={(row, idx) =>
            row.name === "Total"
              ? "region-last-row"
              : idx % 2
              ? "region-light-row"
              : "region-dark-row"
          }
        />
        <div>
          <div
            className="d-flex justify-content-between mt-3 mb-3"
            key={uuid()}
          >
            <div className="d-flex align-items-center justify-content-start ">
              {allData.length > 1 && (
                <div
                  className="dpdown-pill"
                  onClick={() => setYearlyModalOpen(true)}
                >
                  Compare Yearly <ArrowRight />
                </div>
              )}
              {allCategories.length > 1 && (
                <div
                  className="dpdown-pill"
                  onClick={() => setCatgModalOpen(true)}
                >
                  Compare Categories <ArrowRight />
                </div>
              )}
              <Radio.Group
                onChange={(e) => setIsMarketSize(e.target.value)}
                value={isMarketSize}
                defaultValue={true}
                className="ml-2"
              >
                <Radio value={true}>View Market Size (USD)</Radio>
                <Radio value={false}>View Market Size/Capita (USD)</Radio>
              </Radio.Group>
            </div>
          </div>
          <ZoneWiseColumnChart
            data={reqdDataSource}
            categoriesChosen={catgComparisonOptions.categories}
            allCategories={allCategories}
            city={data.city_name}
            year={catgComparisonOptions.year}
            isMarketSize={isMarketSize}
            comparisonType={comparisonType}
            yearlyComparisonOptions={yearlyComparisonOptions}
            allData={allDataFormatted}
          />
        </div>
      </Paper>
      <YearComparisonModal open={yearlyModalOpen} />
      <CatgComparisonModal open={catgModalOpen} />
    </>
  );
}

export default function NationalityComparisonTable({
  data,
  nationalities,
  year,
  monthsSelected,
  categories,
  allData,
  toolTipOpen,
  setToolTipOpen,
}) {
  console.log(categories);
  const allCategories = [
    ...categories.categories.map((cat) => ({ name: cat.name, id: cat.id })),
    ...categories.subcategories.map((subcat) => ({
      name: subcat.name,
      id: subcat.id,
    })),
    ...categories.subsubcategories.map((subsubcat) => ({
      name: subsubcat.name,
      id: subsubcat.id,
    })),
  ];

  console.log(allCategories);

  if (allCategories.length > 0)
    return (
      <MainTable
        data={data}
        nationalities={nationalities}
        year={year}
        monthsSelected={monthsSelected}
        allCategories={allCategories}
        allData={allData}
        toolTipOpen={toolTipOpen}
        setToolTipOpen={setToolTipOpen}
      />
    );
  else return null;
}
