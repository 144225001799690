import React, { Component } from "react";
import { round } from "lodash";
import { LinearProgress, Paper } from "@material-ui/core";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { v4 as uuid } from "uuid";
//styles
import "../../css/tables.css";
//tables
import ZoneTable from "./Tables/ZoneTable";
import NationalityComparisonTable from "./Tables/NationalityComparisonTable";
import DetailedComparisonTable from "./Tables/DetailedComparisonTable";
import NationalityZoneTable from "./Tables/NationalityZoneTable";
import ByZoneByMonth from "./Tables/ByZoneByMonth";

import getDetailedExcel from "../../utils/getDetailedExcel";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getZoneDataOfRegion(row, allCategories) {
  const dataSource = [];

  for (let zone of row.zones) {
    const newZone = {
      name: zone.name,
      id: zone.id,
      population: round(zone.population, -3),
    };
    for (let category of allCategories) {
      let catTotal = 0;
      for (let month of zone.months) {
        const reqdCategory = month.market_size_data.find(
          (cat) => cat.name === category.name
        );
        if (reqdCategory) {
          const marketSize = round(reqdCategory.market_size, -3);
          const msCap = round(marketSize / zone.population) || 0;
          newZone[`ms_${category.id}_${month.month}`] = marketSize;
          newZone[`mscap_${category.id}_${month.month}`] = msCap;
          catTotal += marketSize;
        } else {
          newZone[`ms_${category.id}_${month.month}`] = 0;
          newZone[`mscap_${category.id}_${month.month}`] = 0;
        }
      }
      newZone[`ms_${category.id}_Total`] = catTotal;
      newZone[`mscap_${category.id}_Total`] =
        round(catTotal / zone.population) || 0;
    }
    dataSource.push(newZone);
  }
  dataSource.sort((zon1, zon2) => zon1.id - zon2.id);

  let totalZone = { ...row };
  totalZone.name = "Total";
  totalZone.id = uuid();
  totalZone.zones = undefined;
  // console.log(totalZone);
  dataSource.push(totalZone);
  return dataSource;
}

function getRegionData(rawData, allCategories, monthsSelected) {
  console.log(allCategories);
  const regionData = [];
  let totalPopulation = 0;
  let totalMarketSizeData = {};

  for (const cat of allCategories) {
    for (const month of monthsSelected) {
      totalMarketSizeData[`ms_${cat.id}_${month}`] = 0;
    }
    totalMarketSizeData[`ms_${cat.id}_Total`] = 0;
  }

  for (let zone of rawData.zones) {
    const idx = regionData.findIndex((reg) => reg.id === zone.region_id);
    const population = round(zone.population, -1);
    totalPopulation += population;
    if (idx === -1) {
      const newRegion = {
        id: zone.region_id,
        key: uuid(),
        name: zone.region_name,
        population,
        zones: [zone],
      };
      for (const cat of allCategories) {
        for (const month of zone.months) {
          const catReqd = month.market_size_data.find(
            (zone_cat) => zone_cat.name === cat.name
          );
          if (catReqd) {
            const mktSize = round(catReqd.market_size, -3);
            newRegion[`ms_${cat.id}_${month.month}`] = mktSize;
            newRegion[`ms_${cat.id}_Total`] = mktSize;
            totalMarketSizeData[`ms_${cat.id}_${month.month}`] += mktSize;
            totalMarketSizeData[`ms_${cat.id}_Total`] += mktSize;
          } else {
            newRegion[`ms_${cat.id}_${month.month}`] = 0;
            newRegion[`ms_${cat.id}_Total`] = 0;
          }
        }
      }
      regionData.push(newRegion);
    } else {
      const reqdRegion = regionData[idx];
      reqdRegion["population"] += population;
      reqdRegion.zones.push(zone);
      for (const cat of allCategories) {
        for (const month of zone.months) {
          const catReqd = month.market_size_data.find(
            (zone_cat) => zone_cat.name === cat.name
          );
          if (catReqd) {
            const mktSize = round(catReqd.market_size, -3);
            reqdRegion[`ms_${cat.id}_${month.month}`] += mktSize;
            reqdRegion[`ms_${cat.id}_Total`] += mktSize;
            totalMarketSizeData[`ms_${cat.id}_${month.month}`] += mktSize;
            totalMarketSizeData[`ms_${cat.id}_Total`] += mktSize;
          }
        }
      }
    }
  }
  regionData.sort((reg1, reg2) => reg1.id - reg2.id);

  for (const region of regionData) {
    for (const key of Object.keys(region)) {
      if (key.includes("ms_")) {
        if (isNaN(region[key])) region[key] = 0;
        const mscapKey = key.replace("ms_", "mscap_");
        let mksCap = round(region[key] / region.population) || 0;
        if (!isFinite(mksCap)) mksCap = 0;
        region[mscapKey] = mksCap;
      }
    }
  }

  let finalMktSizePerCap = {};
  for (let key of Object.keys(totalMarketSizeData)) {
    const msCapKey = key.replace("ms_", "mscap_");
    const mktsizeCap = totalMarketSizeData[key] / totalPopulation || 0;
    finalMktSizePerCap[msCapKey] = round(mktsizeCap);
  }
  // console.log(finalMktSizePerCap, totalMarketSizeData);

  const totalRegion = {
    key: uuid(),
    name: "Total",
    ...totalMarketSizeData,
    ...finalMktSizePerCap,
    population: totalPopulation,
  };

  regionData.push(totalRegion);
  // console.log(regionData);
  return regionData;
}

function getAllDataFormatted(
  allData,
  city,
  nationality,
  allCategories,
  monthsSelected
) {
  const allDataFormatted = {};

  for (let _year of allData) {
    for (let _nationality of _year.nationalities) {
      for (let _city of _nationality.cities) {
        if (_city.name === city && _nationality.nationality === nationality) {
          allDataFormatted[String(_year.year)] = getRegionData(
            _city,
            allCategories,
            monthsSelected
          );
        }
      }
    }
  }
  return allDataFormatted;
}

function columns(isRegionWise, allCategories, monthsSelected) {
  const titleFormatter = (title) =>
    isRegionWise ? (
      <h4 style={{ fontWeight: 700, textAlign: "center" }}>{title}</h4>
    ) : (
      <h6 style={{ textAlign: "center" }}>{title}</h6>
    );
  const dataFormatter = (data) =>
    isRegionWise ? <span style={{ fontWeight: "500" }}>{data}</span> : data;
  return [
    {
      title: titleFormatter(isRegionWise ? "Region" : "Zone"),
      dataIndex: "name",
      className: "column-custom",
      titleText: isRegionWise ? "Region" : "Zone",
      rSpan: allCategories.length > 1 ? 3 : 2,
      key: isRegionWise ? "region" : "zone",
      width: 300,
      fixed: "left",
      render: (x) => dataFormatter(x),
      onCell: (cell) =>
        cell.name === "Total" ? { className: "region-last-row" } : "",
    },
    {
      title: titleFormatter("Population"),
      titleText: "Population",
      numFmt: "#,##0",
      dataIndex: "population",
      rSpan: allCategories.length > 1 ? 3 : 2,
      className: "column-custom",

      key: "population",
      render: (x) =>
        !x
          ? "N/A"
          : dataFormatter(x.toLocaleString("en", { useGrouping: true })),
    },

    ...monthsSelected.map((mon) => ({
      title: titleFormatter(months[mon - 1]),
      titleText: months[mon - 1],
      key: uuid(),
      cSpan: allCategories.length > 1 ? allCategories.length * 2 : 2,
      className: "bordered column-custom",
      children:
        allCategories.length > 1
          ? allCategories.map((cat) => ({
              title: cat.name,
              titleText: cat.name,
              key: uuid(),
              cSpan: 2,
              children: [
                {
                  title: "Market Size (USD)",
                  titleText: "Market Size (USD)",
                  key: uuid(),
                  numFmt: "$#,##0",
                  align: "center",
                  dataIndex: `ms_${cat.id}_${mon}`,
                  render: (x) =>
                    !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),
                },
                {
                  title: "Market Size/Capita (USD)",
                  titleText: "Market Size/Capita (USD)",
                  key: uuid(),
                  numFmt: "$#,##0",
                  align: "center",
                  dataIndex: `mscap_${cat.id}_${mon}`,
                  render: (x) =>
                    !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),
                },
              ],
            }))
          : [
              {
                title: "Market Size (USD)",
                titleText: "Market Size (USD)",
                numFmt: "$#,##0",
                key: uuid(),
                align: "center",
                dataIndex: `ms_${allCategories[0].id}_${mon}`,
                render: (x) =>
                  !x
                    ? "N/A"
                    : dataFormatter(
                        `$${x.toLocaleString("en", { useGrouping: true })}`
                      ),
              },
              {
                title: "Market Size/Capita (USD)",
                titleText: "Market Size/Capita (USD)",
                numFmt: "$#,##0",
                key: uuid(),
                align: "center",
                dataIndex: `mscap_${allCategories[0].id}_${mon}`,
                render: (x) =>
                  Number(x) === 0 || !x
                    ? "N/A"
                    : dataFormatter(
                        `$${x.toLocaleString("en", { useGrouping: true })}`
                      ),
              },
            ],
    })),
    {
      title: titleFormatter("Total"),
      titleText: "Total",
      className: "bordered",
      key: uuid(),
      cSpan: allCategories.length > 1 ? allCategories.length * 2 : 2,
      children:
        allCategories.length > 1
          ? allCategories.map((cat) => ({
              title: cat.name,
              titleText: cat.name,
              key: uuid(),
              cSpan: 2,
              children: [
                {
                  title: "Market Size (USD)",
                  titleText: "Market Size (USD)",
                  key: uuid(),
                  numFmt: "$#,##0",
                  align: "center",
                  dataIndex: `ms_${cat.id}_Total`,
                  render: (x) =>
                    !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),

                  onCell: (cell, idx) =>
                    cell.name === "Total"
                      ? { className: "region-last-row" }
                      : idx % 2
                      ? { classname: "total-light-blue" }
                      : { className: "total-dark-blue" },
                },
                {
                  title: "Market Size/Capita (USD)",
                  titleText: "Market Size/Capita (USD)",
                  numFmt: "$#,##0",
                  key: uuid(),
                  align: "center",
                  dataIndex: `mscap_${cat.id}_Total`,
                  render: (x) =>
                    !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),
                  onCell: (cell, idx) =>
                    cell.name === "Total"
                      ? { className: "region-last-row" }
                      : idx % 2
                      ? { classname: "total-light-blue" }
                      : { className: "total-dark-blue" },
                },
              ],
            }))
          : [
              {
                title: "Market Size (USD)",
                titleText: "Market Size (USD)",
                key: uuid(),
                numFmt: "$#,##0",
                align: "center",
                dataIndex: `ms_${allCategories[0].id}_Total`,
                render: (x) =>
                  !x
                    ? "N/A"
                    : dataFormatter(
                        `$${x.toLocaleString("en", { useGrouping: true })}`
                      ),
                onCell: (cell, idx) =>
                  cell.name === "Total"
                    ? { className: "region-last-row" }
                    : idx % 2
                    ? { className: "total-light-blue" }
                    : { className: "total-dark-blue" },
              },
              {
                title: "Market Size/Capita (USD)",
                titleText: "Market Size/Capita (USD)",
                numFmt: "$#,##0",
                key: uuid(),
                align: "center",
                dataIndex: `mscap_${allCategories[0].id}_Total`,
                render: (x) =>
                  !x
                    ? "N/A"
                    : dataFormatter(
                        `$${x.toLocaleString("en", { useGrouping: true })}`
                      ),
                onCell: (cell, idx) =>
                  cell.name === "Total"
                    ? { className: "region-last-row" }
                    : idx % 2
                    ? { className: "total-light-blue" }
                    : { className: "total-dark-blue" },
              },
            ],
    },
  ];
}

export class Tables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearChosen: null,
      toolTipOpen: true,
    };
  }
  chooseYear = (year) => {
    this.setState({ yearChosen: year });
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (
  //     nextProps.data.length !== this.props.data.length ||
  //     nextState.yearChosen !== this.state.yearChosen ||
  //     nextState.toolTipOpen !== this.state.toolTipOpen
  //   )
  //     return true;
  //   return false;
  // }

  render() {
    console.log(this.props, "SHOWDATA");
    console.log(this.props.zones, "zonesss");
    console.log(this.props.user);
    const data = this.props.data;
    console.table(data, "main-data**");
    const displayMode = this.props.displayMode;
    console.log(displayMode, "displayMode**");
    const purchaseMode =
      this.props.purchaseMode.length === 2
        ? "Offline & Online"
        : this.props.purchaseMode[0];
    const placeOfPurchase =
      this.props.placeOfPurchase.length === 2
        ? "In City and Outside City"
        : this.props.placeOfPurchase[0] === "in"
        ? "In City"
        : "Outside City";
    if (data && data.length > 0) {
      console.log("LOOOOOK");
      console.log(data);
      if (displayMode === "distinct") {
        return (
          <div key={uuid()}>
            {data[0]
              .filter((year) => year.year === this.props.yearChosen)
              .map((year) => (
                <div>
                  <div className="d-flex w-100 align-items-center justify-end p-3">
                    <button
                      onClick={() =>
                        getDetailedExcel(
                          columns,
                          data[0],
                          getAllDataFormatted,
                          getZoneDataOfRegion
                        )
                      }
                    >
                      Download All
                    </button>
                  </div>
                  {year.nationalities.map((nationality) =>
                    nationality.cities.map((city) =>
                      this.props.user.cities.includes(city.name) ||
                      this.props.user.catchmentCities.includes(city.name) ? (
                        <DetailedComparisonTable
                          data={city}
                          columns={columns}
                          year={year.year}
                          city={city.name}
                          categories={city.categories}
                          nationality={nationality.nationality}
                          monthsSelected={year.months}
                          allData={data[0]}
                          getRegionData={getRegionData}
                          getZoneDataOfRegion={getZoneDataOfRegion}
                          getAllDataFormatted={getAllDataFormatted}
                          toolTipOpen={this.state.toolTipOpen}
                          setToolTipOpen={(open) =>
                            this.setState({ toolTipOpen: open })
                          }
                        />
                      ) : null
                    )
                  )}
                </div>
              ))}
          </div>
        );
      } else if (displayMode === "zones") {
        return (
          <div>
            {data.map((division) => (
              <div key={uuid()}>
                <>
                  {division
                    .filter((year) => year.year === this.props.yearChosen)
                    .map((year) =>
                      year.hasOwnProperty("data")
                        ? year.data.map((city) => (
                            <Paper
                              key={uuid()}
                              elevation={5}
                              className="tables-paper"
                            >
                              <div key={uuid()}>
                                <ZoneTable
                                  propertyName="category"
                                  population={city.population}
                                  data={city.market_data}
                                  year={year.year}
                                  months={this.props.months}
                                  city={city.city}
                                  purchaseMode={purchaseMode}
                                  nationalities={this.props.nationalities}
                                  placeOfPurchase={placeOfPurchase}
                                  zones={this.props.zones}
                                  toolTipOpen={this.state.toolTipOpen}
                                  setToolTipOpen={(open) =>
                                    this.setState({ toolTipOpen: open })
                                  }
                                  user={this.props.user}
                                  allCatchments={this.props.allCatchments}
                                />
                              </div>
                            </Paper>
                          ))
                        : year.cities.map((city) => (
                            <div key={uuid()}>
                              {city.categories.map((category) => (
                                <div key={uuid()}>
                                  {!category?.subcategories[0]?.hasOwnProperty(
                                    "subsubcategories"
                                  ) ? (
                                    <Paper
                                      key={uuid()}
                                      elevation={5}
                                      className="tables-paper"
                                    >
                                      <ZoneTable
                                        propertyName="subcategory"
                                        population={city.population}
                                        data={category.subcategories}
                                        year={year.year}
                                        city={city.city}
                                        category={category.category}
                                        placeOfPurchase={placeOfPurchase}
                                        nationalities={this.props.nationalities}
                                        purchaseMode={purchaseMode}
                                        zones={this.props.zones}
                                        toolTipOpen={this.state.toolTipOpen}
                                        setToolTipOpen={(open) =>
                                          this.setState({ toolTipOpen: open })
                                        }
                                        user={this.props.user}
                                        allCatchments={this.props.allCatchments}
                                        months={this.props.months}
                                      />
                                    </Paper>
                                  ) : (
                                    category.subcategories.map(
                                      (subcategory) => (
                                        <Paper
                                          key={uuid()}
                                          elevation={5}
                                          className="tables-paper"
                                        >
                                          <div key={uuid()}>
                                            <ZoneTable
                                              propertyName="subsubcategory"
                                              population={city.population}
                                              data={
                                                subcategory.subsubcategories
                                              }
                                              year={year.year}
                                              category={category.category}
                                              city={city.city}
                                              nationalities={
                                                this.props.nationalities
                                              }
                                              purchaseMode={purchaseMode}
                                              placeOfPurchase={placeOfPurchase}
                                              months={this.props.months}
                                              zones={this.props.zones}
                                              toolTipOpen={
                                                this.state.toolTipOpen
                                              }
                                              user={this.props.user}
                                              allCatchments={
                                                this.props.allCatchments
                                              }
                                              setToolTipOpen={(open) =>
                                                this.setState({
                                                  toolTipOpen: open,
                                                })
                                              }
                                            />
                                          </div>
                                        </Paper>
                                      )
                                    )
                                  )}
                                </div>
                              ))}
                            </div>
                          ))
                    )}
                </>
              </div>
            ))}
          </div>
        );
      } else if (displayMode === "nationality") {
        console.log(data[0][0]);
        // return null;
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!REMOVE WHEN BACKEND DONE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        return (
          <div>
            {data.length &&
              data[0]
                .filter((year) => year.year === this.props.yearChosen)
                .map((year) =>
                  year.data.map((city) =>
                    this.props.user.cities.includes(city.city_name) ||
                    this.props.user.catchmentCities.includes(city.name) ? (
                      <>
                        <NationalityComparisonTable
                          data={city}
                          categories={city.categories}
                          year={year.year}
                          monthsSelected={this.props.months}
                          nationalities={this.props.nationalities}
                          allData={data[0]}
                          toolTipOpen={this.state.toolTipOpen}
                          setToolTipOpen={(open) =>
                            this.setState({ toolTipOpen: open })
                          }
                        />
                      </>
                    ) : null
                  )
                )}
          </div>
        );
      } else if (displayMode === "zone_nationality") {
        return data[0]
          .filter((year) => year.year === this.props.yearChosen)
          .map((year) =>
            year.cities.map((city) => (
              <NationalityZoneTable
                city={city.city}
                zones={city.zones}
                categories={city.categories}
                year={year.year}
                nationalities={year.nationalities}
                monthsSelected={year.months}
                allData={data[0]}
                toolTipOpen={this.state.toolTipOpen}
                setToolTipOpen={(open) => this.setState({ toolTipOpen: open })}
              />
            ))
          );
      } else if (displayMode === "zones_monthly") {
        return data[0]
          .filter((year) => year.year === this.props.yearChosen)
          .map((year) =>
            year.cities.map((city) => (
              <ByZoneByMonth
                city={city}
                categories={year.categories}
                year={year.year}
                monthsSelected={year.months}
                nationalities={year.nationalities}
                allData={data[0]}
                toolTipOpen={this.state.toolTipOpen}
                setToolTipOpen={(open) => this.setState({ toolTipOpen: open })}
              />
            ))
          );
      }
    } else
      return (
        <div
          style={{
            height: "80%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="w-100">
            <span className="p-3">
              <b>
                <h1>
                  Please Wait While We Get The Data You Require.
                  <br />
                  It Usually Takes 30-40 Seconds.
                </h1>
              </b>
            </span>
            <LinearProgress style={{ width: "100%", color: "#144380" }} />
          </div>
        </div>
      );
  }
}

export default Tables;
