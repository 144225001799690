import React, { Component } from "react";

import { eraseCookie } from "../utils/cookie";
import findGetParameter from "../utils/paramReader";
import UserContext from "./UserContext";
import "../css/HomePage/Banner.css";

class HomePage extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      subscribed: false,
      cookieDialogOpen: false,
      acceptCookies: true,
      paramData: { cookie: null, session_id: null },
    };
  }

  componentDidMount = () => {
    let cookie = findGetParameter("cookie");
    let session_id = findGetParameter("session_id");
    if (cookie === "delete") {
      eraseCookie("session_id");
      console.log("delete cookie");
    }
    this.setState({ paramData: { cookie, session_id } });
    console.log(cookie, session_id);
    window.location.href = "https://intelligentinsights.me";
  };

  render() {
    return <div className="loadingPage"></div>;
  }
}

export default HomePage;
