import React, { Component } from "react";
import { Space, Checkbox, Button, Menu } from "antd";
import { Paper } from "@material-ui/core";
import axios from "axios";
import "../../css/DemoUpload.css";

const { SubMenu } = Menu;

const API_URL = "https://data.merd.online:8000/demographic/";
// const API_URL = "http://localhost:8000/demographic/";

export default class DemographicUpload extends Component {
  constructor() {
    super();
    this.state = {
      postObject: {
        folder: [],
      },
      cities: [],
      modes: [],
      types: [],
      files: [],
      filesToDelete: [],
      filterBy: {
        cities: [],
        types: [],
        modes: [],
      },
    };
  }

  setFilters = (files) => {
    let newCities = [];
    let newModes = [];
    let newTypes = [];
    for (let file of files) {
      if (!file.includes(".xlsx")) continue;
      const [city, type, mode] = file
        .replace(".xlsx", "")
        .split("_")
        .map((ele) => ele.trim());
      newCities.push(city);
      newTypes.push(type);
      newModes.push(mode);
    }
    newCities = [...new Set(newCities)];
    newTypes = [...new Set(newTypes), "Map"];
    newModes = [...new Set(newModes)];

    this.setState({
      cities: newCities,
      types: newTypes,
      modes: newModes,
    });
  };

  componentDidMount() {
    axios.get(API_URL).then((res) => {
      let newFiles = res.data.files;
      this.setState({ files: newFiles });

      this.setFilters(newFiles);
    });
  }

  handleFileInput = (e, type) => {
    const files = e.target.files;
    const newPostObject = { ...this.state.postObject };
    newPostObject[type] = files;
    this.setState({ postObject: newPostObject });
  };

  handleSubmit = () => {
    const postForm = new FormData();
    postForm.append("operation", "upload");
    for (let file of this.state.postObject.folder) {
      postForm.append(file.name, file);
    }
    console.log(this.state.postObject.folder);
    axios
      .post(API_URL, postForm)
      .then((res) => {
        const newFiles = [...new Set([...this.state.files, ...res.data.files])];
        this.setState({ files: newFiles });
        this.setFilters(newFiles);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  handleDelete = () => {
    const postForm = new FormData();
    postForm.append("operation", "delete");
    for (let file of this.state.filesToDelete) {
      postForm.append(file, "");
    }
    axios
      .post(API_URL, postForm)
      .then((res) => {
        const filesDeleted = res.data.files;
        const newFiles = this.state.files.filter(
          (file) => !filesDeleted.includes(file)
        );
        this.setState({ files: newFiles, filesToDelete: [] });
        this.setFilters(newFiles);

        console.log(newFiles);
      })
      .catch((err) => console.error(err));
  };

  handleFileCheck = (file) => {
    let newFilesToDelete = [...this.state.filesToDelete];
    const idx = newFilesToDelete.findIndex((ftd) => ftd === file);
    if (idx !== -1) {
      newFilesToDelete.splice(idx, 1);
    } else {
      newFilesToDelete.push(file);
    }
    newFilesToDelete = [...new Set(newFilesToDelete)];
    this.setState({ filesToDelete: newFilesToDelete });
  };

  selectCity = (cityChose) => {
    let newFilterByCities = [...this.state.filterBy.cities];
    const idx = newFilterByCities.findIndex((city) => cityChose === city);
    if (idx !== -1) {
      newFilterByCities.splice(idx, 1);
    } else {
      newFilterByCities.push(cityChose);
    }
    newFilterByCities = [...new Set(newFilterByCities)];
    this.setState({
      filterBy: { ...this.state.filterBy, cities: newFilterByCities },
    });
  };

  selectType = (typeChose) => {
    let newFilterByTypes = [...this.state.filterBy.types];
    const idx = newFilterByTypes.findIndex((type) => typeChose === type);
    if (idx !== -1) {
      newFilterByTypes.splice(idx, 1);
    } else {
      newFilterByTypes.push(typeChose);
    }
    newFilterByTypes = [...new Set(newFilterByTypes)];
    this.setState({
      filterBy: { ...this.state.filterBy, types: newFilterByTypes },
    });
  };

  selectMode = (modeChose) => {
    let newFilterByModes = [...this.state.filterBy.modes];
    const idx = newFilterByModes.findIndex((mode) => modeChose === mode);
    if (idx !== -1) {
      newFilterByModes.splice(idx, 1);
    } else {
      newFilterByModes.push(modeChose);
    }
    newFilterByModes = [...new Set(newFilterByModes)];
    this.setState({
      filterBy: { ...this.state.filterBy, modes: newFilterByModes },
    });
  };

  calculateRelevance = (file, filters) => {
    const [city, type, mode] = file
      .replace(".xlsx", "")
      .replace(".pdf", "")
      .split("_")
      .map((ele) => ele.trim());
    let weight = 0;
    if (filters.cities.includes(city)) ++weight;
    if (filters.types.includes(type)) ++weight;
    if (filters.modes.includes(mode)) ++weight;
    return weight;
  };

  sorter = (prev, current, filters) => {
    return (
      this.calculateRelevance(prev, filters) -
      this.calculateRelevance(current, filters)
    );
  };

  filterFiles = (files, filters) => {
    if (
      filters.cities.length === 0 &&
      filters.types.length === 0 &&
      filters.modes.length === 0
    )
      return files;
    let sortedFiles = [...files];
    for (let i = 0; i < sortedFiles.length - 1; i++) {
      for (let j = 0; j < sortedFiles.length - i - 1; j++) {
        if (
          this.calculateRelevance(sortedFiles[j], filters) <
          this.calculateRelevance(sortedFiles[j + 1], filters)
        ) {
          //swap
          let temp = sortedFiles[j];
          sortedFiles[j] = sortedFiles[j + 1];
          sortedFiles[j + 1] = temp;
        }
      }
    }
    return sortedFiles;
  };

  render() {
    return (
      <div>
        <div className="admin-side">
          <h1 align="center">Upload Demographic Files</h1>
          <div className="d-flex flex-direction-row">
            <div className="filters">
              <Paper style={{ padding: "1rem" }} key="map-pdf">
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                  }}
                >
                  <h3>Upload Folder Consisting Demographic Files</h3>
                  <input
                    type="file"
                    name="excelFileFolder"
                    id="table-upload"
                    webkitdirectory=""
                    mozdirectory=""
                    onChange={(e) => this.handleFileInput(e, "folder")}
                  />
                  {this.state.postObject.folder.length > 0 ? (
                    <button onClick={(e) => this.handleSubmit()}>Upload</button>
                  ) : (
                    <button disabled className="disabled">
                      Upload
                    </button>
                  )}
                </div>
              </Paper>
            </div>
            <div className="mr-2 ml-2">
              <Paper
                style={{
                  marginBottom: "1rem",
                  padding: "1rem",
                  height: "40vh",
                  overflowY: "scroll",
                  width: "fit-content",
                }}
              >
                <h3 color="pink">Select To Delete</h3>
                <Space direction="vertical">
                  {this.state.files.length > 0 &&
                    this.filterFiles(this.state.files, this.state.filterBy).map(
                      (file) => {
                        let bgColor = "white";
                        switch (
                          this.calculateRelevance(file, this.state.filterBy)
                        ) {
                          case 1:
                            bgColor = "lightYellow";
                            break;
                          case 2:
                            bgColor = "lightBlue";
                            break;
                          case 3:
                            bgColor = "lightGreen";
                            break;
                          default:
                            bgColor = "white";
                        }
                        return (
                          <Checkbox
                            value={file}
                            key={file}
                            checked={this.state.filesToDelete.includes(file)}
                            onChange={() => this.handleFileCheck(file)}
                            style={{
                              backgroundColor: bgColor,
                              paddingInline: "2px",
                              borderRadius: "2px",
                            }}
                          >
                            {file}
                          </Checkbox>
                        );
                      }
                    )}
                </Space>
              </Paper>
              <Button
                onClick={this.handleDelete}
                disabled={this.state.filesToDelete.length === 0}
              >
                Delete
              </Button>
            </div>
          </div>
          <Paper className="m-2 p-2">
            <h1>Filters</h1>
            <div>
              <Menu mode="inline" title="Filters" style={{ width: "50%" }}>
                <SubMenu key="1-menu" title={<h4>Select Cities</h4>}>
                  {this.state.cities.map((city) => (
                    <Menu.Item key={`item${city}`}>
                      <Checkbox
                        value={city}
                        key={city}
                        onClick={() => this.selectCity(city)}
                        checked={this.state.filterBy.cities.includes(city)}
                      >
                        {city}
                      </Checkbox>
                    </Menu.Item>
                  ))}
                </SubMenu>
                <SubMenu key="2-menu" title={<h4>Select Types</h4>}>
                  {this.state.types.map((type) => (
                    <Menu.Item key={`item${type}`}>
                      <Checkbox
                        value={type}
                        key={type}
                        onClick={() => this.selectType(type)}
                        checked={this.state.filterBy.types.includes(type)}
                      >
                        {type}
                      </Checkbox>
                    </Menu.Item>
                  ))}
                </SubMenu>
                <SubMenu key="3-menu" title={<h4>Select Modes</h4>}>
                  {this.state.modes.map((mode) => (
                    <Menu.Item key={`item${mode}`}>
                      <Checkbox
                        value={mode}
                        key={mode}
                        onClick={() => this.selectMode(mode)}
                        checked={this.state.filterBy.modes.includes(mode)}
                      >
                        {mode}
                      </Checkbox>
                    </Menu.Item>
                  ))}
                </SubMenu>
              </Menu>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}
