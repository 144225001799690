import React, { Component } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "./Dashboard/Alert";
import SubscriptionAlert from "./Dashboard/SubscriptionAlert";
import OneTimeSubPopUp from "./Dashboard/OneTimeSubPopUp";
import CancelPopUp from "./Dashboard/CancelPopUp";
import ToSubAlert from "./Dashboard/ToSubAlert";
import NotPaidPopUp from "./Dashboard/NotPaidPopUp";
import SelectionList from "./Dashboard/SelectionList";
import { v4 as uuid } from "uuid";

import axios from "axios";
import * as _ from "lodash";

import { sortZones } from "../utils/sort";
import displayCities from "../utils/displayCities";
import { getCookie } from "../utils/cookie";

import { Layout, Menu, Checkbox, Tooltip } from "antd";
import "antd/dist/antd.min.css";

import "../css/dashboard.css";

import SubCategory from "./Dashboard/Menu/SubCategory";
import Tables from "./Dashboard/Tables";
import SubNationality from "./Dashboard/Menu/SubNationality";
import SubMonths from "./Dashboard/Menu/SubMonths";
import ViewOptionModal from "./Dashboard/ViewOptionModal";

import Download from "@material-ui/icons/CloudDownload";
import {
  Apps,
  ArrowBackIos,
  ArrowForwardIosOutlined,
  CallMade,
  Delete,
  MonetizationOn,
  Schedule,
  ShoppingCart,
  SupervisedUserCircle,
  Visibility,
} from "@material-ui/icons";

import UserContext from "./UserContext";
import { Paper } from "@material-ui/core";

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const API_URL = "https://data.merd.online:8000/api/filter";
const CANCEL_URL =
  "https://intelligentinsights.me/subscription-process-cancel/";
// const MAP_URL = "http://localhost:8000/catchments_info/malls/";
// const API_URL = "http://localhost:8000/api/filter";

const nowDate = new Date();
const currentYear = nowDate.getFullYear();
const currentMonth = nowDate.getMonth() + 1;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export class NewDashboard extends Component {
  _isMounted = false;
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.dataToBeEmailed = null;
    //optionData is the raw data received from the backend
    //All other array variables are used to render the menu
    //postObject is the object that gets constructed for POST

    this.state = {
      optionData: [],
      tableData: [],
      cities: [],
      zones: [],
      years: [],
      category: [],
      nationality: [],
      notSubscribed: {
        cities: [],
        catgs: [],
      },
      selectedCities: [],
      selectedCatgs: [],
      postObject: {
        cities: [],
        zones: [],
        years: [],
        months: [],
        categories: [],
        subCategories: [],
        subSubCategories: [],
        nationalities: [],
        purchaseMode: ["online", "offline"],
        placeOfPurchase: ["in", "out"],
      },
      registeredUser: true,
      loading: false,
      siderCollapsed: false,
      csvData: [],
      menuLoading: true,
      isModalOpen: false,
      displayMode: "distinct",
      alertOpen: true,
      alertOpenInvalid: false,
      alertMessage: null,
      subscriber: true,
      subscriptionAlertOpen: false,
      oneTimeSubPopUpOpen: false,
      takingToSubMore: false,
      cancelPopUpOpen: false,
      mallName: null,
      notPaidModalOpen: false,
      allPaid: true,
      listExpanded: true,
      yearChosen: null,
      allCatchments: false,
    };
    this.ModalHandlerClose = this.ModalHandlerClose.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  ModalHandlerClose = async () => {
    this.setState({ alertOpen: false, alertOpenInvalid: false });
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        alertOpen: false,
        alertOpenInvalid: false,
        notPaidModalOpen: false,
      });
    }
  }

  scrollToTop = () => {
    this.top.scrollIntoView({ behavior: "auto" });
  };

  //GET request
  componentDidMount() {
    this._isMounted = true;
    document.addEventListener("mousedown", this.handleClickOutside);
    window.scrollTo(0, 0); //scrolls to the top, on loading, otherwise scrolls to footer.
    let optionData;
    let createdData;
    if (localStorage.getItem("option-data")) {
      optionData = JSON.parse(localStorage.getItem("option-data"));
      // optionData = Object.entries(optionData.filters[0]);
      optionData = sortZones(optionData);
      createdData = this.createData(optionData);
      this.autoSelectForMall(createdData.cities);
    } else {
      axios.get(API_URL).then((res) => {
        optionData = Object.entries(res.data.filters[0]);
        localStorage.setItem("option-data", JSON.stringify(optionData));
        optionData = sortZones(optionData);
        createdData = this.createData(optionData);
        this.autoSelectForMall(createdData.cities);
      });
    }

    if (getCookie("session_id")) {
      this.setState({ alertOpen: false });
    }

    if (localStorage.getItem("selectionsMade")) {
      let selectionsMade = localStorage.getItem("selectionsMade");
      selectionsMade = JSON.parse(selectionsMade);
      if (selectionsMade.type === "market-size") {
        delete selectionsMade.type;
        this.setState({ postObject: selectionsMade });
        this.setState({
          alertOpen: false,
          alertOpenInvalid: false,
        });
        this.checkData();
        localStorage.removeItem("selectionsMade");
      }
    }
  }

  isMallDownloadable = (user, cities, mallName) => {
    const url = new URL(window.location.href);
    const object = JSON.parse(url.searchParams.get("data"));
    const city = cities.find((c) => c.city === object.selectedCity);
    try {
      const mallSubscribed = user.catchments.some(
        (mall) => mall.name === mallName
      );
      return user.cities.includes(city.city) || mallSubscribed;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  autoSelectForMall = (createdDatacities) => {
    //the url will have city data when redirected from catchments page
    const url = new URL(window.location.href);
    const object = JSON.parse(url.searchParams.get("data"));
    let zones = [];
    let city;

    if (object) {
      const cities = createdDatacities;
      city = cities.find((c) => c.city === object.selectedCity);
      try {
        city.zone.forEach((zoneOption) => {
          object.selectedZones.forEach((zone) => {
            if (zoneOption.zone === zone) {
              zones.push(zoneOption.id);
            }
          });
        });
      } catch (e) {
        console.error(e);
      }

      this.setState({ alertOpen: false });

      const temp = this.state.postObject;

      temp.cities = [city.id];

      temp.zones = zones;
      console.log(object.selectedMall);

      this.setState({ mallName: object.selectedMall });

      this.setState({ postObject: temp });
    }
  };

  checkCatchmentsRights = (postObject, userData, mallName) => {
    console.log(userData);
    const mallSubscribed = userData.catchments.find(
      (mall) => mall.name === mallName
    );
    const city = this.state.cities.find((c) => c.id === postObject.cities[0]);
    if (mallSubscribed || userData.cities.includes(city.city)) {
      this.setState({ allCatchments: true });
      return { postObject: { ...postObject, subCombos: [] } };
    } else return false;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  postData = (displayMode, postObject, userData, mallName) => {
    console.log(displayMode);
    this.checkSubscription();
    this.setState({ subscriber: true });

    let isSubscribed = mallName
      ? this.checkCatchmentsRights(postObject, userData, mallName)
      : this.checkUserRights(postObject, userData);

    if (!isSubscribed) {
      if (!userData.username) {
        this.setState({ registeredUser: false });
      }
      this.setState({ subscriber: false, subscriptionAlertOpen: true });
      return false;
    }

    // this.setState({ postObject: isSubscribed });
    let isEmpty = false;
    let isCatEmpty = false;
    if (postObject.cities.length === 0) isEmpty = true;
    if (postObject.zones.length === 0) isEmpty = true;
    if (postObject.nationalities.length === 0) isEmpty = true;
    if (postObject.months.length === 0) isEmpty = true;
    if (postObject.years.length === 0) isEmpty = true;
    if (
      postObject.categories.length === 0 &&
      postObject.subCategories.length === 0 &&
      postObject.subSubCategories.length === 0
    )
      isCatEmpty = true;

    if (isEmpty || isCatEmpty) {
      alert("Please Select all required options");
      this.setState({ isModalOpen: false, alertOpenInvalid: true });
      return;
    }

    this.setState({ displayMode: displayMode });
    this.setState({ isModalOpen: false });
    //changing tableData to [] so loading screen appears again in Table.js
    this.setState({ tableData: [] });
    this.setState({ loading: true, listExpanded: false });

    let dataToBePost = isSubscribed.postObject;
    dataToBePost["filter_type"] = displayMode;

    axios
      .post(API_URL, dataToBePost)
      .then((res) => {
        let tableData = res.data.results;
        this.setState({
          tableData,
          yearChosen: postObject.years[0],
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  checkSubscription = () => {
    if (!this.state.subscriber || !this.state.registeredUser) {
      this.setState({ subscriptionAlertOpen: true });
    }
  };

  handleSubscriptionAlert = () => {
    this.setState({ subscriptionAlertOpen: false });
  };
  showOneTimeSubPopUp = () => {
    this.setState({ oneTimeSubPopUpOpen: true });
  };
  handleCancelPopUp = (state) => {
    this.setState({ cancelPopUpOpen: state });
    this.setState({ alertOpenInvalid: false });
    if (!state) this.submitForm(this.dataToBeEmailed, false);
  };

  hideOneTimeSubPopUp = () => {
    this.setState({ oneTimeSubPopUpOpen: false });
    this.setState({ alertOpenInvalid: false });
    this.setState({ subscriptionAlertOpen: false });
    this.submitForm(this.dataToBeEmailed, true);
  };

  submitForm = (data, oneTime) => {
    const user = this.context.userData;

    const createFormInput = (name, value, form) => {
      let input = document.createElement("input");
      input.name = name;
      input.value = value;
      form.appendChild(input);
    };

    let formTarget = user.username ? "hidden-frame" : "_blank";
    if (oneTime) formTarget = "hidden-frame";

    let form = document.createElement("form");
    form.style.visibility = "hidden"; // no user interaction is necessary
    form.method = "POST"; // forms by default use GET query strings
    form.target = formTarget;
    form.action = CANCEL_URL;

    if (user.username) {
      createFormInput("username", user.username, form);
    }
    if (this.state.mallName) {
      if (oneTime) {
        createFormInput(
          "type",
          `One Time Buy from catchments for the mall: ${this.state.mallName}`,
          form
        );
      } else {
        createFormInput(
          "type",
          `Cancel from catchments for the mall: ${this.state.mallName}`,
          form
        );
      }
    } else {
      if (oneTime) {
        createFormInput("type", "One Time Buy", form);
      } else {
        createFormInput("type", "Cancel", form);
      }
    }
    for (let attribute in data) {
      createFormInput(attribute, data[attribute].join(), form);
    }
    document.body.appendChild(form);
    form.submit();
  };

  checkData = () => {
    if (localStorage.getItem("selectionsMade")) {
      this.setState({ isModalOpen: true, alertOpenInvalid: false });
      return true;
    }
    let isEmpty = false;
    let isCatEmpty = false;
    this.setState({ subscriber: true });
    if (this.state.postObject.cities.length === 0) isEmpty = true;
    if (this.state.postObject.zones.length === 0) isEmpty = true;
    if (this.state.postObject.nationalities.length === 0) isEmpty = true;
    if (this.state.postObject.months.length === 0) isEmpty = true;
    if (this.state.postObject.years.length === 0) isEmpty = true;
    if (
      this.state.postObject.categories.length === 0 &&
      this.state.postObject.subCategories.length === 0 &&
      this.state.postObject.subSubCategories.length === 0
    )
      isCatEmpty = true;
    if (isEmpty || isCatEmpty) {
      // alert('Please Select all required options');
      this.setState({
        isModalOpen: false,
        alertOpenInvalid: true,
      });
      return;
    } else {
      this.setState({ isModalOpen: true, alertOpenInvalid: false });
    }
  };

  //Used to create state variables that are used to display the menus
  createData(receivedData) {
    let optionData = receivedData;

    //Using raw api data to fill in menu variables
    //months is static for now, can be changed later
    this.setState({
      years: optionData[0][1],
      category: optionData[3][1],
      cities: optionData[4][1],
      nationality: optionData[2][1],
    });
    this.setState({ menuLoading: false });
    return {
      years: optionData[0][1],
      category: optionData[3][1],
      cities: optionData[4][1],
      nationality: optionData[2][1],
    };
  }

  //Adding a single zone
  addZone = (whereToBePushed, itemToBePushed, e) => {
    var tempData = this.state.postObject;
    var cities = this.state.cities;

    //Adding/Deleting zone
    const zoneIdx = tempData.zones.findIndex((zone) => zone === itemToBePushed);
    if (zoneIdx === -1) tempData.zones.push(itemToBePushed);
    else tempData.zones.splice(zoneIdx, 1);

    let citiesOfZones = [];
    for (let zoneSelected of tempData.zones) {
      for (let city of cities) {
        if (city.zone.findIndex((zone) => zone.id === zoneSelected) !== -1)
          citiesOfZones.push(city.id);
      }
    }
    citiesOfZones = [...new Set(citiesOfZones)];
    tempData.cities = citiesOfZones;

    this.setState({ postObject: tempData });
  };

  //Called for Months, Years, Nationalities, SubSubCategories

  addItem = (whereToBePushed, itemToBePushed, e) => {
    var tempData = this.state.postObject;

    if (e.target.checked) {
      tempData[whereToBePushed].push(itemToBePushed);
      this.setState({ postObject: tempData });
    } else {
      tempData[whereToBePushed].splice(
        tempData[whereToBePushed].indexOf(itemToBePushed),
        1
      );
      this.setState({ postObject: tempData });
    }
  };

  selectAllPurchaseMode = (e) => {
    let tempData = this.state.postObject;
    if (e.target.checked) {
      let purchaseMode = ["online", "offline"];
      tempData = { ...tempData, purchaseMode };
      this.setState({ postObject: tempData });
    } else {
      tempData = { ...tempData, purchaseMode: [] };
      this.setState({ postObject: tempData });
    }
  };

  selectAllPlaceOfPurchase = (e) => {
    let tempData = this.state.postObject;
    if (e.target.checked) {
      let placeOfPurchase = ["in", "out"];
      tempData = { ...tempData, placeOfPurchase };
      this.setState({ postObject: tempData });
    } else {
      tempData = { ...tempData, placeOfPurchase: [] };
      this.setState({ postObject: tempData });
    }
  };

  //Called for Cities
  selectAllZones = (whereToBePushed, itemToBePushed, e) => {
    var tempData = this.state.postObject;
    const cities = this.state.cities;
    //checkArray will get all zones of particular city
    const checkArray = [];

    //Populating checkArray
    cities.forEach((data) => {
      if (data.id === itemToBePushed) {
        data.zone.forEach((id) => {
          checkArray.push(id.id);
        });
      }
    });

    //Adding city id to city
    if (e.target.checked) {
      //Check if city already exists in propObject,
      //if it does not, add city
      //if it does, only add respective zones
      if (tempData.cities.indexOf(itemToBePushed) === -1) {
        tempData.zones = _.union([...checkArray], [...tempData.zones]);
        tempData[whereToBePushed].push(itemToBePushed);
        this.setState({ postObject: tempData });
      } else {
        tempData.zones = _.union([...checkArray], [...tempData.zones]);
        this.setState({ postObject: tempData });
      }
    } else {
      tempData[whereToBePushed].splice(
        tempData[whereToBePushed].indexOf(itemToBePushed),
        1
      );
      tempData.zones = tempData.zones.filter((el) => !checkArray.includes(el));
      this.setState({ postObject: tempData });
    }
  };

  //Called for Categories
  selectAllSubCategories = (whereToBePushed, itemToBePushed, e) => {
    var tempData = this.state.postObject;
    const category = this.state.category;
    //checkSubArray will get all sub categories of particular category
    const checkSubArray = [];
    //checkSubSubArray will get all sub sub categories of particular category
    const checkSubSubArray = [];

    //Populating checkSubArray
    category.forEach((main) => {
      if (main.id === itemToBePushed) {
        main.sub_category.forEach((sub) => {
          checkSubArray.push(sub.id);
        });
      }
    });

    //Populating checkSubSubArray
    category.forEach((main) => {
      if (main.id === itemToBePushed) {
        main.sub_category.forEach((sub) => {
          sub.sub_sub_category.forEach((subsub) => {
            checkSubSubArray.push(subsub.id);
          });
        });
      }
    });

    //Delete all subCat and subSubCat that are present in particular Category
    tempData.subSubCategories = tempData.subSubCategories.filter(
      (el) => !checkSubSubArray.includes(el)
    );
    tempData.subCategories = tempData.subCategories.filter(
      (el) => !checkSubArray.includes(el)
    );

    //Adding Category id to Category
    if (e.target.checked) {
      tempData[whereToBePushed].push(itemToBePushed);
      this.setState({ postObject: tempData });
    } else {
      tempData[whereToBePushed].splice(
        tempData[whereToBePushed].indexOf(itemToBePushed),
        1
      );
      this.setState({ postObject: tempData });
    }
  };

  selectAllSubSubCategories = (whereToBePushed, itemToBePushed, e) => {
    var tempData = this.state.postObject;
    const category = this.state.category;
    //checkArray is for all subSubCategories for a particular Category
    const checkArray = [];

    //Populating checkArray
    category.forEach((main) => {
      main.sub_category.forEach((sub) => {
        if (sub.id === itemToBePushed) {
          sub.sub_sub_category.forEach((subsub) => {
            checkArray.push(subsub.id);
          });
        }
      });
    });

    //Deleting all subSubCategories present in particular category
    tempData.subSubCategories = tempData.subSubCategories.filter(
      (el) => !checkArray.includes(el)
    );

    //Adding subCategory id to subCategories
    if (e.target.checked) {
      tempData[whereToBePushed].push(itemToBePushed);
      this.setState({ postObject: tempData });
    } else {
      tempData[whereToBePushed].splice(
        tempData[whereToBePushed].indexOf(itemToBePushed),
        1
      );
      this.setState({ postObject: tempData });
    }
  };

  selectAllNationalities = (e) => {
    const tempData = this.state.postObject;
    const allNationalities = this.state.nationality.map((nation) => nation.id);

    if (e.target.checked) {
      tempData.nationalities = allNationalities;
      this.setState({ postObject: tempData });
    } else {
      tempData.nationalities = [];
      this.setState({ postObject: tempData });
    }
  };

  addMonths = (months) => {
    const tempData = this.state.postObject;
    tempData.months = months;
    this.setState({ postObject: tempData });
  };
  // RESET SELECTIONS
  resetSelections = (e) => {
    // e.preventDefault();

    this.setState({
      postObject: {
        cities: [],
        zones: [],
        years: [],
        months: [],
        categories: [],
        subCategories: [],
        subSubCategories: [],
        nationalities: [],
        purchaseMode: [],
        placeOfPurchase: [],
      },
    });
    window.location.href = window.location.href.split("?")[0];
  };

  handleAccordian = () => {
    this.setState({ listExpanded: !this.state.listExpanded });
  };

  categoryDisplayer = (cat, sub, subsub) => {
    let catArray = [];
    let subObject = {};
    let subsubObject = {};
    if (cat.length > 0) {
      catArray = cat.map((item) => item.toUpperCase() + ">All items");
      catArray = [...new Set(catArray)];
    }
    if (sub.length > 0) {
      for (let category of this.state.category) {
        for (let subItem of sub) {
          if (
            category.sub_category.some((sub_cat) => sub_cat.name === subItem)
          ) {
            if (subObject.hasOwnProperty(category.name)) {
              subObject[category.name].push(subItem);
            } else {
              subObject[category.name] = [subItem];
            }
          }
        }
      }
    }
    if (subsub.length > 0) {
      const categories = this.state.category;
      for (let subsubItem of subsub) {
        for (let category of categories) {
          for (let subcategory of category.sub_category) {
            if (
              subcategory.sub_sub_category.some((el) => el.name === subsubItem)
            ) {
              if (subsubObject.hasOwnProperty(category.name)) {
                if (
                  subsubObject[category.name].hasOwnProperty(subcategory.name)
                ) {
                  subsubObject[category.name][subcategory.name].push(
                    subsubItem
                  );
                } else {
                  subsubObject[category.name][subcategory.name] = [subsubItem];
                }
              } else {
                subsubObject[category.name] = {};
                subsubObject[category.name][subcategory.name] = [subsubItem];
              }
            }
          }
        }
      }
    }

    let finalStr = [...catArray];
    for (let [key, values] of Object.entries(subObject)) {
      finalStr.push(`${key.toUpperCase()}>${values}>All Items`);
    }
    for (let [category, subcategory] of Object.entries(subsubObject)) {
      for (let [subcat, subsubcategory] of Object.entries(subcategory)) {
        finalStr.push(`${category.toUpperCase()}>${subcat}>${subsubcategory}`);
      }
    }
    return finalStr.join(", ");
  };
  zonesToCity = (zones) => {
    const cities = this.state.cities;
    let citiesAndZones = {};
    zones.forEach((zone) => {
      cities.forEach((city) => {
        if (city.zone.some((data) => data.id === zone.id)) {
          if (citiesAndZones.hasOwnProperty(city.city))
            citiesAndZones[city.city].push(zone.zone);
          else citiesAndZones[city.city] = [zone.zone];
        }
      });
    });

    let finalStr = [];
    for (let [city, zones] of Object.entries(citiesAndZones)) {
      let cityItem = "";
      cityItem += city + ">";
      let maxZones = this.state.cities.find((data) => data.city === city).zone
        .length;
      if (zones.length === maxZones) cityItem += "All zones";
      else cityItem += zones;
      finalStr.push(cityItem);
    }
    return finalStr;
  };

  getCity = (id) => {
    for (let cityOption of this.state.cities) {
      if (id === cityOption.id) {
        return {
          id,
          name: cityOption.city,
        };
      }
    }
  };

  getCategory = (id, propertyName) => {
    if (propertyName == "category") {
      let category = this.state.category.find((cat) => cat.id === id);
      return { name: category.name, id: category.id };
    } else if (propertyName === "subcategory") {
      let category = {};
      this.state.category.forEach((cat) => {
        let found = false;
        cat.sub_category.forEach((sub_cat) => {
          if (sub_cat.id === id) {
            category.name = cat.name;
            category.id = cat.id;
            found = true;
            return;
          }
        });
        if (found) return;
      });
      return category;
    } else if (propertyName === "subsubcategory") {
      let category = {};
      this.state.category.forEach((cat) => {
        let found = false;
        cat.sub_category.forEach((sub_cat) => {
          sub_cat.sub_sub_category.forEach((sub_sub_cat) => {
            if (sub_sub_cat.id === id) {
              category.name = cat.name;
              category.id = cat.id;
              found = true;
              return;
            }
          });
          if (found) return;
        });
        if (found) return;
      });
      return category;
    }
  };

  checkUserRights = (postObject, userData) => {
    const userDetails = userData;
    const citiesSelected = postObject.cities;
    const zonesSelected = postObject.zones;
    const catgSelected = postObject.categories;
    const subCatgSelected = postObject.subCategories;
    const subSubCatgSelected = postObject.subSubCategories;

    let dataToBeEmailed = {
      cities: [],
      categories: [],
      years: postObject.years,
      months: [],
      nationalities: [],
      placeOfPurchase: postObject.placeOfPurchase,
      purchaseMode: postObject.purchaseMode,
    };

    citiesSelected.forEach((city) => {
      this.state.cities.forEach((cityOption) => {
        if (city === cityOption.id) {
          dataToBeEmailed.cities = [...dataToBeEmailed.cities, cityOption.city];
        }
      });
    });

    dataToBeEmailed.categories = catgSelected.map(
      (catg) => this.getCategory(catg, "category").name
    );
    dataToBeEmailed.categories = [
      ...dataToBeEmailed.categories,
      ...subCatgSelected.map((sc) => this.getCategory(sc, "subcategory").name),
    ];
    dataToBeEmailed.categories = [
      ...dataToBeEmailed.categories,
      ...subSubCatgSelected.map(
        (ssc) => this.getCategory(ssc, "subsubcategory").name
      ),
    ];

    dataToBeEmailed.categories = [...new Set(dataToBeEmailed.categories)];

    dataToBeEmailed.months = postObject.months.map(
      (mon) => monthNames[mon - 1]
    );

    this.state.nationality.forEach((natOption) => {
      postObject.nationalities.forEach((nat) => {
        if (natOption.id === nat) {
          dataToBeEmailed.nationalities.push(natOption.nationality);
        }
      });
    });

    this.dataToBeEmailed = dataToBeEmailed;

    let allowedSubCombo = [];

    if (userDetails.username) {
      console.log("MMMMMMMMMMMMM", zonesSelected);
      console.log(catgSelected, subCatgSelected, subSubCatgSelected);
      console.log(userData);
      console.log(this.state.cities);

      if (
        zonesSelected.every(
          (zone) =>
            userData.catchments.findIndex((mall) => mall.zone === zone) !== -1
        )
      ) {
        return { postObject: { ...postObject, subCombos: [] } };
      }

      let allowedCities = [];

      const allowedZones = zonesSelected.filter((zone) => {
        const city = this.state.cities.find(
          (city) => city.zone.findIndex((z) => z.id === zone) !== -1
        );
        if (userDetails.cities.includes(city.city)) {
          allowedCities.push(city.id);
          return true;
        } else return false;
      });

      allowedCities = [...new Set(allowedCities)];

      const allowedcatg = catgSelected.filter((cat) => {
        const name = this.getCategory(cat, "category").name;
        return userDetails.categories.includes(name);
      });
      const allowedSubCatg = subCatgSelected.filter((subcat) => {
        const name = this.getCategory(subcat, "subcategory").name;
        return userDetails.categories.includes(name);
      });

      const allowedSubsubCatg = subSubCatgSelected.filter((subsubcat) => {
        const name = this.getCategory(subsubcat, "subsubcategory").name;
        return userDetails.categories.includes(name);
      });

      console.log(
        "Allowed",
        allowedZones,
        allowedcatg,
        allowedSubCatg,
        allowedSubsubCatg
      );

      for (let zone of allowedZones) {
        const cityOfZone = this.state.cities.find(
          (city) =>
            city.zone.findIndex((cityZone) => cityZone.id === zone) !== -1
        );
        for (let cat of allowedcatg) {
          const category = this.getCategory(cat, "category");
          if (
            userData.subCombos.findIndex(
              (sub) =>
                sub.category === category.name && sub.city === cityOfZone.city
            ) !== -1 &&
            allowedSubCombo.findIndex(
              (sub) => sub.city === cityOfZone.id && sub.category === cat
            ) === -1
          ) {
            allowedSubCombo.push({
              city: cityOfZone.id,
              category: cat,
            });
          }
        }
        for (let subcat of allowedSubCatg) {
          const category = this.getCategory(subcat, "subcategory");
          if (
            userData.subCombos.findIndex(
              (sub) =>
                sub.category === category.name && sub.city === cityOfZone.city
            ) !== -1 &&
            allowedSubCombo.findIndex(
              (sub) => sub.city === cityOfZone.id && sub.subcategory === subcat
            ) === -1
          ) {
            allowedSubCombo.push({
              city: cityOfZone.id,
              subcategory: subcat,
            });
          }
        }
        for (let subsubcat of allowedSubsubCatg) {
          const category = this.getCategory(subsubcat, "subsubcategory");
          if (
            userData.subCombos.findIndex(
              (sub) =>
                sub.category === category.name && sub.city === cityOfZone.city
            ) !== -1 &&
            allowedSubCombo.findIndex(
              (sub) =>
                sub.city === cityOfZone.id && sub.subsubcategory === subsubcat
            ) === -1
          ) {
            allowedSubCombo.push({
              city: cityOfZone.id,
              subsubcategory: subsubcat,
            });
          }
        }
      }
      console.log(allowedSubCombo);
      if (allowedSubCombo.length !== 0) {
        if (
          allowedSubCombo.length !==
          citiesSelected.length *
            (catgSelected.length +
              subCatgSelected.length +
              subSubCatgSelected.length)
        ) {
          this.setState({
            subscriber: false,
            alertOpenInvalid: true,
            alertMessage: {
              title: "Not subscribed to some categories.",
              message:
                "We see you have chosen some cities / categories you have not subscribed to. We will display the data for the ones you have subscription for." +
                "Or if you have selected zones falling under catchments please view through catchments page. For others choose one of the below",
            },
          });
        }

        const newPostObject = {
          ...postObject,
          cities: allowedCities,
          zones: allowedZones,
          categories: allowedcatg,
          subCategories: allowedSubCatg,
          subSubCategories: allowedSubsubCatg,
          subCombos: allowedSubCombo,
        };
        console.log(newPostObject);

        return {
          postObject: newPostObject,
        };
      } else {
        this.setState({ subscriber: false });
        console.log("Subscriber False!!!!");
        return false;
      }
    } else {
      this.setState({ registeredUser: false });
      return false;
    }
  };
  addYear = (year, years, months, e) => {
    if (years.length == 0 && months.length > 0 && year === currentYear) {
      const newMonths = months.filter((mon) => mon < currentMonth);
      this.addMonths(newMonths);
    }
    this.addItem("years", year, e);
  };

  render() {
    let checkCity = [];
    let checkZone = [];
    let checkYear = [];
    let checkMonth = [];
    let checkCategory = [];
    let checkSubCategory = [];
    let checkSubSubCategory = [];
    let checkNationality = [];
    if (this.state.postObject.cities.length > 0) {
      const cities = this.state.cities;
      const subcities = this.state.postObject.cities;
      const subzones = this.state.postObject.zones;
      //checkArray will get all zones of particular city

      //Populating checkArray
      cities.forEach((data) => {
        subcities.forEach((data2) => {
          if (data.id === data2) {
            checkCity.push(data.city);
            data.zone.forEach((id) => {
              subzones.forEach((data3) => {
                if (id.id === data3) {
                  checkZone.push(id);
                }
              });
            });
          }
        });
      });
    }
    if (this.state.postObject.years.length > 0) {
      const year = this.state.years;
      const subyear = this.state.postObject.years;
      year.forEach((data) => {
        subyear.forEach((data2) => {
          if (data === data2) {
            checkYear.push(data);
          }
        });
      });
      // checkYear = this.insertCommas(checkYear);
    }
    if (this.state.postObject.months.length > 0) {
      // const year = this.state.years;
      const submonth = this.state.postObject.months;
      submonth.forEach((data) => {
        if (data === 1) {
          checkMonth.push("January");
        }
        if (data === 2) {
          checkMonth.push("February");
        }
        if (data === 3) {
          checkMonth.push("March");
        }
        if (data === 4) {
          checkMonth.push("April");
        }
        if (data === 5) {
          checkMonth.push("May");
        }
        if (data === 6) {
          checkMonth.push("June");
        }
        if (data === 7) {
          checkMonth.push("July");
        }
        if (data === 8) {
          checkMonth.push("August");
        }
        if (data === 9) {
          checkMonth.push("September");
        }
        if (data === 10) {
          checkMonth.push("October");
        }
        if (data === 11) {
          checkMonth.push("November");
        }
        if (data === 12) {
          checkMonth.push("December");
        }
      });
      // checkMonth = this.insertCommas(checkMonth);s
    }
    if (this.state.postObject.categories.length > 0) {
      const cat = this.state.category;
      const subcat = this.state.postObject.categories;
      cat.forEach((data) => {
        subcat.forEach((data2) => {
          if (data.id === data2) {
            checkCategory.push(data.name);
          }
        });
      });
      // checkCategory = this.insertCommas(checkCategory);
    }
    if (this.state.postObject.subCategories.length > 0) {
      const cat = this.state.category;
      const subcat = this.state.postObject.subCategories;
      cat.forEach((data) => {
        subcat.forEach((data2) => {
          data.sub_category.forEach((data3) => {
            if (data3.id === data2) {
              checkSubCategory.push(data3.name);
            }
          });
        });
      });
      // checkSubCategory = this.insertCommas(checkSubCategory);
    }
    if (this.state.postObject.subSubCategories.length > 0) {
      const cat = this.state.category;
      const subcat = this.state.postObject.subSubCategories;
      cat.forEach((data) => {
        subcat.forEach((data2) => {
          data.sub_category.forEach((data3) => {
            data3.sub_sub_category.forEach((data4) => {
              if (data4.id === data2) {
                checkSubSubCategory.push(data4.name);
              }
            });
          });
        });
      });
      // checkSubSubCategory = this.insertCommas(checkSubSubCategory);
    }
    if (this.state.postObject.nationalities.length > 0) {
      const nat = this.state.nationality;
      const subnat = this.state.postObject.nationalities;
      nat.forEach((data) => {
        subnat.forEach((data2) => {
          if (data.id === data2) {
            checkNationality.push(data.nationality);
          }
        });
      });
    }

    return (
      <div style={{ position: "relative", width: "100vw" }}>
        <div
          ref={(el) => (this.top = el)}
          style={{ position: "absolute", top: 0, left: 0 }}
        ></div>{" "}
        {this.state.alertOpen && (
          <div ref={this.setWrapperRef}>
            <Alert
              title={"Instructions"}
              contentText={
                "Make selections in the panel on the left to view market sizes."
              }
              btnText={"CONTINUE"}
              ModalHandlerClose={this.ModalHandlerClose}
            />
          </div>
        )}
        <div>
          <Layout
            style={{
              minHeight: "100vh",
            }}
            hasSider
          >
            <Sider
              width={400}
              collapsed={this.state.siderCollapsed}
              className="mkt-size-sider"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100vh",
                overflowY: "scroll",
                position: "sticky",
                left: 0,
                top: 0,
                paddingTop: "10px",
              }}
            >
              {this.state.siderCollapsed ? (
                <span className="monetization">
                  <MonetizationOn
                    style={{
                      fontSize: "4rem",
                      border: "1px solid white",
                      borderRadius: "50%",
                      overflow: "hidden",
                      backgroundColor: "#00ba4e",
                    }}
                  />
                </span>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "20px",
                  }}
                >
                  <MonetizationOn
                    style={{
                      fontSize: "5rem",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: "50%",
                      overflow: "hidden",
                      backgroundColor: "#00ba4e",
                      margin: "0 5px 5px 5px",
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      color: "black",
                      fontSize: "1.75rem",
                      textAlign: "left",
                      fontWeight: "700",
                    }}
                  >
                    Market Size Dashboard
                    <br />
                    <span
                      style={{
                        fontSize: "1rem",
                        fontWeight: "lighter",
                        marginTop: "-10px",
                      }}
                    >
                      {"\n"}
                      Make selections from the dropdowns below
                      {/* <TouchApp /> */}
                    </span>
                  </div>
                </div>
              )}
              {!this.state.menuLoading ? (
                <Menu
                  mode="inline"
                  style={{
                    minHeight: "80vh",
                    borderRight: 0,
                    overflowX: "hidden",
                  }}
                  theme={"light"}
                >
                  {!this.state.mallName &&
                    displayCities(
                      this.state.cities,
                      this.state.postObject,
                      this.addZone,
                      this.selectAllZones
                    )}
                  <SubMenu
                    key="Years"
                    title={
                      <span style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
                        Years
                      </span>
                    }
                    style={{
                      marginTop: "1rem",
                      fontWeight: "600",
                    }}
                    className="round-menu-items"
                    icon={
                      <Schedule
                        style={{ fontSize: "1.25rem", lineHeight: "1.75" }}
                      />
                    }
                  >
                    {this.state.years.map((year) => (
                      <Menu.Item key={year}>
                        <Checkbox
                          onClick={(e) =>
                            this.addYear(
                              year,
                              this.state.postObject.years,
                              this.state.postObject.months,
                              e
                            )
                          }
                          checked={this.state.postObject.years.includes(year)}
                        >
                          {year}
                        </Checkbox>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                  <SubMonths
                    addmonths={this.addMonths}
                    monthsSelected={this.state.postObject.months}
                    className="round-menu-items"
                    style={{
                      marginTop: "1rem",
                      fontWeight: "600",
                    }}
                    years={this.state.postObject.years}
                    icon={
                      <Apps
                        style={{ fontSize: "1.25rem", lineHeight: "1.75" }}
                      />
                    }
                  />
                  <SubMenu
                    key="Category"
                    title={
                      <span style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
                        Categories
                      </span>
                    }
                    icon={
                      <ShoppingCart
                        style={{ fontSize: "1.25rem", lineHeight: "1.75" }}
                      />
                    }
                    className="round-menu-items"
                    style={{ marginTop: "1rem", fontWeight: "600" }}
                  >
                    {this.state.category
                      .sort((a, b) => a.id - b.id)
                      .map((main) => (
                        <SubCategory
                          key={main.id}
                          checked={this.state.postObject.categories.includes(
                            main.id
                          )}
                          main={main}
                          additem={this.addItem}
                          selectallsubsubcategories={
                            this.selectAllSubSubCategories
                          }
                          selectallsubcategories={this.selectAllSubCategories}
                          subCatgSelected={this.state.postObject.subCategories}
                          subsubcategoriesSelected={
                            this.state.postObject.subSubCategories
                          }
                        />
                      ))}
                  </SubMenu>
                  <SubNationality
                    nationality={this.state.nationality}
                    additem={this.addItem}
                    selectAllNationalities={this.selectAllNationalities}
                    natSelected={this.state.postObject.nationalities}
                    style={{ marginTop: "1rem", fontWeight: "600" }}
                    icon={
                      <SupervisedUserCircle
                        style={{
                          fontSize: "1.5rem",
                          lineHeight: "1.75",
                          marginLeft: "-3px",
                        }}
                      />
                    }
                    className="round-menu-items"
                  />
                  <SubMenu
                    key={uuid()}
                    expandIcon={<></>}
                    style={{
                      borderBottom: "2px solid #4f5bff",
                      marginInline: "5px",
                      height: "10px",
                    }}
                  ></SubMenu>
                  {this.state.siderCollapsed ? (
                    <Tooltip title="View Market Size" placement="left">
                      <SubMenu
                        key={uuid()}
                        icon={
                          <Visibility
                            style={{
                              fontSize: "1.5rem",
                              lineHeight: "1.75",
                              marginLeft: "-3px",
                            }}
                          />
                        }
                        title={
                          <span
                            style={{
                              fontSize: "1.25rem",
                              lineHeight: "1.75",
                              fontweight: "600",
                            }}
                          >
                            View Market Size
                          </span>
                        }
                        expandIcon={<CallMade />}
                        className="round-menu-items menu-btn vmk"
                        style={{ marginTop: "1rem", fontWeight: "600" }}
                        onTitleClick={this.checkData}
                      />
                    </Tooltip>
                  ) : (
                    <SubMenu
                      key={uuid()}
                      icon={
                        <Visibility
                          style={{
                            fontSize: "1.5rem",
                            lineHeight: "1.75",
                            marginLeft: "-3px",
                          }}
                        />
                      }
                      title={
                        <span
                          style={{
                            fontSize: "1.25rem",
                            lineHeight: "1.75",
                            fontweight: "600",
                          }}
                        >
                          View Market Size
                        </span>
                      }
                      expandIcon={<CallMade />}
                      className="round-menu-items menu-btn vmk"
                      style={{ marginTop: "1rem", fontWeight: "600" }}
                      onTitleClick={this.checkData}
                    />
                  )}

                  {this.state.siderCollapsed ? (
                    <Tooltip title="Reset Selections" placement="left">
                      <SubMenu
                        key={uuid()}
                        icon={
                          <Delete
                            style={{
                              fontSize: "1.5rem",
                              lineHeight: "1.75",
                              marginLeft: "-3px",
                            }}
                          />
                        }
                        onTitleClick={this.resetSelections}
                        expandIcon={<></>}
                        title={
                          <span
                            style={{
                              fontSize: "1.25rem",
                              lineHeight: "1.75",
                              fontweight: "600",
                            }}
                          >
                            Reset Selections
                          </span>
                        }
                        className="round-menu-items menu-btn rs"
                        style={{ marginTop: "1rem", fontWeight: "600" }}
                      />
                    </Tooltip>
                  ) : (
                    <SubMenu
                      key={uuid()}
                      icon={
                        <Delete
                          style={{
                            fontSize: "1.5rem",
                            lineHeight: "1.75",
                            marginLeft: "-3px",
                          }}
                        />
                      }
                      onTitleClick={this.resetSelections}
                      expandIcon={<></>}
                      title={
                        <span
                          style={{
                            fontSize: "1.25rem",
                            lineHeight: "1.75",
                            fontweight: "600",
                          }}
                        >
                          Reset Selections
                        </span>
                      }
                      className="round-menu-items menu-btn rs"
                      style={{ marginTop: "1rem", fontWeight: "600" }}
                    />
                  )}
                </Menu>
              ) : (
                <div
                  style={{
                    height: "100%",
                    borderRight: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    background: "white",
                  }}
                  theme={"light"}
                >
                  {[...Array(5)].map((e, i) => (
                    <Skeleton
                      key={i}
                      animation="wave"
                      height={65}
                      width={280}
                    />
                  ))}
                </div>
              )}
              <div
                className="collapse-btn"
                onClick={() =>
                  this.setState({ siderCollapsed: !this.state.siderCollapsed })
                }
              >
                {this.state.siderCollapsed ? (
                  <ArrowForwardIosOutlined />
                ) : (
                  <ArrowBackIos />
                )}
              </div>
            </Sider>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
              }}
            >
              <ViewOptionModal
                handleClose={() => this.setState({ isModalOpen: false })}
                handleRadioClick={(displayMode) =>
                  this.setState({
                    displayMode,
                    loading: false,
                  })
                }
                isModalOpen={this.state.isModalOpen}
                displayMode={this.state.displayMode}
                handlePost={() =>
                  this.postData(
                    this.state.displayMode,
                    this.state.postObject,
                    this.context.userData,
                    this.state.mallName
                  )
                }
              />
              {this.state.mallName && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1 style={{ color: "var(--darkBlue)" }}>
                      Catchments For The Mall:{" "}
                      <u style={{ fontWeight: 600 }}>{this.state.mallName} </u>
                    </h1>
                    <a
                      href={`https://data.merd.online:8000/maps/${this.state.mallName}.pdf`}
                      style={{ color: "white" }}
                      target="_blank"
                    >
                      <button
                        className={
                          this.isMallDownloadable(
                            this.context.userData,
                            this.state.cities,
                            this.state.mallName
                          )
                            ? "map-dwnld-btn"
                            : "map-dwnld-btn disabled"
                        }
                        disabled={
                          !this.isMallDownloadable(
                            this.context.userData,
                            this.state.cities,
                            this.state.mallName
                          )
                        }
                      >
                        Download Map <Download />
                      </button>
                    </a>
                  </div>
                </>
              )}
              <SelectionList
                cities={
                  this.state.postObject.cities.length > 0
                    ? this.zonesToCity(checkZone)
                    : "Select Cities & Zones"
                }
                nationalities={
                  this.state.postObject.nationalities.length > 0
                    ? checkNationality.length === //if all the nationalities are checked
                      this.state.nationality.length
                      ? "All Nationalities"
                      : checkNationality
                    : "Select Nationalities"
                }
                categories={
                  checkCategory.length > 0 ||
                  checkSubCategory.length > 0 ||
                  checkSubSubCategory.length > 0
                    ? this.categoryDisplayer(
                        checkCategory,
                        checkSubCategory,
                        checkSubSubCategory
                      )
                    : "Select Categories"
                }
                months={checkMonth}
                years={checkYear}
                handleAccordian={this.handleAccordian}
                listExpanded={this.state.listExpanded}
              />

              {this.state.alertOpenInvalid &&
                (this.state.subscriber ? (
                  <Alert
                    title={"Caution"}
                    contentText={
                      "You Need To Select All Filters Before Viewing Market Size"
                    }
                    btnText={"OK"}
                    ModalHandlerClose={this.ModalHandlerClose}
                  />
                ) : (
                  <SubscriptionAlert
                    registered={this.state.registeredUser}
                    handleSubscriptionAlert={this.handleSubscriptionAlert}
                    showOneTimeSubPopUp={this.showOneTimeSubPopUp}
                    handleCancelPopUp={this.handleCancelPopUp}
                    postObject={this.state.postObject}
                    handleClose={() =>
                      this.setState({ alertOpenInvalid: false })
                    }
                    onlyDemographic={
                      this.context.userData.categories.every(
                        (cat) => cat === "Demographic"
                      ) && this.context.userData.categories.length > 0
                    }
                    alertMessage={this.state.alertMessage}
                    takeToSub={() =>
                      this.setState({
                        subscriptionAlertOpen: false,
                        takingToSubMore: true,
                      })
                    }
                  />
                ))}
              {this.state.subscriptionAlertOpen && (
                <SubscriptionAlert
                  registered={this.state.registeredUser}
                  handleSubscriptionAlert={this.handleSubscriptionAlert}
                  showOneTimeSubPopUp={this.showOneTimeSubPopUp}
                  handleCancelPopUp={this.handleCancelPopUp}
                  postObject={this.state.postObject}
                  handleClose={() =>
                    this.setState({ subscriptionAlertOpen: false })
                  }
                  onlyDemographic={
                    this.context.userData.categories.every(
                      (cat) => cat === "Demographic"
                    ) && this.context.userData.categories.length > 0
                  }
                  alertMessage={this.state.alertMessage}
                  takeToSub={() =>
                    this.setState({
                      subscriptionAlertOpen: false,
                      takingToSubMore: true,
                    })
                  }
                />
              )}
              {this.state.takingToSubMore && (
                <ToSubAlert
                  handleClose={() =>
                    this.setState({
                      takingToSubMore: false,
                      alertOpenInvalid: false,
                    })
                  }
                />
              )}
              {this.state.oneTimeSubPopUpOpen && (
                <OneTimeSubPopUp
                  hideOneTimeSubPopUp={this.hideOneTimeSubPopUp}
                />
              )}
              {this.state.cancelPopUpOpen && (
                <CancelPopUp handleCancelPopUp={this.handleCancelPopUp} />
              )}
              <NotPaidPopUp
                open={this.state.notPaidModalOpen}
                setOpen={(status) =>
                  this.setState({ notPaidModalOpen: status })
                }
              />

              {!this.state.registeredUser ? (
                <h1 style={{ fontSize: "1.5rem" }}>
                  You need to subscribe to access data.
                </h1>
              ) : (
                this.state.loading && (
                  <div style={{ marginBlock: "4rem" }}>
                    {this.state.postObject.years.length > 1 && (
                      <Paper className="d-flex align-items-start w-100 mt-5">
                        <div
                          style={{
                            padding: "0 10px",
                            fontSize: "1.25rem",
                            fontWeight: "500",
                          }}
                        >
                          Choose Year
                        </div>
                        <br />
                        {this.state.postObject.years.sort().map((year) =>
                          this.state.yearChosen === year ? (
                            <div className="chosen-yr top-btn">{year}</div>
                          ) : (
                            <div
                              className="top-btn"
                              onClick={() => {
                                this.setState({ yearChosen: year });
                              }}
                            >
                              {year}
                            </div>
                          )
                        )}
                      </Paper>
                    )}

                    <Tables
                      data={this.state.tableData}
                      displayMode={this.state.displayMode}
                      purchaseMode={this.state.postObject.purchaseMode}
                      placeOfPurchase={this.state.postObject.placeOfPurchase}
                      allCatchments={this.state.allCatchments}
                      months={
                        checkMonth.length === 12 //if all the months are selected
                          ? "The Whole Year"
                          : checkMonth
                      }
                      user={this.context.userData}
                      nationalities={
                        this.state.postObject.nationalities.length > 0
                          ? checkNationality.length === //if all the nationalities are checked
                            this.state.nationality.length
                            ? "All Nationalities"
                            : checkNationality
                          : "Select Nationalities"
                      }
                      zones={this.zonesToCity(checkZone)}
                      yearChosen={this.state.yearChosen}
                    />
                  </div>
                )
              )}
            </Content>
          </Layout>
        </div>
        <iframe
          name="hidden-frame"
          style={{
            visibility: "hidden",
            height: 0,
            padding: 0,
            margin: 0,
            width: 0,
            border: "none",
          }}
        ></iframe>
      </div>
    );
  }
}

export default NewDashboard;
