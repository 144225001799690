import React, { Component } from "react";
import Filters from "./Filters";

export default class SubscriptionFilters extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
  }
  render() {
    return (
      <>
        <div className="elementor-widget-container">
          <style
            dangerouslySetInnerHTML={{
              __html:
                "/*! elementor - v3.5.3 - 28-12-2021 */\n.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}",
            }}
          />
          <div>
            <Filters />
          </div>
        </div>
      </>
    );
  }
}
