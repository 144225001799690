import React, { useReducer, useEffect } from "react";
import axios from "axios";
import Card from "./Card";
import { Business } from "@material-ui/icons";
import { Empty } from "antd";
import { Link } from "react-router-dom";

// const API_URL = "http://localhost:8000/demographic/";
const API_URL = "https://data.merd.online:8000/demographic/";
const ACTIONS = {
  GET_FILES: "get-files",
};
function reducer(state, action) {
  console.log(action.files);
  switch (action.type) {
    case ACTIONS.GET_FILES:
      const cities = [
        ...new Set(action.files.map((file) => file.split("_")[0].trim())),
      ];
      return { files: action.files, cities };
    default:
      throw "Invalid action";
  }
}

function CityFiles({ city, files, user, postObject, handleCancel }) {
  console.log(files);
  return (
    <div className="city-group">
      <div className="title-and-btn">
        <div className="demo-file-city-title flex items-center">
          <div className="flex items-center ">
            <div className="flex items-center p-2 m-2 bg-sky-200 rounded-full">
              <Business fontSize="large" color="primary" />
            </div>
            <span>{city}</span>
          </div>
          <button className="mx-4 p-2">
            <Link className="text-lg text-white" to={`maps?city=${city}`}>
              View Maps
            </Link>
          </button>
        </div>
      </div>
      <div className="city-grid">
        {files
          .filter((file) => file.includes(city))
          .map((file) => (
            <Card
              file={file}
              user={user}
              postObject={postObject}
              handleCancel={handleCancel}
            />
          ))}
      </div>
    </div>
  );
}

export default function FilesPage({ postObject, user, handleCancel }) {
  const [state, dispatch] = useReducer(reducer, { files: [], cities: [] });
  useEffect(() => {
    console.log(user);
    if (
      postObject.displayModes.length === 0 ||
      postObject.types.length === 0 ||
      postObject.cities.length === 0
    )
      window.location.href = "/new-demographic";
    console.log(postObject);

    axios
      .post(API_URL, { ...postObject, operation: "get-excel-files" })
      .then((res) => {
        console.log(res.data.files);
        dispatch({ type: ACTIONS.GET_FILES, files: res.data.files });
      });
  }, []);
  return (
    <div className="files-body">
      {state.files.length > 0 ? (
        state.cities.map((city) => (
          <CityFiles
            city={city}
            files={state.files}
            user={user}
            postObject={postObject}
            handleCancel={handleCancel}
          />
        ))
      ) : (
        <Empty description={<h1>NO FILES AVAILABLE</h1>} />
      )}
    </div>
  );
}
