import React from "react";
import ReportUpload from "./ReportUpload";
import FileUpload from "./FileUpload";
import DemographicUpload from "./DemographicUpload";
import UploadPublic from "./UploadPublic";
import BulkMapUpload from "./BulkMapUpload";

export default function Admin() {
  return (
    <div>
      <h1 align="center">Admin Side</h1>
      <FileUpload api={"upload_data/"} title={"Upload Data"} />
      <FileUpload api={"upload_census_data/"} title={"Upload Census Data"} />
      <ReportUpload title="Tourist Reports" url="tourist_reports/" />
      <DemographicUpload />
      <BulkMapUpload />
      <UploadPublic />
    </div>
  );
}
