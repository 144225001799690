import React, { useRef } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import FCExporter from "fusioncharts/fusioncharts.widgets";

ReactFusioncharts.fcRoot(FusionCharts, charts, FusionTheme, FCExporter);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function DetailedLineChart({
  area,
  nationality,
  year,
  category,
  isRegionWise,
  comparisonType,
  allData,
  yearlyComparisonOptions,
  catgComparisonOptions,
  monthsSelected,
  allCategories,
}) {
  const chartRef = useRef(null);
  let dataset = [];
  let caption = "";
  let areaName = "";
  let xAxisName;

  switch (comparisonType) {
    case "year":
      for (let year of yearlyComparisonOptions.years) {
        const reqdDivision = allData[String(year)].find(
          (reg) => reg.id === area
        );
        areaName = reqdDivision.name;
        const newData = {
          seriesname: String(year),
          data: [],
        };
        for (let key of Object.keys(reqdDivision)) {
          if (key.includes("ms_")) {
            const catId = key.split("_")[1];
            if (Number(catId) === yearlyComparisonOptions.category) {
              newData.data.push({ value: reqdDivision[key] });
            }
          }
        }
        dataset.push(newData);
      }
      caption = `Region: ${areaName}, Years: ${yearlyComparisonOptions.years.join(
        ", "
      )}, Category: ${category.name}`;
      xAxisName = `<b>Months (${yearlyComparisonOptions.years.join(", ")})</b>`;

      break;

    case "category":
      const reqdDivision = allData[String(catgComparisonOptions.year)].find(
        (reg) => reg.id === area
      );
      areaName = reqdDivision.name;

      for (let catId of catgComparisonOptions.categories) {
        const newData = {
          seriesname: allCategories.find((cat) => cat.id === catId).name,
          data: [],
        };
        for (let key of Object.keys(reqdDivision)) {
          if (key.includes("ms_") && !key.includes("Total")) {
            const mscatId = key.split("_")[1];
            if (Number(mscatId) === catId) {
              newData.data.push({ value: reqdDivision[key] });
            }
          }
        }
        dataset.push(newData);
      }
      caption = `Region: ${areaName}, Year: ${catgComparisonOptions.year}`;
      break;
  }

  const monthsAxis = monthsSelected.map((mon) => ({ label: months[mon - 1] }));

  const dataSource = {
    chart: {
      theme: "fusion",
      caption,
      xAxisName,
      yAxisName: "<b>Market Size (USD)</b>",
      animation: "0",
      numberprefix: "$",
      forceDecimals: "1",
      chartBottomMargin: "70",
      decimals: "1",
      xAxisNameFontSize: "18",
      xAxisNameFontColor: "#3a3a3a",
      xAxisNameFontBold: "1",
      yAxisNameFontSize: "18",
      yAxisNameFontColor: "#3a3a3a",
      yAxisNameFontBold: "1",
      legendIconBorderThickness: "8",
      maxLabelWidthPercent: "3",
      exportenabled: "1",
      exportFormats: "PNG=Export as PNG",
      exportfilename: caption,
    },
    categories: [
      {
        category: monthsAxis,
      },
    ],
    annotations: {
      autoScale: "1",
      groups: [
        {
          id: "detailed-line-chart",
          items: [
            {
              id: "new-id",
              type: "text",
              //Define the attributes needed to create a text annotation
              text: "<u>Source: INTELLIGENT INSIGHTS</u>",

              fillcolor: "#000000",
              fontsize: "12",
              bold: "1",
              wrap: "1",
              wrapWidth: "350",
              x: "$canvasEndX-120",
              y: "$canvasEndY + 160",
            },
          ],
        },
      ],
    },
    dataset,
  };

  const handleExport = () => {
    chartRef.current.chartObj.exportChart({
      exportFileName: caption,
    });
  };

  return (
    <div>
      {" "}
      <div className="w-100 d-flex align-items-center justify-content-end">
        <button onClick={handleExport}>Download Chart</button>
      </div>
      <ReactFusioncharts
        fcEvent-exportLinkedChart="handleExport"
        ref={chartRef}
        type="msline"
        width="100%"
        height="400"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
}
