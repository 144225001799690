import * as React from "react";
import { Button, Modal } from "@material-ui/core";
import { Close, Info } from "@material-ui/icons";
import { v4 as uuid } from "uuid";

export default function AlertDialog({
  open,
  setOpen,
  user,
  postObject,
  handleCancel,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const handlePopUpCancel = () => {
    handleCancel();
    handleClose();
  };

  const handleSubscribe = () => {
    let newPostObject = { ...postObject, type: "demographic" };
    localStorage.setItem("selectionsMade", JSON.stringify(newPostObject));
    window.location.href = "https://intelligentinsights.me/subscription/";
  };
  const handleLogin = () => {
    let newPostObject = { ...postObject, type: "demographic" };
    localStorage.setItem("selectionsMade", JSON.stringify(newPostObject));
    window.location.href = "https://intelligentinsights.me/login/";
  };
  const dialogText = user.username
    ? "You are trying to download files of the city you are not subscribed to."
    : "Please subscribe to download files.If you have an active account please login.";
  const dialogFooter = user.username ? (
    <>
      <div onClick={handleSubscribe}>Subscribe More</div>
      <div onClick={handleClose}>Cancel</div>
    </>
  ) : (
    <>
      <div onClick={handleSubscribe} autoFocus>
        Susbcribe
      </div>
      <div className="passive" onClick={handleLogin}>
        Login
      </div>
      <div className="passive" onClick={handlePopUpCancel}>
        Cancel
      </div>
    </>
  );

  return (
    <div>
      <Modal key={uuid()} open={open}>
        <div className="view-option-modal">
          <div className="top-header">
            <div>
              <Info
                style={{
                  marginRight: "10px",
                  fontSize: "2rem",
                  color: "royalblue",
                  borderRadius: "50%",
                  backgroundColor: "#bbf7fc",
                }}
              />
              <span>You can view more data</span>
            </div>
            <Close onClick={handleClose} className="modal-close-button" />
          </div>
          <div className="display-options font-bold">{dialogText}</div>
          <div className="submit-btn-div">{dialogFooter}</div>
        </div>
      </Modal>
    </div>
  );
}
