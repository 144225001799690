import React, { Component } from "react";
import * as _ from "lodash";

import { Table } from "antd";

import "../../../css/tables.css";
import { v4 as uuidv4 } from "uuid";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import { saveAs } from "file-saver";
import TitleHeader from "../TitleHeader";

async function download(
  year,
  city,
  nationality,
  monthsSelected,
  category,
  zones,
  purchaseMode,
  propertyName,
  data,
  user,
  allCatchments,
  population
) {
  const wb = new ExcelJS.Workbook();

  const ws = wb.addWorksheet();

  ws.addRow(["", ""]);

  ws.getRow(1).height = 50;
  ws.mergeCells("A1:D1");
  const headerCell = ws.getCell("A1");
  headerCell.value = `Market Size (In USD) For City: ${city} / Zones:${zones} / Year: ${year} / Months: ${monthsSelected} / Category: Categories Selected /Nationalities: ${nationality} / Purchase Mode : ${purchaseMode}`;
  const firstRow = ws.getRow(1);
  firstRow.font = { bold: true, size: 14, underline: true };
  firstRow.alignment = { vertical: "middle" };

  for (let i = 1; i <= 4; i++) {
    ws.getColumn(i).width = 50;
  }
  const titles = [
    "Category",
    "Population",
    "Market Size (USD)",
    "Market Size/Capita (USD)",
  ];
  for (let i = 1; i <= 4; i++) {
    const headerCell = ws.getCell(2, i);
    headerCell.font = { size: 14, bold: true, color: { argb: "FFFFFF" } };
    headerCell.alignment = { vertical: "middle", horizontal: "center" };
    headerCell.border = {
      top: { style: "thick", color: { argb: "00000000" } },
      left: { style: "thick", color: { argb: "00000000" } },
      bottom: { style: "thick", color: { argb: "00000000" } },
      right: { style: "thick", color: { argb: "00000000" } },
    };
    headerCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    };
    headerCell.value = titles[i - 1];
    headerCell.height = 25;
  }

  data.forEach((row) => {
    let mkSize;
    propertyName === "category"
      ? user.subCombos.find(
          (sub) =>
            sub.city === city &&
            sub.category === row[propertyName] &&
            sub.active
        ) || allCatchments
        ? row.total_market_size === 0
          ? (mkSize = "NA")
          : (mkSize = _.round(row.total_market_size, -3))
        : (mkSize = "Not Subscribed")
      : user.subCombos.find(
          (sub) => sub.city === city && sub.category === category && sub.active
        ) || allCatchments
      ? row.total_market_size === 0
        ? (mkSize = "NA")
        : (mkSize = _.round(row.total_market_size, -3))
      : (mkSize = "Not Subscribed");
    const curRow = ws.addRow([
      row[propertyName],
      population,
      mkSize,
      _.isNumber(mkSize) && population > 0
        ? _.round(mkSize / population)
        : "NA",
    ]);
    curRow.alignment = { horizontal: "center", vertical: "middle" };
  });
  let totalData = "";
  propertyName === "category"
    ? data.every((row) =>
        user.subCombos.find(
          (sub) =>
            sub.city === city &&
            sub.category === row[propertyName] &&
            sub.active
        )
      ) || allCatchments
      ? (totalData = _.round(calcTotal(data), -3))
      : (totalData = "Not Subscribed")
    : data.every((_) =>
        user.subCombos.find(
          (sub) => sub.city === city && sub.category === category && sub.active
        )
      ) || allCatchments
    ? (totalData = _.round(calcTotal(data), -3))
    : (totalData = "Not Subscribed");
  const totalRow = ws.addRow([
    "Total",
    population,
    totalData,
    _.isNumber(totalData) && population > 0
      ? _.round(totalData / population)
      : "NA",
  ]);
  // totalRow.numFmt = "[$$-en-US]#,##0";
  totalRow.alignment = { horizontal: "center", vertical: "middle" };
  totalRow.font = { bold: true };

  ws.getColumn(3).numFmt = "[$$-en-US]#,##0";
  ws.getColumn(4).numFmt = "[$$-en-US]#,##0";

  ws.eachRow({ includeEmpty: false }, function (row, rowNumber) {
    if (rowNumber === 1) return;
    row.height = 25;
  });

  const lastRow = ws.rowCount;
  for (let i = 1; i <= 4; i++) {
    ws.getCell(lastRow, i).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "DDEBF7" },
    };
  }

  const buf = await wb.xlsx.writeBuffer();

  saveAs(new Blob([buf]), `${year}_${nationality}.xlsx`);
}
function calcTotal(data) {
  let total = 0;
  data.forEach((row) => (total += _.round(row.total_market_size, -3)));
  return total;
}

class ZoneTable extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef(null);

    this.state = {
      dataSource: [],
      columns: [],
    };
  }
  componentDidMount = () => {
    this.setState({
      columns: [
        {
          title: <span className="bolder-header">Category</span>,
          dataIndex: "category",
          key: uuidv4(),
          align: "center",
          width: "25%",
          render: (x) => <b>{x}</b>,
        },
        {
          title: <span className="bolder-header">Population</span>,
          dataIndex: "population",
          key: uuidv4(),
          align: "center",
          width: "25%",

          render: (x) => (
            <b>
              {" "}
              {_.isNumber(x) && _.isFinite(x)
                ? `${x.toLocaleString("en", { useGrouping: true })}`
                : "N/A"}
            </b>
          ),
        },
        {
          title: <span className="bolder-header">Market Size (USD)</span>,
          dataIndex: "market_size",
          key: uuidv4(),
          align: "center",
          width: "25%",

          render: (x) => (
            <b>
              {" "}
              {_.isNumber(x) && _.isFinite(x)
                ? `$${x.toLocaleString("en", { useGrouping: true })}`
                : "N/A"}
            </b>
          ),
        },
        {
          title: (
            <span className="bolder-header">Market Size/Capita (USD)</span>
          ),
          dataIndex: "ms_cap",
          key: uuidv4(),
          width: "25%",
          render: (x) => (
            <b>
              {_.isNumber(x) && _.isFinite(x)
                ? `$${x.toLocaleString("en", { useGrouping: true })}`
                : "N/A"}
            </b>
          ),
          align: "center",
        },
      ],
    });
    const newDataSource = [];
    this.props.data.forEach((row) => {
      console.log(row);
      const categoryField = row[this.props.propertyName];
      let mktSize;
      if (this.props.propertyName === "category") {
        if (
          this.props.user.subCombos.find(
            (sub) =>
              (sub.city === this.props.city &&
                sub.category === row[this.props.propertyName] &&
                sub.active) ||
              this.props.allCatchments
          )
        ) {
          mktSize = row.total_market_size;
        } else {
          mktSize = (
            <a href="https://intelligentinsights.me/subscription/">
              <button>Susbcribe to view</button>
            </a>
          );
        }
      } else {
        if (
          this.props.user.subCombos.find(
            (sub) =>
              sub.city === this.props.city &&
              sub.category === this.props.category &&
              sub.active
          ) ||
          this.props.allCatchments
        ) {
          mktSize = row.total_market_size;
        } else {
          mktSize = (
            <a href="https://intelligentinsights.me/subscription/">
              <button>Susbcribe to view</button>
            </a>
          );
        }
      }
      newDataSource.push({
        key: uuidv4(),
        category: categoryField,
        population: this.props.population,
        market_size: mktSize,
        ms_cap: _.round(mktSize / this.props.population),
      });
    });

    let totalMktSize;

    if (this.props.propertyName === "category") {
      if (
        this.props.data.every((row) =>
          this.props.user.subCombos.find(
            (sub) =>
              sub.city === this.props.city &&
              sub.category === row[this.props.propertyName] &&
              sub.active
          )
        ) ||
        this.props.allCatchments
      ) {
        totalMktSize = calcTotal(this.props.data);
      } else {
        totalMktSize = (
          <a href="https://intelligentinsights.me/subscription/">
            <button>Susbcribe to view</button>
          </a>
        );
      }
    } else {
      if (
        this.props.user.subCombos.find(
          (sub) =>
            sub.city === this.props.city &&
            sub.category === this.props.category &&
            sub.active
        ) ||
        this.props.allCatchments
      ) {
        totalMktSize = calcTotal(this.props.data);
      } else {
        totalMktSize = (
          <a href="https://intelligentinsights.me/subscription/">
            <button>Susbcribe to view</button>
          </a>
        );
      }
    }

    newDataSource.push({
      key: uuidv4(),
      category: "TOTAL",
      population: this.props.population,

      market_size: totalMktSize,
      ms_cap: _.round(totalMktSize / this.props.population),
    });

    this.setState({ dataSource: newDataSource });
  };

  getZones(city, citiesAndZones) {
    for (let cityAndZone of citiesAndZones) {
      if (cityAndZone.includes(city)) {
        return cityAndZone;
      }
    }
  }
  getCategories = (propertyName, data) => {
    return data.map((row) => row[propertyName]).join();
  };

  render() {
    console.log(this.props.population);

    if (this.props.data.length > 0) {
      let empty = false;
      // this.props.data.forEach(obj => {
      //     if (obj.total_market_size > 0) empty = false;
      // })
      if (!empty) {
        const {
          data,
          propertyName,
          year,
          city,
          zones,
          months,
          nationalities,
          purchaseMode,
          category,
          user,
        } = this.props;

        return (
          <>
            <Table
              columns={this.state.columns}
              dataSource={this.state.dataSource}
              rowClassName={(_, idx) =>
                idx === this.state.dataSource.length - 1
                  ? "region-last-row"
                  : ""
              }
              pagination={false}
              bordered
              title={() => (
                <TitleHeader
                  city={city}
                  setToolTipOpen={this.props.setToolTipOpen}
                  toolTipOpen={this.props.toolTipOpen}
                  zones={this.getZones(city, zones)}
                  year={year}
                  months={months}
                  categories="Categories Selected"
                  nationalities={nationalities}
                  handleExcelDownload={() =>
                    download(
                      year,
                      city,
                      nationalities,
                      months,
                      category,
                      this.getZones(city, zones),
                      purchaseMode,
                      propertyName,
                      data,
                      user,
                      this.props.sallCatchments,
                      this.props.population
                    )
                  }
                />
              )}
            />
          </>
        );
      } else return null;
    } else return null;
  }
}

export default ZoneTable;
