import React from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { LockOpen } from "@material-ui/icons";

function NotSignedIn({ show, setShow, user }) {
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <LockOpen style={{ fontSize: "2rem", color: "var(--darkBlue)" }} />{" "}
            {user.username ? (
              <u>Not Subscribed To Chosen City.</u>
            ) : (
              <u>Please Log In</u>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user.username ? (
            <span style={{ fontSize: "1.25rem" }}>
              Please Susbcribe To View Maps Of The Chosen City.
            </span>
          ) : (
            <span style={{ fontSize: "1.25rem" }}>
              You need to log-in to access maps.
            </span>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!user.username && (
            <a href="https://intelligentinsights.me/login/">
              <button>Login</button>
            </a>
          )}
          <a href={"https://intelligentinsights.me/subscription/"}>
            <button>Subscribe</button>
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NotSignedIn;
