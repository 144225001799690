import React from "react";
import { Radio, Space } from "antd";
import { Modal } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { Close, Timeline, NavigateNext } from "@material-ui/icons";

import "../../css/modal.css";

export default function ViewOptionModal({
  handleClose,
  isModalOpen,
  displayMode,
  handleRadioClick,
  handlePost,
}) {
  const displayOptions = [
    {
      value: "distinct",

      title: "By Zone By Nationality, Monthly",
    },
    {
      value: "zones_monthly",

      title: "By Zone By Month, Nationality Added",
    },
    {
      value: "zone_nationality",

      title: "By Zone By Nationality, Annual",
    },
    {
      value: "nationality",

      title: "By Zone, Annual",
    },
    {
      value: "zones",

      title: "Aggregated Single Number, Annual",
    },
  ];
  console.log(isModalOpen);

  return (
    <Modal key={uuid()} open={isModalOpen}>
      <div className="view-option-modal">
        <div className="top-header">
          <div>
            <Timeline
              style={{
                marginRight: "10px",
                fontSize: "2rem",
                color: "royalblue",
                borderRadius: "50%",
                backgroundColor: "#bbf7fc",
              }}
            />
            <span>Select A Display Option</span>
          </div>
          <Close onClick={handleClose} className="modal-close-button" />
        </div>
        <div className="display-options">
          <Radio.Group
            value={displayMode}
            onChange={(e) => handleRadioClick(e.target.value)}
            style={{ width: "100%" }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              {displayOptions.map((option) => (
                <Radio
                  value={option.value}
                  className={
                    option.value === displayMode
                      ? "option-radio checked"
                      : "option-radio"
                  }
                >
                  {option.title}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
        <div className="submit-btn-div">
          <div onClick={handlePost}>
            View Market Size <NavigateNext />
          </div>
        </div>
      </div>
    </Modal>
  );
}
