import { Modal } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { Close, Timeline } from "@material-ui/icons";
import { Space } from "antd";

import "../../css/modal.css";

export default function MallOptionModal({
  handleClose,
  mallOptions,
  isModalOpen,
  handleMallClick,
}) {
  console.log("working MMMMMMMMMMMMM");
  return (
    <Modal key={uuid()} open={isModalOpen}>
      <div className="view-option-modal">
        <div className="top-header">
          <div>
            <Timeline
              style={{
                marginRight: "10px",
                fontSize: "2rem",
                color: "royalblue",
                borderRadius: "50%",
                backgroundColor: "#bbf7fc",
              }}
            />
            <span>Your Subscribed Malls</span>
          </div>
          <Close onClick={handleClose} className="modal-close-button" />
        </div>
        <div className="mall-options">
          <Space direction="vertical" style={{ width: "100%" }}>
            {mallOptions.map((mall) => (
              <div
                className="option-radio"
                onClick={() => handleMallClick(mall)}
              >
                {mall.name}
              </div>
            ))}
          </Space>
        </div>
      </div>
    </Modal>
  );
}
