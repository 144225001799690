import { useEffect, useState, useRef } from "react";
import { Paper } from "@material-ui/core";
import { Backup, Delete, Fingerprint, Search } from "@material-ui/icons";
import axios from "axios";
import { Checkbox, Empty } from "antd";
const levenshtein = require("fast-levenshtein");

const MAPS_UPLOAD_URL =
  "https://data.nerd.online:8000/catchments_info/malls_upload/";
const MAPS_UPDATE_URL =
  "https://data.merd.online:8000/catchments_info/malls_update/";

export default function BulkMapUpload() {
  const fileInputRef = useRef(null);
  const [filesAvailable, setFilesAvailable] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);

  useEffect(() => {
    axios.get(MAPS_UPDATE_URL).then((res) => {
      console.log(res.data.files);
      setFilesAvailable(res.data.files.map((file) => ({ file, weight: 0 })));
    });
  }, []);

  function handleFileUpload() {
    const files = fileInputRef.current.files;

    if (files.length === 0) return alert("No Files Selected");

    const form = new FormData();

    for (let i = 0; i < files.length; i++) {
      form.append("files", files[i]);
    }
    axios
      .post(MAPS_UPLOAD_URL, form)
      .then((res) => {
        setFilesAvailable(res.data.files.map((file) => ({ file, weight: 0 })));
        alert("Files Uploaded Successfully");
      })
      .catch((err) => console.error(err));
  }

  function handleSearchChange(e) {
    const searchTerm = e.target.value;
    setFilesAvailable(
      filesAvailable.map((file) => ({
        file: file.file,
        weight: file.file.toUpperCase().includes(searchTerm.toUpperCase())
          ? -10000
          : levenshtein.get(file.file, searchTerm),
      }))
    );
  }

  function handleFileSelect(item) {
    const newFilesSelected = [...filesSelected];
    const idx = filesSelected.findIndex(
      (fileSelected) => fileSelected === item
    );
    if (idx === -1) {
      newFilesSelected.push(item);
    } else {
      newFilesSelected.splice(idx, 1);
    }
    setFilesSelected(newFilesSelected);
  }

  function handleAllSelect(e) {
    const checked = !e.target.checked;
    checked
      ? setFilesSelected([])
      : setFilesSelected(filesAvailable.map((file) => file.file));
  }

  function deleteSelected(filesSelected) {
    if (
      window.confirm(
        `Are you sure, you want to delete ${filesSelected.length} number of files. This is not reversible.`
      )
    ) {
      axios
        .post(MAPS_UPDATE_URL, filesSelected)
        .then((res) => {
          setFilesAvailable(
            res.data.files.map((file) => ({ file, weight: 0 }))
          );
          setFilesSelected([]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  return (
    <Paper className="p-4 flex items-center justify-center m-2">
      <div className="flex flex-column items-start justify-center m-4 p-4 ">
        <div className="flex items-center justify-center  text-4xl font-bold">
          <div className="flex items-center justify-center m-2 p-4 shadow-md rounded-full bg-slate-200">
            <Backup style={{ fontSize: "36px" }} color="primary" />
          </div>
          <span>Bulk Catchments Maps Upload</span>
        </div>
        <div className="flex gap-6">
          <div className="my-6 text-slate-400 font-semibold flex flex-column items-start justify-between gap-3">
            Upload catchments map files here.
            <div className="flex items-center justify-center text-black font-normal">
              <div className="flex items-center justify-center m-1 p-1 bg-rose-100 rounded-full">
                <Fingerprint color="secondary" />
              </div>
              Upload the PDF files.
            </div>
            <input
              type="file"
              name="file-input"
              id="file-input"
              accept="application/pdf"
              multiple
              ref={fileInputRef}
            />
            <div className="w-100">
              <div
                className="rounded-lg shadow-md bg-green-500 text-white p-2 my-2 cursor-pointer text-center"
                onClick={handleFileUpload}
              >
                Upload
              </div>
            </div>
            <div className="italic font-normal">
              Note:
              <ol>
                <li>
                  1.Name of the actual pdf file must me in format{" "}
                  <b>mall name.pdf</b>
                </li>
                <li>
                  2.Name of the preview pdf file must me in format{" "}
                  <b>preview_mall_name.pdf</b>
                </li>
                <li>3.Similar names replace old files.</li>
              </ol>
            </div>
          </div>
          <div className="my-6 ">
            <span className="font-lg font-bold">Files Uploaded</span>
            <div className="flex flex-column items-start justify-between gap-4">
              <div className="search-bar rounded-lg flex items-center justify-center gap-2">
                <input
                  type="text"
                  name="search"
                  id="file-search"
                  key="file-search"
                  onChange={handleSearchChange}
                  disabled={filesAvailable.length === 0}
                />
                <div className="flex items-center justify-center p-2  bg-slate-200 rounded-full shadow-md">
                  <Search style={{ fontSize: "24" }} color="primary" />
                </div>
                {filesSelected.length > 0 ? (
                  <div
                    onClick={() => deleteSelected(filesSelected)}
                    className="rounded-lg flex items-center justify-center gap-2 text-center w-fit px-4 py-2 ml-4 font-lg font-bold text-white shadow-md bg-rose-500 cursor-pointer "
                  >
                    <Delete /> Delete
                  </div>
                ) : (
                  <div className="rounded-lg flex items-center justify-center gap-2 text-center w-fit px-4 py-2 ml-4 font-lg font-bold text-white shadow-sm bg-slate-500 ">
                    <Delete /> Delete
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between mr-2 w-100 ">
                {filesAvailable.length > 0 ? (
                  <Checkbox
                    checked={filesAvailable.length === filesSelected.length}
                    onClick={handleAllSelect}
                    className="font-xl font-semibold"
                  >
                    Select All
                  </Checkbox>
                ) : (
                  <Checkbox disabled className="font-xl font-semibold">
                    Select All
                  </Checkbox>
                )}
                <span className="text-sm font-bold p-2 rounded-md bg-sky-500 text-white">
                  Items Selected: {filesSelected.length}
                </span>
              </div>
              <div
                className="flex flex-column items-start justify-between gap-2 px-2"
                style={{ maxHeight: "60vh", overflow: "auto" }}
              >
                {filesAvailable.length > 0 ? (
                  filesAvailable
                    .sort((file1, file2) => file1.weight - file2.weight)
                    .map((file) => (
                      <div className="flex items-center justify-between gap-1 w-100">
                        <Checkbox
                          className="font-md font-semibold"
                          value={file.file}
                          checked={filesSelected.includes(file.file)}
                          onClick={() => handleFileSelect(file.file)}
                        >
                          {file.file}
                        </Checkbox>
                        <a
                          className="italic text-sky-300 underline"
                          target="_blank"
                          href={`https://data.merd.online:8000/maps/${file.file}`}
                        >
                          view
                        </a>
                      </div>
                    ))
                ) : (
                  <Empty description="No Files" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}
