import { Avatar } from "@material-ui/core";
import React, { Component } from "react";

const avatarColor = {
  ABCDEFGHIJK: "greenyellow",
  LMNOPQRST: "red",
  UVWXYZ: "yellow",
};

export default class UserList extends Component {
  render() {
    return (
      <div className="chats-list">
        <p style={{ margin: "10px" }}>Chats</p>
        <div className="d-flex flex-col">
          {this.props.users
            .filter((user) => user.user !== "MERD Admin")
            .map((user) =>
              this.props.userSelected ? (
                <div
                  className={
                    this.props.userSelected.user === user.user
                      ? "user mb-2 d-flex selected"
                      : "user mb-2 d-flex"
                  }
                  onClick={() => this.props.selectUser(user)}
                >
                  <Avatar className="user-avatar">
                    {user.user[0].toUpperCase()}
                  </Avatar>
                  <span className="d-flex flex-col">
                    <span>{user.user}</span>
                    {this.props.unreadUsers.findIndex(
                      (urUser) => urUser.user === user.user
                    ) !== -1 ? (
                      <span className="user-sub">Unread</span>
                    ) : (
                      <span className="user-sub read">Read</span>
                    )}
                  </span>
                </div>
              ) : (
                <div
                  className={"user mb-2 d-flex"}
                  onClick={() => this.props.selectUser(user)}
                >
                  <Avatar className="user-avatar">
                    {user.user[0].toUpperCase()}
                  </Avatar>
                  <span className="d-flex flex-col">
                    <span>{user.user}</span>
                    {this.props.unreadUsers.findIndex(
                      (urUser) => urUser.user === user.user
                    ) !== -1 ? (
                      <span className="user-sub">Unread</span>
                    ) : (
                      <span className="user-sub read">Read</span>
                    )}
                  </span>
                </div>
              )
            )}
        </div>
      </div>
    );
  }
}
