import { CheckCircle, GetApp, Info, MoreHoriz } from "@material-ui/icons";
import { Popover } from "antd";
import { isArray } from "lodash";

export default function TitleHeader({
  main,
  city,
  year,
  months,
  categories,
  nationalities,
  zones,
  handleExcelDownload,
  setToolTipOpen,
  toolTipOpen,
}) {
  let headerList = {
    City: city,
    Year: String(year),
    Months: isArray(months) ? months.join(", ") : months,
    Categories: isArray(categories) ? categories.join(", ") : categories,
    Nationalities: isArray(nationalities)
      ? nationalities.join(", ")
      : nationalities,
    "Purchase Mode": "Online and Offline",
  };

  if (zones)
    headerList["Zones"] = isArray(zones) ? zones.join(", ") : String(zones);

  return (
    <div className="header-list">
      <div className="info-tooltip">
        <h1 className="table-header-main">{main}</h1>
        <Popover
          color="#144380"
          content={
            <div style={{ color: "white" }}>
              <span>Please click once on the table so that</span>
              <br />
              <span>the headers remain visible when you scroll down.</span>
            </div>
          }
          title={
            <div className="d-flex align-items-center justify-between text-white">
              <span>Info For User</span>
              <div
                onClick={() => setToolTipOpen(false)}
                style={{ cursor: "pointer" }}
              >
                ❎
              </div>
            </div>
          }
          visible={toolTipOpen}
          placement="topRight"
        ></Popover>
        <Info
          onClick={() => setToolTipOpen(!toolTipOpen)}
          style={{
            fontSize: "2rem",
            color: "var(--darkBlue)",
            border: "2px solid var(--lightBlue)",
            borderRadius: "2rem",
          }}
          className="hover-lighter"
        />
      </div>
      <ul>
        {Object.keys(headerList).map((key) => (
          <li key={key}>
            <span className="title-key" key={key + "key"}>
              <CheckCircle
                style={{
                  fontWeight: "800",
                  color: "#4f5bff",
                  marginRight: "2px",
                }}
              />
              {key}:
            </span>
            {headerList[key].length < 30 ? (
              <span key={key + "val"}>{headerList[key]}</span>
            ) : (
              <Popover
                content={headerList[key]}
                title={key}
                style={{ width: "100px" }}
              >
                <span key={key + "val"} style={{ cursor: "pointer" }}>
                  {headerList[key].substring(0, 20)}
                  <MoreHoriz
                    style={{
                      color: "#4f5bff",
                      marginLeft: "2px",
                      marginBottom: 0,
                    }}
                  />
                </span>
              </Popover>
            )}
          </li>
        ))}
      </ul>
      <div className="d-flex align-items-center justify-between p-3">
        <b>
          <u>SOURCE: MIDDLE EAST RETAIL DATA</u>
        </b>
        <button onClick={handleExcelDownload}>
          Save Excel <GetApp />
        </button>
      </div>
    </div>
  );
}
