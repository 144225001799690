import React, { Component, createRef } from "react";
import { Avatar, Tooltip } from "@material-ui/core";
import { DoneAll, Delete } from "@material-ui/icons";
import date from "date-and-time";

export default class ChatBox extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.messages);
    this.chatBoxref = createRef();
  }
  scrollToBottom = () => {
    this.chatBoxref.current.scrollTo(0, this.chatBoxref.current.scrollHeight);
  };
  handleSend = (message) => {
    this.props.sendMessage(message);
    this.scrollToBottom();
  };

  messageDiv = (message) =>
    message.user === "MERD Admin" ? (
      <div className="message-outer admin-msg">
        <div className="msg">
          <span className="time">
            {date.format(new Date(message.created_at), "DD MMM, hh:mm")}
            {message.read ? (
              <span>
                <DoneAll className="check-icon" />
              </span>
            ) : (
              <span className="check-icon">
                <DoneAll className="check-icon read-color" />
              </span>
            )}
          </span>
          <span>{message.message}</span>
        </div>
      </div>
    ) : (
      <div className="message-outer">
        <div className="msg">
          <span className="time admin-time">
            {date.format(new Date(message.created_at), "DD MMM, hh:mm")}
            {message.read ? (
              <span>
                <DoneAll className="check-icon" />
              </span>
            ) : (
              <span className="check-icon">
                <DoneAll className="check-icon read-color" />
              </span>
            )}
          </span>
          <span>{message.message}</span>
        </div>
      </div>
    );
  handleKeyDown = (e, adminMessage) => {
    if (e.key === "Enter") {
      this.handleSend(adminMessage);
      this.setAdminMessage("");
    }
  };
  render() {
    const { user, messages, adminMessage, setAdminMessage, deleteChats } =
      this.props;
    return (
      <div className="main-messages-outer">
        <div className={"user d-flex chat-top"}>
          <span className="d-flex user-tab">
            <Avatar className="user-avatar top-avatar">
              {user.user[0].toUpperCase()}
            </Avatar>
            <span>
              <span>{user.user}</span>
            </span>
          </span>
          <Tooltip title={`Delete Chats of ${user.user}`}>
            <span
              className="d-flex flex-col justify-content-center align-items-center text-danger"
              onClick={() => deleteChats(user.user)}
            >
              <Delete className="text-danger" />
              <span style={{ fontSize: "12px" }}>Delete Chat</span>
            </span>
          </Tooltip>
        </div>
        <div className="messages-box d-flex flex-col" ref={this.chatBoxref}>
          {messages.map((message) => this.messageDiv(message))}
        </div>
        <div className="msg-input-outer">
          <input
            type="text"
            className="message-input"
            value={adminMessage}
            onChange={(e) => setAdminMessage(e.target.value)}
            onKeyDown={(e) => this.handleKeyDown(e, adminMessage)}
            placeholder="Write your message here"
          />
          <button onClick={() => this.handleSend(adminMessage)}>Send</button>
        </div>
      </div>
    );
  }
}
