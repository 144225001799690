import React, { Component } from "react";
import { Radio, Collapse, Space } from "antd";
import { v4 as uuid } from "uuid";

const { Panel } = Collapse;

export default class CityFilter extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="mr-5 selection-filters">
        <h5 className=" mb-2" style={{ fontWeight: "500" }}>
          We understate you like to conduct a catchments analysis. Please choose
          a city.
        </h5>
        <Collapse defaultActiveKey={["City-0"]}>
          {this.props.cityOptions.map((country, idx) => (
            <Panel
              header={
                <span style={{ fontWeight: "600", fontSize: "1.5rem" }}>
                  {country.country}
                </span>
              }
              key={`City-${idx}`}
            >
              <Space size={[8, 16]} wrap>
                {country.cities.map((city) => (
                  <Radio
                    key={uuid()}
                    value={city}
                    checked={this.props.citySelected === city}
                    onClick={() => this.props.handleCitySelect(city)}
                  >
                    <span style={{ fontSize: "1.25rem", fontWeight: "400" }}>
                      {city}
                    </span>
                  </Radio>
                ))}
              </Space>
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  }
}
