import React from "react";
import { Menu, Checkbox } from "antd";
const nowDate = new Date();
const currentYear = nowDate.getFullYear();
const currentMonth = nowDate.getMonth() + 1;

const SubSubMonths = ({
  allselected,
  selectquarter,
  selectmonth,
  years,
  monthsSelected,
  quarter,
  ...rest
}) => {
  function futureMonth(years, month) {
    if (!years) return false;
    if (years.length !== 1) return false;
    if (years[0] !== currentYear) return false;
    if (month < currentMonth) return false;
    return true;
  }
  return (
    <Menu.SubMenu
      key={quarter.quarter}
      title={`${quarter.quarter} Quarter`}
      {...rest}
    >
      <Menu.Item>
        <Checkbox onChange={(e) => selectquarter(quarter.quarter, years, e)}>
          Select All
        </Checkbox>
      </Menu.Item>
      {quarter.months.map((month) =>
        allselected[quarter.quarter] ? (
          <Menu.Item key={month.month}>
            <Checkbox
              checked={false}
              disabled={
                allselected[quarter.quarter] || futureMonth(years, month.id)
              }
            >
              {month.month}
            </Checkbox>
          </Menu.Item>
        ) : (
          <Menu.Item key={month.month}>
            <Checkbox
              onClick={(e) => selectmonth(month.id, e)}
              checked={monthsSelected.includes(month.id)}
              disabled={futureMonth(years, month.id)}
            >
              {month.month}
            </Checkbox>
          </Menu.Item>
        )
      )}
    </Menu.SubMenu>
  );
};

export default SubSubMonths;
