import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { Divider, Tag } from "antd";
import { AssignmentTurnedIn, CheckCircle } from "@material-ui/icons";
import axios from "axios";
import NotSignedIn from "../Modals/NotSignedIn";

import CityFilter from "./CityFilter";
import MallFilter from "./MallFilter";
import ZoneFilter from "./ZoneFilter";
import MallOptionModal from "./MallOptionModal";
import MessageModal from "./MessageModal";

import "../../css/CatchmentsDashboard.css";
import { Paper } from "@material-ui/core";
import UserContext from "../UserContext";

const MALLS_URL = "https://data.merd.online:8000/catchments_info/";
// const MALLS_URL = "http://localhost:8000/catchments_info/";
const CANCEL_URL =
  "https://intelligentinsights.me/subscription-process-cancel/";

export default class Catchments extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      cityOptions: [],
      citySelected: null,
      mallSelected: null,
      zonesSelected: [],
      zonesOptions: [],
      showNotSignedIn: false,
      showMallOptions: false,
      subscribedMalls: [],
      modalOpen: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.citySelected !== this.state.citySelected &&
      this.state.mallSelected
    ) {
      console.log("City Changed");
      this.setState({ mallSelected: null });
    }

    if (
      this.context.userData.username &&
      window.location.href.includes("from-homepage")
    ) {
      window.history.pushState({}, document.title, window.location.pathname);
      const mallsSubcribed = [];

      const catchmentsSubscribed = this.context.userData.catchments;
      console.log(this.context.userData);

      for (let mall of catchmentsSubscribed) {
        if (
          mallsSubcribed.findIndex((mallSub) => mallSub.name === mall.name) ===
          -1
        ) {
          mallsSubcribed.push({
            name: mall.name,
            zones: catchmentsSubscribed
              .filter((cat) => cat.name === mall.name)
              .map((cat) => cat.zone_name),
            cities: [
              ...new Set(
                catchmentsSubscribed
                  .filter((cat) => cat.name === mall.name)
                  .map((cat) => cat.city_name)
              ),
            ],
          });
        }
      }
      console.log("gggggggggggggggggg", mallsSubcribed);

      if (mallsSubcribed.length > 0) {
        this.setState({
          showMallOptions: true,
          subscribedMalls: mallsSubcribed,
        });
      }
    }
  }

  componentDidMount() {
    console.log(this.context.userData);
    axios.get(MALLS_URL).then((res) => {
      this.setState({ options: res.data.data });
      let countries = [];
      res.data.data.forEach((mall) => {
        const idx = countries.findIndex(
          (country) => country.country === mall.country
        );
        if (idx === -1) {
          countries.push({ country: mall.country, cities: [mall.city_name] });
        } else {
          !countries[idx].cities.includes(mall.city_name) &&
            countries[idx].cities.push(mall.city_name);
        }
      });
      this.setState({ cityOptions: countries });
    });
  }

  handleCitySelect = (city) => {
    console.log(this.context.userData);
    this.setState({ citySelected: city });
    console.log(this.state.options, city);
    let malls = this.state.options.filter(
      (option) => option.city_name === city
    );
    malls = malls.map((mall) => mall.name);
    malls = [...new Set(malls)];
    this.setState({ mallOptions: malls });
  };

  handleMallSelect = (mallName) => {
    console.log(this.state.options);
    this.setState({ mallSelected: mallName });
    let zones = this.state.options.filter((option) => option.name === mallName);
    zones = zones.map((mall) => ({
      name: mall.zone_name,
      type: mall.zone_type,
    }));
    this.setState({
      zonesOptions: zones,
      zonesSelected: zones,
    });
  };

  handleZoneSelect = (e) => {
    const zonesSelectedCopy = [...this.state.zonesSelected];
    const idx = zonesSelectedCopy.findIndex(
      (zone) => zone.name === e.target.value
    );
    if (idx !== -1) {
      zonesSelectedCopy.splice(idx, 1);
    } else {
      zonesSelectedCopy.push({ name: e.target.value });
    }
    this.setState({ zonesSelected: zonesSelectedCopy });
    console.log(zonesSelectedCopy);
  };

  handleDemoInfo = () => {
    if (this.state.zonesSelected.length === 0) {
      this.showInvalid(true);
      return false;
    }
    window.location.href = `/dashboard?data=${JSON.stringify({
      selectedCity: this.state.citySelected,
      selectedZones: this.state.zonesSelected.map((zone) => zone.name),
      selectedMall: this.state.mallSelected,
    })}`;
  };

  handleMallClick = (mall) => {
    window.location.href = `/dashboard?data=${JSON.stringify({
      selectedCity: mall.cities[0],
      selectedZones: mall.zones,
      selectedMall: mall.name,
    })}`;
  };

  showInvalid = (open) => {
    this.setState({ modalOpen: open });
  };

  canDownload = (citySelected) => {
    return this.context.userData.cities.includes(citySelected);
  };
  download = (mallName) => {
    window.open(`${MALLS_URL}malls/?mall_map=${mallName}`, "_blank");
  };

  submitForm = (data, mallSelected) => {
    const user = this.context.userData;

    const createFormInput = (name, value, form) => {
      let input = document.createElement("input");
      input.name = name;
      input.value = value;
      form.appendChild(input);
    };

    let formTarget = user.username ? "hidden-frame" : "_blank";

    let form = document.createElement("form");
    form.style.visibility = "hidden"; // no user interaction is necessary
    form.method = "POST"; // forms by default use GET query strings
    form.target = formTarget;
    form.action = CANCEL_URL;

    if (user.username) {
      createFormInput("username", user.username, form);
    }
    createFormInput(
      "type",
      `Cancel from catchments for the mall: ${mallSelected}`,
      form
    );
    for (let attribute in data) {
      createFormInput(attribute, data[attribute].join(), form);
    }
    document.body.appendChild(form);
    form.submit();
  };

  postForm = (citySelected, mallSelected) => {
    let dataToBeEmailed = {
      cities: [citySelected, "invalid map access in catchments"],
      categories: [mallSelected, "user not logged in & trying to download map"],
      years: ["N/A", "from catchments"],
      months: ["N/A", "from catchments"],
      nationalities: ["N/A", "from catchments"],
      placeOfPurchase: ["N/A"],
      purchaseMode: ["N/A"],
    };
    this.submitForm(dataToBeEmailed, mallSelected);
  };

  render() {
    return (
      <>
        {/* Modals */}
        <MallOptionModal
          isModalOpen={this.state.showMallOptions}
          handleClose={() => this.setState({ showMallOptions: false })}
          mallOptions={this.state.subscribedMalls}
          handleMallClick={this.handleMallClick}
        />
        <MessageModal
          isModalOpen={this.state.modalOpen}
          handleClose={() => this.showInvalid(false)}
          title={"Incomplete Selections"}
          message={"Please select before proceeding."}
        />
        <NotSignedIn
          show={this.state.showNotSignedIn}
          setShow={() =>
            this.setState({ showNotSignedIn: !this.state.showNotSignedIn })
          }
          user={this.context.userData}
          postForm={() => this.postForm(this.state.citySelected)}
        />
        <div>
          <div className="top-banner">
            <div className="top-img"></div>
            <div className="top-header uppercase text-5xl">
              Catchments Analysis
            </div>
          </div>
          <div className="steps w-full">
            <Link className="step step-1 active" to="/catchments" replace>
              <div className="img active">
                <div className="img1 invert"></div>
              </div>
            </Link>
            <Link
              className="step step-2"
              to={this.state.citySelected ? "/catchments/mall" : "#"}
              replace
            >
              {window.location.pathname.includes("mall") ||
              window.location.pathname.includes("zone") ? (
                <div className="step step-2 active">
                  <div className="img active">
                    <div className="img2 invert"></div>
                  </div>
                </div>
              ) : (
                <div className="step step-2">
                  <div className="img">
                    <div className="img2"></div>
                  </div>
                </div>
              )}
            </Link>
            <Link
              to={this.state.mallSelected ? "/catchments/zone" : "#"}
              replace
            >
              {window.location.pathname.includes("zone") ? (
                <div className="step step-3 active">
                  <div className="img active">
                    <div className="img3 invert"></div>
                  </div>
                </div>
              ) : (
                <div className="step step-3">
                  <div className="img">
                    <div className="img3"></div>
                  </div>
                </div>
              )}
            </Link>
          </div>
          <div className="w-full d-flex p-5 main-outer-catchments">
            <Switch>
              <Route exact path="/catchments">
                <CityFilter
                  mallOptions={this.state.mallOptions}
                  citySelected={this.state.citySelected}
                  mallSelected={this.state.mallSelected}
                  cityOptions={this.state.cityOptions}
                  handleCitySelect={this.handleCitySelect}
                />
              </Route>
              <Route exact path="/catchments/mall">
                <MallFilter
                  mallOptions={this.state.mallOptions}
                  citySelected={this.state.citySelected}
                  zonesSelected={this.state.zonesSelected}
                  mallSelected={this.state.mallSelected}
                  handleMallSelect={this.handleMallSelect}
                />
              </Route>
              <Route exact path="/catchments/zone">
                <ZoneFilter
                  mallOptions={this.state.mallOptions}
                  citySelected={this.state.citySelected}
                  mallSelected={this.state.mallSelected}
                  zonesSelected={this.state.zonesSelected}
                  zoneOptions={this.state.zonesOptions}
                  handleZoneSelect={this.handleZoneSelect}
                />
              </Route>
            </Switch>

            <Paper className="selections-paper">
              <Divider orientation="left">
                <span style={{ fontWeight: "600", fontSize: "2rem" }}>
                  <AssignmentTurnedIn
                    style={{
                      fontSize: "2.1rem",
                      paddingBottom: "2px",
                      color: "var(--darkBlue)",
                    }}
                  />{" "}
                  Selections
                </span>
              </Divider>
              <h4>
                <CheckCircle
                  style={{
                    fontSize: "2rem",
                    paddingBottom: "2px",
                    color: "var(--lightBlue)",
                  }}
                />{" "}
                City:
                {"   "}
                {this.state.citySelected ? (
                  <Tag color="green">
                    <h4>
                      {
                        this.state.options.find(
                          (mall) => mall.city_name === this.state.citySelected
                        ).country
                      }
                      {">"}
                      {this.state.citySelected}
                    </h4>
                  </Tag>
                ) : (
                  <span style={{ fontSize: "1", opacity: "0.5" }}>
                    Select City
                  </span>
                )}
              </h4>
              <Divider />
              <h4>
                <CheckCircle
                  style={{
                    fontSize: "2rem",
                    paddingBottom: "2px",
                    color: "var(--lightBlue)",
                  }}
                />{" "}
                Mall:
                {"   "}
                {this.state.mallSelected ? (
                  <Tag color="volcano">
                    <h4>{this.state.mallSelected}</h4>
                  </Tag>
                ) : (
                  <span style={{ fontSize: "1", opacity: "0.5" }}>
                    Select Mall
                  </span>
                )}
              </h4>
              <Divider />
            </Paper>
          </div>
        </div>
        <div className="w-100 p-5 d-flex justify-content-between align-items-center">
          {window.location.pathname.includes("mall") ? (
            <>
              <Link to="/catchments" replace>
                <button style={{ minWidth: "200px" }}>Previous</button>
              </Link>
              {this.state.mallSelected ? (
                <Link to="/catchments/zone" replace>
                  <button style={{ minWidth: "200px" }}>Next</button>
                </Link>
              ) : (
                <button
                  style={{ minWidth: "200px" }}
                  onClick={() => this.showInvalid(true)}
                >
                  Next
                </button>
              )}
            </>
          ) : window.location.pathname.includes("zone") ? (
            <>
              <Link to="/catchments/mall" replace>
                <button style={{ minWidth: "200px" }}>Previous</button>
              </Link>

              <button
                onClick={this.handleDemoInfo}
                style={{ minWidth: "200px" }}
              >
                View Market Size ➔
              </button>
            </>
          ) : (
            <>
              <div />
              {this.state.citySelected ? (
                <Link to="/catchments/mall" replace>
                  <button style={{ minWidth: "200px" }}>Next</button>
                </Link>
              ) : (
                <button
                  style={{ minWidth: "200px" }}
                  onClick={() => this.showInvalid(true)}
                >
                  Next
                </button>
              )}
            </>
          )}
        </div>

        <iframe
          name="hidden-frame"
          title="for-post"
          style={{
            visibility: "hidden",
            height: 0,
            padding: 0,
            margin: 0,
            width: 0,
            border: "none",
          }}
        ></iframe>
      </>
    );
  }
}
