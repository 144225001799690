const submitForm = (data, formTarget, url) => {
  const createFormInput = (name, value, form) => {
    let input = document.createElement("input");
    input.name = name;
    input.value = value;
    form.appendChild(input);
  };

  let form = document.createElement("form");
  form.style.visibility = "hidden"; // no user interaction is necessary
  form.method = "POST"; // forms by default use GET query strings
  form.target = formTarget;
  form.action = url;

  for (let attribute in data) {
    createFormInput(attribute, data[attribute], form);
  }
  document.body.appendChild(form);
  form.submit();
};

export default submitForm;
