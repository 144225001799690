function getUnique(array1) {
  const initialValue = [];
  const uniqueValues = array1.reduce(
    (accumulator, currentValue) =>
      accumulator.includes(currentValue)
        ? accumulator
        : [...accumulator, currentValue],
    initialValue
  );
  return uniqueValues;
}
export default getUnique;
