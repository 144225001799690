import React, { Component, createRef } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import getUserDetail from "../../utils/getUserDetail";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";

import { withStyles } from "@material-ui/core/styles";
import axios from "axios";

const OLD_CHATS = process.env.REACT_APP_OLD_CHATS;
// const OLD_CHATS = "http://localhost:8000/chat/";
// const CHAT_CLIENT = "ws://localhost:8000/chat/ws/chat/";
// const CHAT_CLIENT = process.env.REACT_APP_CHAT_CLIENT;
const CHAT_CLIENT = "wss://data.merd.online:8000/ws/chat/";

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  adminAvatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  chatterAvatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    boxShadow: "none",
  },
});

class Chat extends Component {
  constructor(props) {
    super(props);
    this.chatCardRef = createRef();
  }

  user = {
    username: "sourab",
  };
  state = {
    isLoggedIn: false,
    messages: [],
    value: "",
    name: "",
    room: "vacad",
  };

  client = new W3CWebSocket(CHAT_CLIENT + this.state.room + "/");

  onButtonClicked = (e) => {
    e.preventDefault();
    this.client.send(
      JSON.stringify({
        type: "message",
        message: this.state.value,
        user: this.state.name,
      })
    );
    console.log("click");
    this.setState({ value: "" });
  };

  componentDidMount() {
    if (this.user.username) {
      this.setState({
        name: this.user.username,
        room: this.user.username,
      });
      let newRoom = this.user.username.split("@")[0];
      this.setLoggedIn(newRoom);
    }
    this.client.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    this.client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      console.log("got reply! ", dataFromServer.type);
      if (dataFromServer) {
        this.setState((state) => ({
          messages: [
            ...state.messages,
            {
              msg: dataFromServer.message,
              user: dataFromServer.user,
            },
          ],
        }));
      }
      console.log(this.state.messages);

      console.log(this.chatCardRef.current.scrollHeight);
      this.chatCardRef.current.scrollTo(
        0,
        this.chatCardRef.current.scrollHeight
      );
    };
  }

  changeRoom = (room) => {
    this.setState({ room });
    this.client = new W3CWebSocket(CHAT_CLIENT + room + "/");
    this.client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      console.log("got reply! ", dataFromServer.type);
      if (dataFromServer) {
        this.setState((state) => ({
          messages: [
            ...state.messages,
            {
              msg: dataFromServer.message,
              user: dataFromServer.user,
            },
          ],
        }));
      }
      console.log(this.state.messages);

      console.log(this.chatCardRef.current.scrollHeight);
      this.chatCardRef.current.scrollTo(
        0,
        this.chatCardRef.current.scrollHeight
      );
    };
    axios.get(`${OLD_CHATS}${room}/`).then((res) => {
      const newMessages = res.data.messages.map((message) => ({
        msg: message.message,
        user: message.user,
      }));
      this.setState({ messages: newMessages });
      this.chatCardRef.current.scrollTo(
        0,
        this.chatCardRef.current.scrollHeight
      );
    });
  };

  setLoggedIn = (room) => {
    this.changeRoom(room);
    this.setState({ isLoggedIn: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        {this.state.isLoggedIn ? (
          <div style={{ marginTop: 50 }}>
            Room Name: {this.state.room}
            <Paper
              style={{
                height: "60vh",
                maxHeight: "60vh",
                overflow: "auto",
              }}
              ref={this.chatCardRef}
            >
              {this.state.messages.map((message) => (
                <>
                  <Card className={classes.root}>
                    <CardHeader
                      avatar={
                        message.user !== this.state.name ? (
                          <Avatar className={classes.adminAvatar}>
                            {message.user[0].toUpperCase()}
                          </Avatar>
                        ) : (
                          <Avatar className={classes.chatterAvatar}>
                            {message.user[0].toUpperCase()}
                          </Avatar>
                        )
                      }
                      title={message.user}
                      subheader={message.msg}
                    />
                  </Card>
                </>
              ))}
            </Paper>
            <TextField
              id="outlined-helperText"
              defaultValue="Default Value"
              value={this.state.value}
              fullWidth
              onChange={(e) => {
                this.setState({ value: e.target.value });
                this.value = this.state.value;
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.onButtonClicked}
            >
              Start Chatting
            </Button>
          </div>
        ) : (
          <div>
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                ChattyRooms
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Chatroom Name"
                name="Chatroom Name"
                autoFocus
                value={this.state.room}
                onChange={(e) => {
                  this.setState({ room: e.target.value });
                  this.value = this.state.room;
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="Username"
                label="Username"
                type="Username"
                id="Username"
                value={this.state.name}
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                  this.value = this.state.name;
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.setLoggedIn(this.state.room)}
              >
                Start Chatting
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </Container>
    );
  }
}
export default withStyles(useStyles)(Chat);
