import React from "react";
import { Menu, Checkbox } from "antd";
import { useState, useEffect } from "react";
import SubSubMonths from "./SubSubMonths";
const months = [
  {
    quarter: "First",
    months: [
      { id: 1, month: "January" },
      { id: 2, month: "February" },
      { id: 3, month: "March" },
    ],
  },
  {
    quarter: "Second",
    months: [
      { id: 4, month: "April" },
      { id: 5, month: "May" },
      { id: 6, month: "June" },
    ],
  },
  {
    quarter: "Third",
    months: [
      { id: 7, month: "July" },
      { id: 8, month: "August" },
      { id: 9, month: "September" },
    ],
  },
  {
    quarter: "Fourth",
    months: [
      { id: 10, month: "October" },
      { id: 11, month: "November" },
      { id: 12, month: "December" },
    ],
  },
];
let selectedMonths = [];
const nowDate = new Date();
const currentYear = nowDate.getFullYear();
const currentMonth = nowDate.getMonth() + 1;

const SubMonths = ({ addmonths, monthsSelected, years, ...rest }) => {
  useEffect(() => {
    if (monthsSelected.length === 12) {
      setAllQuartersSelected(true);
    }
  }, [monthsSelected, years]);

  const [allSelected, setAllSelected] = useState({
    First: false,
    Second: false,
    Third: false,
    Fourth: false,
  });
  const [allQuartersSelected, setAllQuartersSelected] = useState(false);

  const selectMonth = (id, e) => {
    if (e.target.checked) {
      selectedMonths.push(id);
    } else {
      selectedMonths = selectedMonths.filter((item) => item !== id);
    }
    addmonths(selectedMonths);
  };

  const selectQuarter = (quarter, years, e) => {
    if (e.target.checked) {
      const tempAllSelected = { ...allSelected };
      tempAllSelected[quarter] = true;
      setAllSelected(tempAllSelected);
      const isCurrentYear = years
        ? years.length == 1 && years[0] === currentYear
        : false;

      const rqedQuarter = months.find((q) => q.quarter === quarter);
      if (isCurrentYear) {
        selectedMonths = rqedQuarter.months
          .map((mon) => mon.id)
          .filter((mon) => mon < currentMonth);
      } else {
        selectedMonths = rqedQuarter.months.map((mon) => mon.id);
      }
    } else {
      const tempAllSelected = { ...allSelected };
      tempAllSelected[quarter] = false;
      setAllSelected(tempAllSelected);

      months.forEach((obj) => {
        if (obj.quarter === quarter) {
          obj.months.forEach((month) => {
            if (selectedMonths.indexOf(month.id) !== -1) {
              selectedMonths = selectedMonths.filter(
                (item) => item !== month.id
              );
            }
          });
        }
      });
    }
    addmonths(selectedMonths);
  };

  const selectAllQuarters = (e, years) => {
    if (e.target.checked) {
      setAllQuartersSelected(true);
      if (years) {
        if (years.length === 1 && years[0] === currentYear) {
          selectedMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          selectedMonths = selectedMonths.filter((mon) => mon < currentMonth);
        } else {
          selectedMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        }
      } else {
        selectedMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }
      setAllSelected({
        First: false,
        Second: false,
        Third: false,
        Fourth: false,
      });
    } else {
      setAllQuartersSelected(false);
      selectedMonths = [];
    }

    addmonths(selectedMonths);
  };
  function containAllFutureMonths(quarter, years) {
    if (!years) return false;
    if (years.length !== 1) return false;
    const selectedYear = years[0];
    if (selectedYear !== currentYear) return false;
    for (let qMonth of quarter.months) {
      if (qMonth.id < currentMonth) return false;
    }
    return true;
  }

  return (
    <Menu.SubMenu
      key="Months"
      title={
        <span style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>Months</span>
      }
      {...rest}
    >
      {months.some((quarter) => !containAllFutureMonths(quarter, years)) && (
        <Menu.Item>
          <Checkbox
            onClick={(e) => selectAllQuarters(e, years)}
            checked={allQuartersSelected}
          >
            Select All
          </Checkbox>
        </Menu.Item>
      )}
      {months.map(
        (quarter) =>
          !allQuartersSelected && (
            <SubSubMonths
              key={quarter.quarter}
              monthsSelected={monthsSelected}
              quarter={quarter}
              selectquarter={selectQuarter}
              selectmonth={selectMonth}
              allselected={allSelected}
              years={years}
              disabled={containAllFutureMonths(quarter, years)}
            />
          )
        // <Menu.SubMenu key={quarter.quarter} title={`${quarter.quarter} Quarter`}>
        //     <Menu.Item><Checkbox onChange={e => selectQuarter(quarter.quarter, e)}>Select All</Checkbox></Menu.Item>
        //     {quarter.months.map(month =>
        //         allSelected[quarter.quarter] ?
        //             <Menu.Item key={month.month}><Checkbox checked={false} disabled={allSelected[quarter.quarter]}>{month.month}</Checkbox></Menu.Item>
        //             : <Menu.Item key={month.month}><Checkbox onChange={e => selectMonth(month.id, e)}>{month.month}</Checkbox></Menu.Item>
        //     )}
        // </Menu.SubMenu>
      )}
    </Menu.SubMenu>
  );
};

export default SubMonths;
