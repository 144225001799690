import React, { Component } from "react";
import { Collapse, Radio, Space } from "antd";
import { v4 as uuid } from "uuid";

const { Panel } = Collapse;

export default class MallFilter extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (this.props.citySelected === null) window.location.href = "/catchments";
  }
  render() {
    if (this.props.citySelected === null) {
      window.location.href = "/catchments";
      return null;
    }
    return (
      <div className="mr-5 selection-filters">
        <h5 className=" mb-2">
          Please select a mall from the drop down below.
        </h5>
        <Collapse defaultActiveKey={["1"]}>
          <Panel
            header={
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                }}
              >
                {this.props.citySelected}
              </span>
            }
            key={"1"}
          >
            <Space direction="vertical" className="collapse-new w-100">
              {this.props.mallOptions.map((mall) => (
                <Radio
                  key={uuid()}
                  value={mall}
                  checked={this.props.mallSelected === mall}
                  onClick={() => this.props.handleMallSelect(mall)}
                >
                  <span style={{ fontSize: "1.25rem", fontWeight: "600" }}>
                    {mall}
                  </span>
                </Radio>
              ))}
            </Space>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
