import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Clear } from "@material-ui/icons";

export default function SubscriptionAlert({
  registered,
  handleSubscriptionAlert,
  showOneTimeSubPopUp,
  handleCancelPopUp,
  postObject,
  onlyDemographic,
  takeToSub,
  handleClose,
  alertMessage,
}) {
  const [show, setShow] = useState(true);
  function handleOneTimeSubscribe() {
    handleSubscriptionAlert();
    showOneTimeSubPopUp();
  }
  function handleCancel(state) {
    handleSubscriptionAlert();
    handleCancelPopUp(state);
  }
  //href="https://intelligentinsights.me/login/"
  function handleLogin() {
    localStorage.setItem(
      "selectionsMade",
      JSON.stringify({ ...postObject, type: "market-size" })
    );
    //in end the window is redirected to login page (php)
    window.location.href = "https://intelligentinsights.me/login/";
  }

  let message = onlyDemographic ? (
    "We see you are only subscribed to only demographic. You can subscribe to all products to view more data. "
  ) : (
    <>
      <b>
        If you are already registered and cannot access the data, your payment
        is probably in process. Please contact us{" "}
        <i>
          <a href="https://intelligentinsights.me/contact-us/">here</a>
        </i>{" "}
        for more information. Or
        <br />
        if you have chosen a different city / category from what you have
        subscribed to.You have the following options.
        <br />
        Know your subscriptions{" "}
        <i>
          <a href="https://intelligentinsights.me/account/">here</a>
        </i>{" "}
        .
      </b>
    </>
  );

  let title = onlyDemographic ? (
    "Not subscribed to All Products"
  ) : (
    <>
      Payment in process. <br />
      OR
      <br /> Not Subscribed to selected Categories.
    </>
  );
  if (alertMessage) {
    message = alertMessage.message;
    title = alertMessage.title;
  }

  function handleSubscribe() {
    localStorage.setItem(
      "selectionsMade",
      JSON.stringify({ ...postObject, type: "market-size" })
    );
    if (alertMessage) {
      takeToSub();
      setShow(false);
    } else {
      window.location.href = "https://intelligentinsights.me/subscription/";
    }
  }

  return (
    <>
      {registered ? (
        <div>
          {" "}
          <Dialog aria-labelledby="simple-dialog-title" open={show}>
            <DialogTitle>
              <div className="d-flex align-items-center justify-between">
                <span>{title}</span>
                <span
                  style={{ alignSelf: "center", cursor: "pointer" }}
                  onClick={handleClose}
                >
                  <Clear />
                </span>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions style={{ alignSelf: "center", cursor: "pointer" }}>
              <span className="cta" onClick={handleSubscribe}>
                Subscribe
              </span>
              <span className="cta" onClick={handleOneTimeSubscribe}>
                Try one time
              </span>
              <span onClick={() => handleCancel(true)} className="cta">
                Cancel
              </span>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>
          {" "}
          <Dialog aria-labelledby="simple-dialog-title" open={true}>
            <div className="dashboard-alert">
              <DialogTitle>
                <h2 className="registeration-required-title">
                  Registeration Required
                </h2>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <h4 className="registeration-required-content" align="center">
                    You need to subscribe to view data.
                  </h4>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ alignSelf: "center", cursor: "pointer" }}>
                <div className="registration-required-buttons">
                  <span
                    className="registration-required-btn-subscribe"
                    onClick={handleSubscribe}
                  >
                    <span className="cta">Subscribe</span>
                  </span>
                  <a
                    className="registration-required-btn-subscribe"
                    onClick={handleLogin}
                  >
                    <span className="cta">Login</span>
                  </a>
                  <span className="cta" onClick={() => handleCancel(false)}>
                    Cancel
                  </span>
                </div>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
