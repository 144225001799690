import "../Dashboard/alert.css";
import { Modal } from "@material-ui/core";
import { Close, Info } from "@material-ui/icons";
import { v4 as uuid } from "uuid";

export default function Alert({
  title,
  contentText,
  btnText,
  ModalHandlerClose,
}) {
  return (
    <Modal key={uuid()} open={true}>
      <div className="view-option-modal">
        <div className="top-header">
          <div>
            <Info
              style={{
                marginRight: "10px",
                fontSize: "2rem",
                color: "royalblue",
                borderRadius: "50%",
                backgroundColor: "#bbf7fc",
              }}
            />
            <span>{title}</span>
          </div>
          <Close onClick={ModalHandlerClose} className="modal-close-button" />
        </div>
        <div className="display-options font-bold">{contentText}</div>
        <div className="submit-btn-div">
          <div onClick={ModalHandlerClose} className="font-semibold">
            {btnText}
          </div>
        </div>
      </div>
    </Modal>
  );
}
