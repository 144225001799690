import React, { Component } from "react";
import { Checkbox } from "antd";
import { Paper } from "@material-ui/core";

export default class DisplayModePage extends Component {
  constructor(props) {
    super(props);
  }

  handleModeCheck = (e) => {
    const modes = this.props.modes;

    if (e.target.checked) {
      modes.push(e.target.value);
    } else {
      const idx = modes.findIndex((m) => m === e.target.value);
      if (idx !== -1) modes.splice(idx, 1);
    }

    console.log(modes);

    this.props.handleDisplayModeCheck(modes);
  };

  render() {
    return (
      <Paper
        className="flex flex-col align-items-start justify-content-start p-2 filter-paper"
        elevation={4}
      >
        <div className="filter-title ">How do you want to view the data?</div>
        <div className="check-box-group">
          {this.props.modes.includes("zone") ? (
            <Checkbox
              value="zone"
              checked={true}
              onClick={(e) => this.handleModeCheck(e)}
              className="type-check"
            >
              <span className="check-text">By Zone</span>
            </Checkbox>
          ) : (
            <Checkbox
              value="zone"
              checked={false}
              onClick={(e) => this.handleModeCheck(e)}
              className="type-check"
            >
              <span className="check-text">By Zone</span>
            </Checkbox>
          )}
          {this.props.modes.includes("nat") ? (
            <Checkbox
              value="nat"
              checked={true}
              onClick={(e) => this.handleModeCheck(e)}
              className="type-check"
            >
              <span className="check-text">By Nationality</span>
            </Checkbox>
          ) : (
            <Checkbox
              value="nat"
              checked={false}
              onClick={(e) => this.handleModeCheck(e)}
              className="type-check"
            >
              <span className="check-text">By Nationality</span>
            </Checkbox>
          )}
          {this.props.modes.includes("zonenat") ? (
            <Checkbox
              value="zonenat"
              checked={true}
              onClick={(e) => this.handleModeCheck(e)}
              className="type-check"
            >
              <span className="check-text">By Nationality By Zone</span>
            </Checkbox>
          ) : (
            <Checkbox
              value="zonenat"
              checked={false}
              onClick={(e) => this.handleModeCheck(e)}
              className="type-check"
            >
              <span className="check-text">By Nationality By Zone</span>
            </Checkbox>
          )}
        </div>
      </Paper>
    );
  }
}
