import React, { useState } from "react";
import { Menu, Checkbox } from "antd";

const SubNationality = ({
  nationality,
  additem,
  selectAllNationalities,
  natSelected,
  ...rest
}) => {
  const nationalities = nationality.map((nation) => nation.id);

  function checkAll(nationalitiesChecked) {
    let nationalitiesNeeded = [
      ...nationalities.filter(
        (nation) => !nationalitiesChecked.includes(nation)
      ),
    ];
    const checked = isCheckedAll(nationalitiesChecked);

    nationalitiesNeeded = [...new Set(nationalitiesNeeded)];
    if (checked) {
      for (let nation of nationalities) {
        additem("nationalities", nation, { target: { checked: false } });
      }
    } else {
      for (let nation of nationalitiesNeeded) {
        additem("nationalities", nation, { target: { checked: true } });
      }
    }
  }

  function isCheckedAll(nationalitiesChecked) {
    return nationalities.every((nation) =>
      nationalitiesChecked.includes(nation)
    );
  }

  return (
    <Menu.SubMenu
      key="Nationality"
      title={
        <span style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
          Nationalities
        </span>
      }
      {...rest}
    >
      <Menu.Item>
        <Checkbox
          onClick={() => checkAll(natSelected)}
          checked={isCheckedAll(natSelected)}
        >
          Select All
        </Checkbox>
      </Menu.Item>
      {nationality.map((nation) => (
        <Menu.Item key={nation.nationality}>
          <Checkbox
            onClick={(e) => additem("nationalities", nation.id, e)}
            checked={natSelected.includes(nation.id)}
          >
            {nation.nationality}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu.SubMenu>
  );
};

export default SubNationality;
