import React, { Component } from "react";
import { Menu, Checkbox, Tag } from "antd";
const { SubMenu } = Menu;
export class SubCity extends Component {
  constructor(props) {
    super(props);
  }

  //First checks all zones and then adds city using selectallzones

  ifZoneChecked = (zoneId) => {
    return this.props.zonesSelected.includes(zoneId);
  };

  checkAllOfRegion = (zones, zonesSelected, checked) => {
    let zonesNeeded = zones.map((zone) => zone.id);

    if (!checked) {
      zonesNeeded.filter((zone) => !zonesSelected.includes(zone));
    }

    for (let zone of zonesNeeded) {
      this.props.addzone("zones", zone, null);
    }
  };

  // checkAllOfRegion = (region, e) => {
  //   //get zones id under that region
  //   let zonesOfRegion = [];
  //   for (let city of this.props.cities) {
  //     for (let cityRegion of city.regions) {
  //       if (cityRegion.name === region) {
  //         zonesOfRegion = cityRegion.zone.map((zone) => zone.id);
  //       }
  //     }
  //   }
  //   //call addZone to push/remove zones to/from postObject object
  //   for (let zone of zonesOfRegion) {
  //     if (e.target.checked) {
  //       if (!this.props.zonesSelected.includes(zone))
  //         this.props.addzone("zones", zone, e);
  //     } else {
  //       if (this.props.zonesSelected.includes(zone))
  //         this.props.addzone("zones", zone, e);
  //     }
  //   }
  // };

  isCityCheckedAll = (regions, zonesSelected) => {
    return regions.every((region) =>
      this.isRegionCheckedAll(region.zone, zonesSelected)
    );
  };

  isRegionCheckedAll = (regionZones, zonesSelected) => {
    return regionZones.every((zone) => zonesSelected.includes(zone.id));
  };

  render() {
    //Performing a shallow copy of this.props object
    const filteredProps = { ...this.props };
    //Removing custom functional props as antd causes trouble with them
    ["selectallzones", "addzone"].forEach((item) => delete filteredProps[item]);

    return (
      <SubMenu
        key={this.props.country}
        title={this.props.country}
        {...filteredProps}
      >
        {this.props.cities
          .filter((city) => city.regions.length > 0 || city.zone.length > 0)
          .sort((c1, c2) => parseFloat(c1.id) - parseFloat(c2.id))
          .map((city) => (
            <>
              <SubMenu key={city.city + "1"} title={city.city}>
                <Menu.Item>
                  <Checkbox
                    checked={this.isCityCheckedAll(
                      city.regions,
                      this.props.zonesSelected
                    )}
                    onClick={(e) =>
                      this.props.selectallzones("cities", city.id, e)
                    }
                  >
                    Select All
                  </Checkbox>
                </Menu.Item>
                {city.regions
                  .sort((r1, r2) => parseFloat(r1.id) - parseFloat(r2.id))
                  .map((region) => (
                    <SubMenu key={region.name + "1"} title={region.name}>
                      <Menu.Item key={`${region.name}-selectall`}>
                        <Checkbox
                          onClick={() =>
                            this.checkAllOfRegion(
                              region.zone,
                              this.props.zonesSelected,
                              this.isRegionCheckedAll(
                                region.zone,
                                this.props.zonesSelected
                              )
                            )
                          }
                          checked={this.isRegionCheckedAll(
                            region.zone,
                            this.props.zonesSelected
                          )}
                        >
                          Select All
                        </Checkbox>
                      </Menu.Item>
                      {region.zone
                        .sort((z1, z2) => parseFloat(z1.id) - parseFloat(z2.id))
                        .map((zone) => (
                          <Menu.Item key={zone.id}>
                            <Checkbox
                              checked={this.props.zonesSelected.includes(
                                zone.id
                              )}
                              onClick={(e) =>
                                this.props.addzone("zones", zone.id, e)
                              }
                            >{`${zone.zone}`}</Checkbox>
                          </Menu.Item>
                        ))}
                    </SubMenu>
                  ))}
                {city.zone
                  .sort((z1, z2) => parseFloat(z1.id) - parseFloat(z2.id))
                  .map((zone) => (
                    <Menu.Item key={zone.id}>
                      <Checkbox
                        checked={this.props.zonesSelected.includes(zone.id)}
                        onClick={(e) => this.props.addzone("zones", zone.id, e)}
                      >{`${zone.zone}`}</Checkbox>
                    </Menu.Item>
                  ))}
              </SubMenu>
            </>
          ))}
      </SubMenu>
    );
  }
}

export default SubCity;
