import React, { Component } from "react";
import axios from "axios";
import { Link, Route, Switch } from "react-router-dom";

import FiltersPage from "./FiltersPage";
import TableTypesPage from "./TableTypesPage";
import DisplayModePage from "./DisplayModePage";
import FilesPage from "./FilesPage";
import Maps from "./Maps";

import { Modal, Button } from "antd";

import "../../css/Demographic.css";
import getUserDetail from "../../utils/getUserDetail";
import SubscriptionAlert from "../Dashboard/SubscriptionAlert";
import OneTimeSubPopUp from "../Dashboard/OneTimeSubPopUp";
import CancelPopUp from "../Dashboard/CancelPopUp";
import UserContext from "../UserContext";
import { isArray } from "lodash";

// const API_URL = "https://data.merd.online:8000/api/filter";
const API_URL = "https://data.merd.online:8000/demographic_cities";
const CANCEL_URL =
  "https://intelligentinsights.me/subscription-process-cancel/";

export default class NewDemographic extends Component {
  static contextType = UserContext;
  constructor() {
    super();
    this.dataToBeEmailed = null;
    this.state = {
      userDetails: null,
      registeredUser: true,
      subscriptionAlertOpen: false,
      oneTimeSubPopUpOpen: false,
      cancelPopUpOpen: false,
      cities: [],
      countryAndCities: {
        country: "",
        city: [],
      },
      firstTimePopUp: false,
      idx: 0,
      postObject: {
        cities: [],
        types: [],
        displayModes: [],
        type: "demogpc",
      },
      alertOpen: false,
    };
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  componentDidMount = () => {
    console.log("newdemo");

    let optionData;
    if (localStorage.getItem("demo-option-data")) {
      optionData = JSON.parse(localStorage.getItem("demo-option-data"));
      this.createData(optionData);
      console.log(optionData);
      this.setState({ firstTimePopUp: false });
    } else {
      this.setState({ firstTimePopUp: true });
      axios
        .get(API_URL)
        .then((res) => {
          optionData = res.data.cities;
          localStorage.setItem("demo-option-data", JSON.stringify(optionData));
          this.createData(optionData);
          this.setState({ firstTimePopUp: false });
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (localStorage.getItem("selectionsMade")) {
      const selectionsMade = JSON.parse(localStorage.getItem("selectionsMade"));
      if ((selectionsMade.type = "demographic")) {
        delete selectionsMade.type;
        this.setState({ postObject: selectionsMade });
        localStorage.removeItem("selectionsMade");
      }
    }
  };

  handleSubscriptionAlert = () => {
    this.setState({ subscriptionAlertOpen: false });
  };
  handleSubscriptionAlert = () => {
    this.setState({ subscriptionAlertOpen: false });
  };
  showOneTimeSubPopUp = () => {
    this.setState({ oneTimeSubPopUpOpen: true });
  };
  handleCancelPopUp = (state) => {
    if (!state) this.submitForm(this.state.postObject, false);
  };

  hideOneTimeSubPopUp = () => {
    this.setState({ oneTimeSubPopUpOpen: false });
    this.setState({ subscriptionAlertOpen: false });
    this.submitForm(this.dataToBeEmailed, true);
  };
  checkUserRights = () => {
    console.log("check");
    const userDetails = getUserDetail();
    this.setState({ userDetails });
    if (userDetails.username) this.setState({ registeredUser: true });
    else {
      this.setState({ registeredUser: false });
    }
    const citiesCheckedNames = [];
    this.state.postObject.cities.forEach((cityChecked) => {
      this.state.cities.forEach((city) => {
        if (city.id === cityChecked) citiesCheckedNames.push(city.city);
      });
    });
    if (this.state.idx === 0 && userDetails.username) {
      const invalidCities = citiesCheckedNames.filter(
        (cityChecked) =>
          !this.context.userData.demographicCities.includes(cityChecked)
      );
      console.log(citiesCheckedNames, invalidCities);
      if (invalidCities.length > 0) {
        this.dataToBeEmailed = {
          cities: citiesCheckedNames,
        };
        // this.setState({ subscriptionAlertOpen: true });
        this.setState({ idx: 0 });
      }
    } else if (!userDetails.username) {
      this.setState({ subscriptionAlertOpen: true });
      this.dataToBeEmailed = {
        cities: citiesCheckedNames,
      };
      this.setState({ idx: 0 });
    }
    return true;
  };

  submitForm = (postObject, oneTime) => {
    console.log(postObject);
    const user = this.context.userData;

    const createFormInput = (name, value, form) => {
      let input = document.createElement("input");
      input.name = name;
      input.value = value;
      form.appendChild(input);
    };

    let formTarget = this.context.userData.username ? "hidden-frame" : "_blank";

    let form = document.createElement("form");
    form.style.visibility = "hidden"; // no user interaction is necessary
    form.method = "POST"; // forms by default use GET query strings
    form.target = formTarget;
    form.action = CANCEL_URL;

    if (user.username) {
      createFormInput("username", this.context.userData.username, form);
    }
    if (oneTime) {
      createFormInput("type", "One Time Buy from demographic", form);
    } else {
      createFormInput("type", "Cancel from demographic", form);
    }

    let data = { ...postObject };

    data["categories"] = [...postObject.types, ...postObject.displayModes];

    for (let attribute in data) {
      createFormInput(
        attribute,
        isArray(data[attribute])
          ? data[attribute].join(", ")
          : String(data[attribute]),
        form
      );
    }
    console.log(form);
    document.body.appendChild(form);
    form.submit();
  };

  createData(optionData) {
    console.log(optionData);

    let cities = [];
    for (let city of optionData) {
      cities.push({
        city: city.city,
        id: city.id,
        country: {
          id: city.country_id,
          country: city.country__country,
        },
      });
    }

    this.setState({
      cities,
    });
    console.log(optionData);
    this.setState({ menuLoading: false });
  }

  handleNext = (max) => {
    if (!this.checkUserRights()) return false;

    if (this.state.postObject.cities.length === 0 && this.state.idx === 0) {
      this.setState({ alertOpen: true });
      return false;
    }
    if (
      (this.state.postObject.types.length === 0 ||
        this.state.postObject.displayModes.length === 0) &&
      this.state.idx === 1
    ) {
      this.setState({ alertOpen: true, idx: 1 });
      return false;
    }

    if (this.state.idx < max - 1) {
      this.setState({ idx: this.state.idx + 1 });
    }
  };

  handlePrev = () => {
    if (this.state.idx > 0) {
      this.setState({ idx: this.state.idx - 1 });
    }
  };
  handleTypeCheck = (types) => {
    const newPostObject = { ...this.state.postObject, types };
    this.setState({ postObject: newPostObject }, () =>
      console.log(this.state.postObject)
    );
  };
  handleDisplayModeCheck = (displayModes) => {
    this.setState({ postObject: { ...this.state.postObject, displayModes } });
  };

  warning = () => {
    Modal.warning({
      title: "SOMETHING IS MISSING...",
      content: "Please Select Atleast One Field Before Proceeding",
      onOk: () => this.setState({ alertOpen: false }),
    });
  };

  //  CountryCityMapper = () =>{
  // }

  render() {
    const pages = [
      <FiltersPage
        cities={this.state.cities}
        handleCitiesCheck={(cities) =>
          this.setState({ postObject: { ...this.state.postObject, cities } })
        }
        citiesChecked={this.state.postObject.cities}
        handleAlert={(state) => this.setState({ alertOpen: state })}
        setPostObject={(postObject) => this.setState({ postObject })}
      />,
      <div>
        <div className="flex gap-5 w-full justify-between second-page">
          <TableTypesPage
            cities={this.state.postObject.cities}
            handleTypeCheck={this.handleTypeCheck}
            types={this.state.postObject.types}
          />
          <DisplayModePage
            handleDisplayModeCheck={this.handleDisplayModeCheck}
            modes={this.state.postObject.displayModes}
            types={this.state.postObject.types}
            handleAlert={(state) => this.setState({ alertOpen: state })}
          />
        </div>
        <div className="btns mt-5">
          <Link to="/new-demographic">
            <button style={{ minWidth: "200px" }}>Previous</button>
          </Link>
          {this.state.postObject.displayModes.length > 0 &&
          this.state.postObject.types.length > 0 ? (
            <Link to="/new-demographic/files">
              <button className="mt-2" style={{ minWidth: "200px" }}>
                Next
              </button>
            </Link>
          ) : (
            <button className="disabled mt-2" style={{ minWidth: "200px" }}>
              {" "}
              Next
            </button>
          )}
        </div>
      </div>,
      <FilesPage
        postObject={this.state.postObject}
        citiesOptions={this.state.cities}
        registeredUser={this.context.userData.username}
        user={this.context.userData}
        handleCancel={() => this.handleCancelPopUp(false)}
        citiesSubscribed={this.context.userData.demographicCities}
        showOneTimeSubPopUp={this.showOneTimeSubPopUp}
        handleAlert={(state) => this.setState({ alertOpen: state })}
      />,
    ];
    return (
      <div className="w-full">
        <div className="top-banner">DEMOGRAPHIC INFORMATION</div>
        <div className="container w-75">
          {this.state.subscriptionAlertOpen && this.state.idx === 2 && (
            <SubscriptionAlert
              registered={this.context.userData.username && true}
              handleSubscriptionAlert={this.handleSubscriptionAlert}
              showOneTimeSubPopUp={this.showOneTimeSubPopUp}
              handleCancelPopUp={this.handleCancelPopUp}
              postObject={this.state.postObject}
            />
          )}{" "}
          {this.state.oneTimeSubPopUpOpen && (
            <OneTimeSubPopUp hideOneTimeSubPopUp={this.hideOneTimeSubPopUp} />
          )}
          {this.state.cancelPopUpOpen && (
            <CancelPopUp handleCancelPopUp={this.handleCancelPopUp} />
          )}
          <Modal
            visible={this.state.firstTimePopUp}
            title="Please wait while the filters load"
            onOk={() => this.setState({ firstTimePopUp: false })}
            onCancel={() => this.setState({ firstTimePopUp: false })}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={() => this.setState({ firstTimePopUp: false })}
              >
                <span style={{ color: "black" }}>Okay</span>
              </Button>,
            ]}
          >
            <div>
              Filters takes only a minute to load for the first time. Please
              wait. This only happens once.
            </div>
          </Modal>
          <div className="container">
            <Switch>
              <Route exact path="/new-demographic">
                {pages[0]}
              </Route>
              <Route exact path="/new-demographic/type">
                {pages[1]}
              </Route>
              <Route exact path="/new-demographic/files">
                {pages[2]}
              </Route>
              <Route exact path="/new-demographic/maps">
                <Maps
                  handleCancel={this.handleCancelPopUp}
                  user={this.context.userData}
                />
              </Route>
            </Switch>
          </div>
          {this.state.alertOpen && this.warning()}
          <iframe
            name="hidden-frame"
            id="hidden-frame"
            hidden
            frameborder="0"
          ></iframe>
        </div>
      </div>
    );
  }
}
