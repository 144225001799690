import { Chip, Collapse, Paper } from "@material-ui/core";
import { ExpandMore, LocalMall, PictureAsPdf } from "@material-ui/icons";
import { Checkbox, Empty, Menu, Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import submitForm from "../../utils/form";
import getUnique from "../../utils/getUnique";

import "../../css/SubscriptionPages/MallSubscription.css";
const { SubMenu } = Menu;

const REGISTERATION_URL = process.env.REACT_APP_REGISTERATION_URL;
const CITIES_API = "https://data.merd.online:8000/cities";
// const CITIES_API = "http://localhost:8000/cities";

const MAP_URL = "https://data.merd.online:8000/catchments_info/malls/";
// const MAP_URL = "http://localhost:8000/catchments_info/malls/";

function subscribe(cost, malls) {
  let data = {};
  console.log(malls);

  for (let mall of malls) {
    data[`Mall_${mall.name}`] = ["Catchments"];
  }

  console.log(data);
  let dataString = "";
  for (let [city, categories] of Object.entries(data)) {
    dataString += city + ":" + categories.join(",") + "|";
  }
  dataString = dataString.slice(0, -1);

  submitForm(
    {
      data: dataString,
      // amount: this.calAmountForExtra(this.state.totalSubscriptions),
      amount: cost,
    },
    "_blank",
    REGISTERATION_URL
  );
}

function MallCard({ mall }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="mall-card">
      <div className="mall-name">
        <span className="d-flex align-items-center justify-start">
          <LocalMall
            style={{
              fontSize: "1.5rem",
              color: "white",
              backgroundColor: "var(--lightBlue)",
              borderRadius: "10px",
              padding: "1px",
              marginInline: "10px",
            }}
          />
          <u> Mall: {mall.name}</u>
        </span>
      </div>
      <div className="mall-city-name">
        <span>
          Cities: {getUnique(mall.zones.map((zone) => zone.city)).join(", ")}
        </span>
        <a
          href={`https://data.merd.online:8000/maps/preview_${mall.name.replaceAll(
            " ",
            "_"
          )}.pdf`}
          target="_blank"
        >
          <div className="preview-map-btn">
            <PictureAsPdf /> Preview Map
          </div>
        </a>
      </div>
      <div className="zone-header">
        <u>Zones Covered</u>
      </div>
      {mall.zones.length > 3 ? (
        <>
          <div className="zones-name">
            {mall.zones.slice(0, 2).map((zone) => (
              <div className="zone-item">
                {zone?.firstPrimary && (
                  <Chip
                    label={<b>Primary Catchments</b>}
                    size="small"
                    color="secondary"
                  />
                )}
                <span>{zone.zone}</span>
              </div>
            ))}
          </div>

          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            className="zones-name"
          >
            {mall.zones.slice(3).map((zone) => (
              <div className="zone-item">
                {zone?.firstSecondary && (
                  <Chip
                    label={<b>Secondary Catchments</b>}
                    size="small"
                    color="primary"
                  />
                )}
                <span>{zone.zone}</span>
              </div>
            ))}
          </Collapse>
          <span
            onClick={() => setExpanded(!expanded)}
            style={{ color: "var(--lightBlue)", cursor: "pointer" }}
          >
            {expanded ? "Show Less" : "Show More"}
            <ExpandMore className={expanded ? "rotate-180" : ""} />
          </span>
        </>
      ) : (
        <div className="zones-name">
          {mall.zones.map((zone) => (
            <div className="zone-item">
              {zone?.firstPrimary && (
                <Chip
                  label={<b>Primary Catchments</b>}
                  size="small"
                  color="secondary"
                />
              )}
              {zone?.firstSecondary && (
                <Chip
                  label={<b>Secondary Catchments</b>}
                  size="small"
                  color="primary"
                />
              )}
              <span>{zone.zone}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default function MallSubscription() {
  const [options, setOptions] = useState({ malls: [] });
  const [menuItems, setMenuItems] = useState([]);
  const [selections, setSelections] = useState({ malls: [] });
  const [cost, setCost] = useState(0);
  function getOptions() {
    axios.get(CITIES_API).then((res) => {
      const data = res.data.data;
      const newMalls = data
        .filter((city) => city.country === "Mall")
        .map((mall) => ({
          name: mall.name.replace("Mall_", ""),
          zones: mall.zones
            .sort((z1, z2) => z1.type - z2.type)
            .map((zone, idx, zones) => {
              if (idx === 0) return { ...zone, firstPrimary: true };
              else if (
                idx !== 0 &&
                zones[idx - 1].type === 1 &&
                zone.type === 2
              )
                return { ...zone, firstSecondary: true };
              else return zone;
            }),
        }));
      console.log(data, newMalls);
      setOptions({ malls: newMalls });
      const allCountries = [];
      for (let mall of newMalls) {
        for (let zone of mall.zones) {
          if (!allCountries.includes(zone.country))
            allCountries.push(zone.country);
        }
      }
      console.log(allCountries, newMalls);
      const newMenu1 = [];
      for (let mall of newMalls) {
        const countryIdx = newMenu1.findIndex(
          (item) => item.country === mall.zones[0].country
        );
        if (countryIdx !== -1) {
          const cityIdx = newMenu1[countryIdx].cities.findIndex(
            (city) => city.city === mall.zones[0].city
          );
          if (cityIdx !== -1) {
            newMenu1[countryIdx].cities[cityIdx].malls.push(mall);
          } else {
            newMenu1[countryIdx].cities.push({
              city: mall.zones[0].city,
              key: uuid(),
              malls: [mall],
            });
          }
        } else {
          newMenu1.push({
            key: uuid(),
            country: mall.zones[0].country,
            cities: [
              {
                key: uuid(),
                city: mall.zones[0].city,
                malls: [mall],
              },
            ],
          });
        }
      }
      console.log(newMenu1);
      setMenuItems(newMenu1);
      const newMenu = allCountries.map((country) => ({
        key: uuid(),
        country: country,
        malls: newMalls.filter(
          (mall) =>
            mall.zones.findIndex((zone) => zone.country === country) !== -1
        ),
      }));
      console.log(newMenu);
    });
  }
  useEffect(getOptions, []);
  function selectMall(mallClicked, malls) {
    const newMalls = [...malls];
    const idx = newMalls.findIndex((mall) => mall === mallClicked);
    if (idx === -1) {
      newMalls.push(mallClicked);
    } else {
      newMalls.splice(idx, 1);
    }
    console.log(newMalls);
    setCost(newMalls.length * 1000);
    setSelections({ malls: newMalls });
  }

  return (
    <div className="mall-main">
      <div className="main-header uppercase">Mall Catchments Subscription</div>

      <div className="ppr-container">
        <Paper className="mall-list cstm-ppr">
          {options.malls.length > 1 ? (
            <>
              <div className="mall-header uppercase">Select Malls</div>
              <div className="mall-sub-header">
                Check mark the malls you want to subscribe to.
              </div>
              <div className="mall-ulist">
                <Menu
                  mode="inline"
                  theme="light"
                  defaultActiveFirst={["mall_city_0"]}
                >
                  {menuItems.map((country) => (
                    <SubMenu
                      title={
                        <span className="menu-country-header">
                          {country.country}
                        </span>
                      }
                      className="mall-submenu"
                      key={country.key}
                    >
                      {country.cities.map((city) => (
                        <SubMenu
                          title={
                            <span className="text-lg font-semibold">
                              {city.city}
                            </span>
                          }
                          key={city.key}
                        >
                          {city.malls.map((mall) => (
                            <Menu.Item key={mall.key}>
                              <Checkbox
                                className="mall-item"
                                key={mall.key + "check"}
                                checked={selections.malls.includes(mall.name)}
                                onClick={() =>
                                  selectMall(mall.name, selections.malls)
                                }
                              >
                                {mall.name}
                              </Checkbox>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      ))}
                    </SubMenu>
                  ))}
                </Menu>
              </div>
            </>
          ) : (
            <Skeleton active />
          )}
        </Paper>
        <Paper className="total-list cstm-ppr ">
          <div className="mall-header uppercase"> Malls and Zones</div>
          <div className="mall-sub-header">
            <span>
              What you will get with each mall:
              <ul>
                <li>1.Catchments Market Sizes For All Categories.</li>
                <li>
                  2.Demographic Data of the where the selected mall is located.
                </li>
                <li>
                  3.Tourist Data of the where the selected mall is located.
                </li>
              </ul>
            </span>
          </div>
          <div className="mall-selected-list">
            {selections.malls.length > 0 ? (
              options.malls
                .filter((mall) => selections.malls.includes(mall.name))
                .map((mall) => <MallCard mall={mall} />)
            ) : (
              <div className="w-100 h-100">
                <Empty description="Select Malls" />
              </div>
            )}
          </div>
          <div className="w-100 d-flex mt-3 justify-between">
            <div className="total-price">
              Total Subscriptions: {selections.malls.length}
              <br />
              Total: ${cost.toLocaleString()}
            </div>

            <button
              className={
                selections.malls.length > 0 ? "btn-size" : "btn-size disabled"
              }
              disabled={selections.malls.length === 0}
              onClick={() =>
                subscribe(
                  cost,
                  options.malls.filter((mall) =>
                    selections.malls.includes(mall.name)
                  )
                )
              }
            >
              Subscribe
            </button>
          </div>
        </Paper>
      </div>
    </div>
  );
}
