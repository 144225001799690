import { Delete, FileCopy, HighlightOff, OpenInNew } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";

const PUBLIC_FILE_UPLOAD_URL =
  "https://data.merd.online:8000/public_file_upload/";
// const PUBLIC_FILE_UPLOAD_URL = "http://localhost:8000/public_file_upload/";
const PUBLIC_FILE_URL = "https://data.merd.online:8000/public/";
// const PUBLIC_FILE_URL = "http://localhost:8000/public/";

export default function UploadPublic() {
  const [newLink, setNewLink] = useState(null);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    axios.get(PUBLIC_FILE_UPLOAD_URL).then((res) => {
      setFiles(res.data.files);
    });
  }, []);

  function copyText(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => alert("Link Copied Successfully"));
  }

  function clearLink() {
    setNewLink(null);
    fileInputRef.current.value = "";
  }

  function handleDelete(file) {
    if (window.confirm(`Are you sure you want to delete the file: ${file}`)) {
      axios
        .get(`${PUBLIC_FILE_UPLOAD_URL}?delete=1&file=${file}`)
        .then((res) => {
          setFiles(res.data.files);
        });
    }
  }

  function upload() {
    const file = fileInputRef.current.files[0];
    if (!file) {
      alert("Please choose a file(PDF).");
      return;
    }
    const form = new FormData();
    form.append("file", file);
    axios.post(PUBLIC_FILE_UPLOAD_URL, form).then((res) => {
      alert("File successfully uploaded✅");
      console.log(res.data);
      setNewLink(PUBLIC_FILE_URL + file.name);
      setFiles(res.data.files);
    });
  }
  return (
    <div className="public-upload-outer">
      <div className="flex gap-2">
        <div className=" flex flex-column gap-2 " style={{ width: "45vw" }}>
          <h1>Upload Public</h1>
          <input
            type="file"
            name="file-input"
            id="file-input"
            accept="application/pdf"
            ref={fileInputRef}
          />
          <button onClick={upload}>Upload Publically</button>
          {newLink ? (
            <div className="flex gap-2 items-center">
              <b>New Link Generated:</b>
              <i>
                <u>
                  <a href={newLink} target="_blank">
                    {newLink}
                  </a>
                </u>
              </i>
              <div
                className="m-2 p-2 rounded-full shadow-md bg-green-100 cursor-pointer"
                onClick={() => copyText(newLink)}
              >
                <FileCopy style={{ fontSize: "36px", color: "#32a856" }} />
              </div>
              <div
                className="m-2 p-2 rounded-full shadow-md bg-rose-100 cursor-pointer"
                onClick={clearLink}
              >
                <HighlightOff style={{ fontSize: "36px" }} color="secondary" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-column gap-1 items-start justify-between">
          <b className="text-xl mb-2">Files Uploaded</b>
          <div
            className="flex flex-column gap-1 items-start justify-between"
            style={{
              maxHeight: "40vh",
              overflowY: "auto",
              width: "45vw",
              overflowX: "hidden",
            }}
          >
            {files.map((file) => (
              <div className="rounded-lg p-2 m-1 font-bold bg-slate-100 w-100 flex items-center justify-between">
                {file}
                <div className="flex items-center justify-between gap-2">
                  <div className="rounded-full p-1 ml-2 bg-slate-100 border-2 border-blue-100 cursor-pointer">
                    <a key={file} href={PUBLIC_FILE_URL + file} target="_blank">
                      <OpenInNew color="primary" style={{ fontSize: "36px" }} />
                    </a>
                  </div>
                  <div
                    className="rounded-full p-1 ml-2 bg-slate-100 border-2 border-rose-100 cursor-pointer"
                    onClick={() => handleDelete(file)}
                  >
                    <Delete color="secondary" style={{ fontSize: "36px" }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
