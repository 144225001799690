import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { Table } from "antd";
import { v4 as uuid } from "uuid";
import { round } from "lodash";
import TitleHeader from "../TitleHeader";
import getExcel from "../../../utils/getExcel";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function ByZoneByMonth(props) {
  const { categories, year, city, monthsSelected, nationalities, allData } =
    props;

  const allCategories = [
    ...categories.categories.map((cat) => ({ name: cat.name, id: cat.id })),
    ...categories.subcategories.map((subcat) => ({
      name: subcat.name,
      id: subcat.id,
    })),
    ...categories.subsubcategories.map((subsubcat) => ({
      name: subsubcat.name,
      id: subsubcat.id,
    })),
  ];
  useEffect(() => console.log("AAAAAAAAAAAAA", allData), [allData]);

  function columns(regionWise) {
    const titleFormatter = (title) =>
      regionWise ? (
        <h4 style={{ fontWeight: 700, textAlign: "center" }}>{title}</h4>
      ) : (
        <h5>{title}</h5>
      );
    const dataFormatter = (data) =>
      regionWise ? (
        <span style={{ fontWeight: "500" }}>{data}</span>
      ) : (
        String(data)
      );
    return [
      {
        title: titleFormatter(regionWise ? "Region" : "Zone"),
        dataIndex: "name",
        titleText: regionWise ? "Region" : "Zone",
        rSpan: allCategories.length > 1 ? 3 : 2,
        key: regionWise ? "region" : "zone",
        width: 300,
        className: "column-custom",

        fixed: "left",
        render: (x) => dataFormatter(x),
        onCell: (cell) =>
          cell.name === "Total" ? { className: "region-last-row" } : "",
      },
      {
        title: titleFormatter("Population"),
        dataIndex: "population",
        numFmt: "#,##0",
        titleText: "Population",
        rSpan: allCategories.length > 1 ? 3 : 2,

        key: "population",

        className: "column-custom",

        render: (x) =>
          x === 0 || !x
            ? "N/A"
            : dataFormatter(x.toLocaleString("en", { useGrouping: true })),
      },
      ...monthsSelected.map((mon) => ({
        title: titleFormatter(months[mon - 1]),
        className: "bordered",
        titleText: months[mon - 1],
        cSpan: allCategories.length * 2,

        key: uuid(),
        children:
          allCategories.length > 1
            ? allCategories.map((cat) => ({
                titleText: cat.name,
                cSpan: 2,
                title: cat.name,
                key: uuid(),
                children: [
                  {
                    titleText: "Market Size (USD)",
                    numFmt: "$#,##0",
                    title: "Market Size (USD)",
                    key: uuid(),
                    align: "center",
                    dataIndex: `ms_${cat.id}_${mon}`,
                    render: (x) =>
                      x === 0 || !x
                        ? "N/A"
                        : dataFormatter(
                            `$${x.toLocaleString("en", { useGrouping: true })}`
                          ),
                  },
                  {
                    title: "Market Size/Capita (USD)",
                    titleText: "Market Size/Capita (USD)",
                    numFmt: "$#,##0",
                    key: uuid(),
                    align: "center",
                    dataIndex: `mscap_${cat.id}_${mon}`,
                    render: (x) =>
                      x === 0 || !x
                        ? "N/A"
                        : dataFormatter(
                            `$${x.toLocaleString("en", { useGrouping: true })}`
                          ),
                  },
                ],
              }))
            : [
                {
                  title: "Market Size (USD)",
                  titleText: "Market Size (USD)",
                  numFmt: "$#,##0",
                  key: uuid(),
                  align: "center",
                  dataIndex: `ms_${allCategories[0].id}_${mon}`,
                  render: (x) =>
                    x === 0 || !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),
                },
                {
                  title: "Market Size/Capita (USD)",
                  titleText: "Market Size/Capita (USD)",
                  numFmt: "$#,##0",
                  key: uuid(),
                  align: "center",
                  dataIndex: `mscap_${allCategories[0].id}_${mon}`,
                  render: (x) =>
                    Number(x) === 0 || !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),
                },
              ],
      })),
      {
        title: titleFormatter("Total"),
        className: "bordered",
        titleText: "Total",
        cSpan: allCategories.length > 1 ? allCategories.length * 2 : 2,

        key: uuid(),
        children:
          allCategories.length > 1
            ? allCategories.map((cat) => ({
                titleText: cat.name,
                title: cat.name,
                cSpan: 2,
                key: uuid(),
                children: [
                  {
                    title: "Market Size (USD)",
                    titleText: "Market Size (USD)",
                    numFmt: "$#,##0",
                    key: uuid(),
                    align: "center",
                    dataIndex: `ms_${cat.id}_Total`,
                    render: (x) =>
                      x === 0 || !x
                        ? "N/A"
                        : dataFormatter(
                            `$${x.toLocaleString("en", { useGrouping: true })}`
                          ),

                    onCell: (cell, idx) =>
                      cell.name === "Total"
                        ? { className: "region-last-row" }
                        : idx % 2
                        ? { classname: "total-light-blue" }
                        : { className: "total-dark-blue" },
                  },
                  {
                    title: "Market Size/Capita (USD)",
                    titleText: "Market Size/Capita (USD)",
                    numFmt: "$#,##0",
                    key: uuid(),
                    align: "center",
                    dataIndex: `mscap_${cat.id}_Total`,
                    render: (x) =>
                      x === 0 || !x
                        ? "N/A"
                        : dataFormatter(
                            `$${x.toLocaleString("en", { useGrouping: true })}`
                          ),
                    onCell: (cell, idx) =>
                      cell.name === "Total"
                        ? { className: "region-last-row" }
                        : idx % 2
                        ? { classname: "total-light-blue" }
                        : { className: "total-dark-blue" },
                  },
                ],
              }))
            : [
                {
                  titleText: "Market Size (USD)",
                  title: "Market Size (USD)",
                  numFmt: "$#,##0",
                  key: uuid(),
                  align: "center",
                  dataIndex: `ms_${allCategories[0].id}_Total`,
                  render: (x) =>
                    x === 0 || !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),
                  onCell: (cell, idx) =>
                    cell.name === "Total"
                      ? { className: "region-last-row" }
                      : idx % 2
                      ? { className: "total-light-blue" }
                      : { className: "total-dark-blue" },
                },
                {
                  title: "Market Size/Capita (USD)",
                  titleText: "Market Size/Capita (USD)",
                  numFmt: "$#,##0",
                  key: uuid(),
                  align: "center",
                  dataIndex: `mscap_${allCategories[0].id}_Total`,
                  render: (x) =>
                    x === 0 || !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),
                  onCell: (cell, idx) =>
                    cell.name === "Total"
                      ? { className: "region-last-row" }
                      : idx % 2
                      ? { className: "total-light-blue" }
                      : { className: "total-dark-blue" },
                },
              ],
      },
    ];
  }

  const allDataFormatted = {};
  for (let year of allData) {
    for (let _city of year.cities) {
      if (_city.city_id === city.city_id) {
        allDataFormatted[year.year] = getRegionData(city);
      }
    }
  }

  function getRegionData(rawData) {
    const regionData = [];
    let totalPopulation = 0;
    let totalMarketSizeData = {};

    console.log(monthsSelected);

    for (let zone of rawData.zones) {
      const idx = regionData.findIndex((reg) => reg.id === zone.region_id);
      const population = zone.population;
      totalPopulation += population;
      if (idx === -1) {
        const newRegion = {
          id: zone.region_id,
          key: uuid(),
          name: zone.region_name,
          population,
          zones: [zone],
        };
        for (const cat of allCategories) {
          for (const month of zone.months) {
            const catReqd = month.market_size_data.find(
              (zone_cat) => zone_cat.name === cat.name
            );
            if (catReqd) {
              const mktSize = round(catReqd.market_size, -3);
              newRegion[`ms_${cat.id}_${month.month}`] = mktSize;
            } else {
              newRegion[`ms_${cat.id}_${month.month}`] = 0;
              newRegion[`ms_${cat.id}_Total`] = 0;
            }
          }
        }
        regionData.push(newRegion);
      } else {
        const reqdRegion = regionData[idx];
        reqdRegion["population"] += population;
        reqdRegion.zones.push(zone);
        for (const cat of allCategories) {
          for (const month of zone.months) {
            const catReqd = month.market_size_data.find(
              (zone_cat) => zone_cat.name === cat.name
            );
            if (catReqd) {
              const mktSize = round(catReqd.market_size, -3);
              reqdRegion[`ms_${cat.id}_${month.month}`] += mktSize;
            }
          }
        }
      }
    }

    for (let cat of allCategories) {
      const totalKey = `ms_${cat.id}_Total`;
      // totalMarketSizeData[totalKey] = 0;
      for (let region of regionData) {
        region[totalKey] = 0;
        for (let key of Object.keys(region)) {
          if (key.includes(`ms_${cat.id}`) && !key.includes("Total"))
            region[totalKey] += region[key];
          // totalMarketSizeData[totalKey] += region[key];
        }
      }
    }
    for (let cat of allCategories) {
      const totalKey = `ms_${cat.id}_Total`;
      totalMarketSizeData[totalKey] = 0;
      for (let month of monthsSelected) {
        const key = `ms_${cat.id}_${month}`;
        totalMarketSizeData[key] = 0;
        for (let region of regionData) {
          totalMarketSizeData[key] += region[key];
          totalMarketSizeData[totalKey] += region[key];
        }
      }
    }

    regionData.sort((reg1, reg2) => reg1.id - reg2.id);

    for (const region of regionData) {
      for (const key of Object.keys(region)) {
        if (key.includes("ms_")) {
          if (isNaN(region[key])) region[key] = 0;
          const mscapKey = key.replace("ms_", "mscap_");
          let mksCap = round(region[key] / region.population) || 0;
          if (!isFinite(mksCap)) mksCap = 0;
          region[mscapKey] = mksCap;
        }
      }
    }

    let finalMktSizePerCap = {};
    for (let key of Object.keys(totalMarketSizeData)) {
      const msCapKey = key.replace("ms_", "mscap_");
      const mktsizeCap = totalMarketSizeData[key] / totalPopulation || 0;
      finalMktSizePerCap[msCapKey] = round(mktsizeCap);
    }
    console.log(finalMktSizePerCap, totalMarketSizeData);

    const totalRegion = {
      key: uuid(),
      name: "Total",
      ...totalMarketSizeData,
      ...finalMktSizePerCap,
      population: totalPopulation,
    };

    regionData.push(totalRegion);
    console.log(regionData);
    return regionData;
  }

  const rqdRegionData = getRegionData(city);

  function getZoneDataOfRegion(row) {
    const dataSource = [];
    console.log(row);

    for (let zone of row.zones) {
      const newZone = {
        name: zone.name,
        id: zone.id,
        population: zone.population,
      };
      for (let category of allCategories) {
        let catTotal = 0;
        for (let month of zone.months) {
          const reqdCategory = month.market_size_data.find(
            (cat) => cat.name === category.name
          );
          if (reqdCategory) {
            const marketSize = round(reqdCategory.market_size, -3);
            const msCap = round(marketSize / zone.population) || 0;
            newZone[`ms_${category.id}_${month.month}`] = marketSize;
            newZone[`mscap_${category.id}_${month.month}`] = msCap;
            catTotal += marketSize;
          } else {
            newZone[`ms_${category.id}_${month.month}`] = 0;
            newZone[`mscap_${category.id}_${month.month}`] = 0;
          }
        }
        newZone[`ms_${category.id}_Total`] = catTotal;
        newZone[`mscap_${category.id}_Total`] =
          round(catTotal / zone.population) || 0;
      }
      dataSource.push(newZone);
    }
    dataSource.sort((zon1, zon2) => zon1.id - zon2.id);

    let totalZone = { ...row };
    totalZone.name = "Total";
    totalZone.id = uuid();
    totalZone.zones = undefined;
    console.log(totalZone);
    dataSource.push(totalZone);
    return dataSource;
  }

  function displaySubTable(row) {
    const dataSource = getZoneDataOfRegion(row);

    return (
      <Paper
        style={{
          backgroundColor: "#002140",
          padding: "10px",
        }}
      >
        <Table
          columns={columns(false)}
          bordered
          scroll={{
            x: (monthsSelected.length + 1) * allCategories.length * 400 + 800,
            y: 300,
          }}
          dataSource={dataSource}
          pagination={false}
          rowClassName={(row, idx) =>
            row.name === "Total"
              ? "region-last-row"
              : idx % 2
              ? "region-light-row"
              : "region-dark-row"
          }
        />
      </Paper>
    );
  }

  const title = `Months: ${
    monthsSelected.length === 12
      ? "The Whole Year"
      : monthsSelected.map((mon) => months[mon - 1]).join(", ")
  }, Categories: ${allCategories.map((cat) => cat.name).join(", ")}, City: ${
    city.name
  }, Nationalities: ${nationalities.map((nat) => nat.nationality).join(", ")}`;

  return (
    <Paper elevation={5} className="tables-paper">
      <Table
        columns={columns(true)}
        bordered
        scroll={{
          x: 250 * ((monthsSelected.length + 1) * allCategories.length * 2 + 1),
          y: 400,
        }}
        expandable={{
          expandedRowRender: displaySubTable,
          rowExpandable: (row) => row.name !== "Total",
        }}
        dataSource={rqdRegionData}
        pagination={false}
        title={() => (
          <TitleHeader
            setToolTipOpen={props.setToolTipOpen}
            toolTipOpen={props.toolTipOpen}
            main={"By Zone By Month, Nationality Added"}
            city={city.name}
            year={year}
            categories={allCategories.map((cat) => cat.name)}
            nationalities={nationalities.map((nat) => nat.nationality)}
            months={
              monthsSelected.length === 12
                ? "The Whole Year"
                : monthsSelected.map((mon) => months[mon - 1])
            }
            handleExcelDownload={() =>
              getExcel(
                columns(true),
                allDataFormatted,
                title,
                getZoneDataOfRegion,
                null,
                null
              )
            }
          />
        )}
      />
    </Paper>
  );
}
