import axios from "axios";
import React, { useEffect, useReducer, useContext, useState } from "react";
import findGetParameter from "../../utils/paramReader";
import { Card, Avatar, Empty } from "antd";
import { Button } from "@material-ui/core";
import NotSubscribedPopUp from "./NotSubscribedPopUp";
import UserContext from "../UserContext";
import { GetApp as Download, PictureAsPdf } from "@material-ui/icons";

const { Meta } = Card;

// const API_URL = "http://localhost:8000/demographic/";
const API_URL = "https://data.merd.online:8000/demographic/";

const ACTIONS = {
  SAVE_FILES: "save_files",
  SET_CITY: "set-city",
};

function downloadBlob(blob, name) {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  console.log(blobUrl);
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

function handleDownload(table_name, filename) {
  axios
    .get(`${API_URL}?table_name=${table_name}`, {
      responseType: "blob",
    })
    .then((res) => {
      // const blob = new Blob([res.data], { type: "application/pdf" });
      // this.downloadBlob(blob, "test.pdf");
      console.log(res);
      downloadBlob(res.data, filename);
    })
    .catch((err) => {
      console.log(err);
    });
}

function MapFile({ file, user, setOpen }) {
  let [city, _, description] = file.replace(".pdf", "").split("_");

  const [downloadable, setDownloadable] = useState(false);
  if (description) description = description.replace("-", " ");
  else description = `Map for ${city}`;
  useEffect(() => {
    setDownloadable(
      user.username &&
        user.demographicCities.findIndex((demCity) => demCity === city) !== -1
    );
  }, [user]);

  return (
    <div className="shadow p-1 mb-5 bg-white rounded cursor-pointer">
      <Card
        style={{
          width: 300,
        }}
        actions={
          downloadable
            ? [
                <Button
                  onClick={() => handleDownload(file, file)}
                  variant="outlined"
                  style={{ color: "#3366CC" }}
                >
                  <Download key="download" /> Download Map
                </Button>,
              ]
            : [
                <Button
                  onClick={() => setOpen(true)}
                  variant="outlined"
                  style={{ color: "#3366CC" }}
                >
                  <Download key="download" /> Download Map
                </Button>,
              ]
        }
      >
        <Meta
          avatar={
            <Avatar
              size={40}
              icon={<PictureAsPdf />}
              style={{
                color: "white",
                backgroundColor: "#ff7a8a",
              }}
            />
          }
          title={city}
          description={description}
        />
      </Card>
    </div>
  );
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SAVE_FILES:
      return { ...state, files: action.files };
    case ACTIONS.SET_CITY:
      if (state.city !== action.city) return { files: [], city: action.city };
      else return state;
    default:
      throw "Action Not Valid in PDF Maps page";
  }
}

export default function Maps({ handleCancel }) {
  const [state, dispatch] = useReducer(reducer, { city: null, files: [] });
  const [open, setOpen] = useState(false);

  const user = useContext(UserContext).userData;
  useEffect(() => {
    const city = findGetParameter("city");
    if (!city) window.location.href = "/new-demographic";
    dispatch({ type: ACTIONS.SET_CITY, city });
    axios.post(API_URL, { operation: "get-pdf-files", city }).then((res) => {
      dispatch({ type: ACTIONS.SAVE_FILES, files: res.data.files });
      console.log(res.data.files);
    });
  }, []);

  return (
    <div className="w-100 d-flex align-items-start justify-content-center flex-column p-2">
      <div>
        {state.files.length === 0 ? (
          <div className="d-flex align-items-center justify-content-around">
            <Empty description="" />
            <h1>
              Sorry! No Maps availabe for {state.city}.<br />
              You can contact us{" "}
              <a
                href="https://merd.online/contact-us"
                style={{ borderBottom: "1px solid #3366CC", color: "#3366CC" }}
              >
                <i>here</i>
              </a>
              .
            </h1>
          </div>
        ) : (
          <div className="p-3">
            <u>
              <span style={{ fontSize: "2.5rem", fontWeight: "600" }}>
                Maps for city {state.city}
              </span>
            </u>
          </div>
        )}
      </div>
      <div className="cards-grid ">
        <NotSubscribedPopUp
          open={open}
          setOpen={setOpen}
          user={user}
          handleCancel={handleCancel}
        />
        {state.files.map((file) => (
          <MapFile
            file={file}
            user={user}
            handleCancel={handleCancel}
            setOpen={setOpen}
          />
        ))}
      </div>
    </div>
  );
}
