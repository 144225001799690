import { Button, Modal } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function NotPaidPopUp({ open, setOpen }) {
  return (
    <>
      <Modal
        title="Payment in process."
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button
            type="primary"
            onClick={() => setOpen(false)}
            style={{ border: "1px solid var(--darkBlue)" }}
          >
            <span style={{ color: "black" }}>Okay</span>
          </Button>,
        ]}
      >
        It seems your subscription is yet to be activated as payment is still
        due. Kindly contact us
        <Link to="contactus">
          {" "}
          <i style={{ color: "blue" }}>here</i>{" "}
        </Link>
        if you have already paid
      </Modal>
    </>
  );
}
