import { Modal } from "@material-ui/core";
import { DesktopMac, NavigateNext } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import "../../css/modal.css";

export default function MvMsgModal({ isModalOpen, handleClose }) {
  return (
    <Modal key={uuid()} open={isModalOpen}>
      <div className="mobile-view-modal">
        <div className="top-header">
          <div className="d-flex">
            <DesktopMac
              style={{
                marginRight: "5px",
                fontSize: "2.5rem",
                color: "royalblue",
                borderRadius: "50%",
                backgroundColor: "#bbf7fc",
                padding: "5px",
              }}
            />
            <span>
              This product is better viewed <br />
              on a desktop/laptop.
            </span>
          </div>
        </div>

        <div className="submit-btn-div">
          <div onClick={handleClose} id="mv-msg-center">
            OK <NavigateNext />
          </div>
        </div>
      </div>
    </Modal>
  );
}
