import "../../css/WebChat.css";

export default function Message() {
  return (
    <div className="chat-message">
      <div className="user-icon">s</div>
      <div className="msg-container">
        <div className="msg-text">Z</div>
        <div className="msg-name-time">
          <div className="msg-user">Username</div>
          <span style={{ marginInline: "2px", color: "grey" }}>•</span>
          <div className="msg-time">27.12.2022</div>
        </div>
      </div>
    </div>
  );
}
