import React, { Component } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Paper } from "@material-ui/core";
import {
  Menu,
  Checkbox,
  Divider,
  Collapse,
  Space,
  Tooltip,
  Tag,
  Empty,
} from "antd";
import { Switch, Route, Link } from "react-router-dom";
import submitForm from "../../utils/form";

import "../../css/SubscriptionPages/Filters.css";
import numberWithCommas from "../../utils/commaSeparated";
import "bootstrap/dist/css/bootstrap.min.css";
import UserContext from "../UserContext";
import { CheckCircle } from "@material-ui/icons";

const CITIES_URL = process.env.REACT_APP_CITIES;
const CATEGORIES_URL = process.env.REACT_APP_CATEGORIES;
const REGISTERATION_URL = process.env.REACT_APP_REGISTERATION_URL;

const { SubMenu } = Menu;
const { Panel } = Collapse;
const extraCategories = ["Mall", "Catchments", "None"];

const IndustrySVG = (
  <svg
    class="reportsClusterIcon reportsClusterIcon--industry flex__item margin-right-15 margin-bottom-s-10"
    viewBox="0 0 60 60"
    preserveAspectRatio="xMidYMid meet"
    width="60"
    height="60"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <title>Industries &amp; Markets</title>
    <circle
      class="reportsClusterIcon__backgroundCircle"
      cx="30"
      cy="30"
      r="30"
      fill="rgba(207, 0, 0, 0.1)"
    ></circle>
    <path
      class="reportsClusterIcon__iconPath"
      d="M36,28.5857864 L43.2928932,21.2928932 C43.9228581,20.6629284 45,21.1090951 45,22 L45,42.0020061 C45,43.5527689 43.5518005,45 42,45 L18,45 C16.4481995,45 15,43.5527689 15,42.0020061 L15,18 C15,16.4477153 16.4477153,15 18,15 L24,15 C25.5562374,15 27,16.4364585 27,18 L27,28.5857864 L34.2928932,21.2928932 C34.9228581,20.6629284 36,21.1090951 36,22 L36,28.5857864 Z M34,24.4142136 L26.7071068,31.7071068 C26.0771419,32.3370716 25,31.8909049 25,31 L25,18 C25,17.5433051 24.4539323,17 24,17 L18,17 C17.5522847,17 17,17.5522847 17,18 L17,42.0020061 C17,42.4478998 17.5524696,43 18,43 L42,43 C42.4475304,43 43,42.4478998 43,42.0020061 L43,24.4142136 L35.7071068,31.7071068 C35.0771419,32.3370716 34,31.8909049 34,31 L34,24.4142136 Z"
      fill="#e42929"
    ></path>
  </svg>
);

export default class Filters extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      optionCities: [],
      optionCategories: [],
      citiesSelected: [],
      countrySelected: null,
      cityCatSelected: [],
      type: null,
      totalSubscriptions: 0,
    };
    axios.get(CITIES_URL).then((res) => {
      console.log(res.data.data);
      const filteredCities = {};
      for (let city of res.data.data) {
        if (city.country === "Mall") continue;
        if (filteredCities.hasOwnProperty(city.country)) {
          filteredCities[city.country].push(city.name);
        } else {
          filteredCities[city.country] = [city.name];
        }
      }
      this.setState({
        optionCities: filteredCities,
        countrySelected: Object.keys(filteredCities)[0],
      });
      console.log(filteredCities);
    });
    axios.get(CATEGORIES_URL).then((res) => {
      console.log(res.data);
      const optionCategories = res.data.data
        .map((cat) => cat.name)
        .filter((cat) => !extraCategories.includes(cat))
        .filter((cat) => !cat.includes("Mall"));
      this.setState({ optionCategories });
    });
  }

  componentDidMount() {
    let params = new URL(window.location.href).searchParams;
    const type = params.get("type") || "allProduct";
    console.log(params.get("type"));
    this.setState({ type });
  }

  selectCity = (e, city, country) => {
    let cities = this.state.citiesSelected;
    if (e.target.checked) {
      cities = [...new Set([...cities, city])];
    } else {
      const idx = cities.findIndex((selectedCity) => selectedCity === city);
      if (idx !== -1) {
        cities.splice(idx, 1);
      }
    }
    let totalSubscriptions = 0;

    if (this.state.type !== "allProduct") {
      totalSubscriptions = cities.length;
    }

    this.setState({ citiesSelected: cities, totalSubscriptions });
  };

  calAmountForExtra(subs) {
    let perSub = this.state.type !== "allProduct" ? 3000 : 4000;
    return perSub * subs;
  }

  amtToString(amt) {
    let total = Math.floor(amt * 0.1) * 10;
    total = `$${numberWithCommas(total)}`;
    return total;
  }

  selections = (cities) => {
    if (cities.length === 0)
      return (
        <div align="center">
          <Empty
            description={<h5>No City Selected</h5>}
            style={{ height: "50px", width: "auto" }}
          />
        </div>
      );
    else {
      return (
        <div className="selection-list">
          {this.state.citiesSelected.map((city) => (
            <div className="city-list-item">
              <CheckCircle
                style={{
                  fontWeight: "800",
                  color: "#4f5bff",
                  marginRight: "10px",
                  fontSize: "2rem",
                }}
              />
              <div>
                <span className="font-bold">{city}</span>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  countryMenu = (country, cities) => {
    return (
      <SubMenu
        key={country}
        title={
          <span style={{ fontSize: "1.5rem" }} className="font-bold">
            {country}
          </span>
        }
        className="city-submenu"
      >
        {cities.sort().map((cityOption) => {
          return (
            <Menu.Item key={uuidv4()}>
              {this.state.type !== "allProduct" &&
              this.context.userData.cities.includes(cityOption) ? (
                <Tooltip
                  title={`You're already subcribed for ${cityOption}`}
                  placement="topLeft"
                >
                  <Checkbox
                    value={cityOption}
                    key={uuidv4()}
                    checked={true}
                    disabled
                  >
                    <span className="font-bold">{cityOption}</span>
                  </Checkbox>
                </Tooltip>
              ) : (
                <Checkbox
                  value={cityOption}
                  key={uuidv4()}
                  checked={this.state.citiesSelected.includes(cityOption)}
                  onClick={(e) => this.selectCity(e, cityOption, country)}
                >
                  <span className="font-bold">{cityOption}</span>
                </Checkbox>
              )}
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  };

  subscribeSubmit = () => {
    let data = {};
    if (this.state.type === "allProduct") {
      for (let sub of this.state.cityCatSelected) {
        if (data.hasOwnProperty(sub.city)) data[sub.city].push(sub.category);
        else {
          data[sub.city] = [sub.category];
        }
      }
    } else {
      for (let city of this.state.citiesSelected) {
        data[city] = ["Demographic"];
      }
    }
    console.log(data);
    let dataString = "";
    for (let [city, categories] of Object.entries(data)) {
      dataString += city + ":" + categories.join(",") + "|";
    }
    dataString = dataString.slice(0, -1);

    submitForm(
      {
        data: dataString,
        // amount: this.calAmountForExtra(this.state.totalSubscriptions),
        amount: this.calAmountForExtra(this.state.totalSubscriptions),
      },
      "_blank",
      REGISTERATION_URL
    );
  };

  showSubscriptions = (subs) => {
    const widthStyle =
      this.state.type === "demographic" ? "100%" : "fit-content";
    return (
      <Paper
        style={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          width: widthStyle,
          height: "fit-content",
        }}
      >
        <div className="total">
          <span style={{ fontWeight: "600", fontSize: "1.5rem" }}>
            Subscriptions:
          </span>
          <span style={{ fontWeight: "600", fontSize: "1.5rem" }}>
            {Number(subs)}
          </span>
        </div>
        <div className="total">
          <span style={{ fontWeight: "600", fontSize: "1.5rem" }}>Amount:</span>
          <span style={{ fontWeight: "600", fontSize: "1.5rem" }}>
            {this.amtToString(this.calAmountForExtra(subs))}
          </span>
        </div>
        <Divider />
        <button
          style={{ marginLeft: "auto" }}
          onClick={this.subscribeSubmit}
          disabled={subs === 0}
        >
          Subscribe
        </button>
      </Paper>
    );
  };

  handleCatSelection = (e, city, cat) => {
    const idx = this.state.cityCatSelected.findIndex(
      (sub) => sub.city === city && sub.category === cat
    );
    const newSubs = this.state.cityCatSelected;
    if (e.target.checked) {
      if (idx === -1) {
        newSubs.push({ city, category: cat });
      }
    } else {
      if (idx !== -1) {
        newSubs.splice(idx, 1);
      }
    }
    this.setState({
      cityCatSelected: newSubs,
      totalSubscriptions: newSubs.length,
    });
  };
  tellChecked = (city, cat) => {
    return (
      this.state.cityCatSelected.findIndex(
        (sub) => sub.city === city && sub.category === cat
      ) !== -1
    );
  };

  renderCategories = () => {
    console.log(this.state.citiesSelected);
    return (
      <div className="main-container">
        <Collapse
          defaultActiveKey={[this.state.citiesSelected[0]]}
          className="filter-collapse"
        >
          {this.state.citiesSelected.map((cityOption) => (
            <Panel header={<h1>{cityOption}</h1>} key={cityOption}>
              <span
                style={{ fontWeight: "600", fontSize: "1.5rem" }}
                className="mb-2"
              >
                Choose categories
              </span>
              <Space size={3} align="start" wrap>
                {this.state.optionCategories
                  .filter(
                    (catOption) =>
                      ![
                        "Demographic",
                        "City Report",
                        "Tourist Report",
                      ].includes(catOption)
                  )
                  .map((catOption) => {
                    if (
                      this.context.userData.subCombos.findIndex(
                        (sub) =>
                          sub.category === catOption && sub.city === cityOption
                      ) === -1
                    ) {
                      return (
                        <Tag color="blue">
                          <Checkbox
                            value={catOption}
                            onClick={(e) =>
                              this.handleCatSelection(e, cityOption, catOption)
                            }
                            checked={this.tellChecked(cityOption, catOption)}
                            key={uuidv4()}
                          >
                            {<h4>{catOption}</h4>}
                          </Checkbox>
                        </Tag>
                      );
                    } else {
                      return (
                        <Tooltip
                          title={`You are already subscribed for ${cityOption} and ${catOption}`}
                          key={uuidv4()}
                          placement="topLeft"
                        >
                          <Checkbox
                            value={catOption}
                            checked={true}
                            key={uuidv4()}
                            disabled
                          >
                            {catOption}
                          </Checkbox>
                        </Tooltip>
                      );
                    }
                  })}
              </Space>
            </Panel>
          ))}
        </Collapse>
        {this.showSubscriptions(this.state.totalSubscriptions)}
      </div>
    );
  };
  renderCities = () => {
    return (
      <div className="main-container">
        <br />
        <div className="subscription">
          <Paper elevation={4} className="cstm-paper">
            <div className="font-bold uppercase" style={{ fontSize: "2rem" }}>
              Select Cities
            </div>
            <div>
              <span style={{ fontSize: "1rem" }} className="font-bold">
                Countries/Cities
              </span>
              <Menu mode="inline" theme={"light"}>
                {Object.keys(this.state.optionCities)
                  .sort()
                  .filter((key) => key !== "None")
                  .map((country) =>
                    this.countryMenu(country, this.state.optionCities[country])
                  )}
              </Menu>
            </div>
          </Paper>
          <div className="city-selection-display">
            <Paper
              className="selection"
              style={{
                padding: "10px",
                overflowY: "scroll",
                height: this.state.type === "demographic" ? "none" : "60vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {IndustrySVG}
                <span
                  className="border-bottom pb-2"
                  style={{ fontSize: "2rem", fontWeight: "600" }}
                >
                  Cities
                </span>
              </div>
              <br />
              <div className="w-100 p-2">
                {this.selections(this.state.citiesSelected)}
              </div>
            </Paper>
            {this.state.type === "allProduct" ? (
              this.state.citiesSelected.length === 0 ? (
                <span
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBlock: "10px",
                  }}
                >
                  <button className={"disabled"} disabled={"disabled"}>
                    Select Categories
                  </button>
                </span>
              ) : (
                <Link
                  to="/subscribe/cat-select"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBlock: "10px",
                  }}
                >
                  <button>Select Categories</button>
                </Link>
              )
            ) : (
              this.showSubscriptions(this.state.totalSubscriptions)
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className="headerimg">
          <div className="imgin-header"></div>
          <div className="font-xl font-bold uppercase font-white title w-100">
            {this.state.type === "allProduct"
              ? "All Products Subscription"
              : "Demographic Subscription"}
          </div>
        </div>
        <div className="h-screen flex align-center justify-center gap-1 w-100">
          <Switch>
            <Route exact path="/subscribe">
              {this.renderCities()}
            </Route>
            <Route path="/subscribe/cat-select">
              {this.renderCategories()}
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}
