import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Divider } from "antd";
import { useState } from "react";
import {
  AssignmentTurnedIn,
  DateRange,
  ExpandMore,
  GroupAdd,
  LocationCity,
  ShoppingBasket,
} from "@material-ui/icons";

function CustomListItem({ icon, header, content, bgColor }) {
  if (typeof content === "object") content = content.join(", ");

  const [collapsedContent, setCollapsedContent] = useState(true);
  return (
    <>
      <div className="custom-list-item">
        <div className="icon-div">{icon}</div>
        <div className="content-div">
          <div className="list-header">
            <h1
              style={{
                fontSize: "1.5rem",
                fontWeight: "500",
                color: "rgba(0, 0, 0, 0.54)",
                backgroundColor: bgColor,
                padding: "2px",
                borderRadius: "2px",
                width: "20rem",
              }}
            >
              {header}
            </h1>
          </div>
          <div className="list-content">
            {content.length > 150 ? (
              collapsedContent ? (
                <>
                  {content.substring(0, 150) + "...  "}
                  <u>
                    <i>
                      <a
                        style={{ color: "#0077d5" }}
                        onClick={() => setCollapsedContent(false)}
                      >
                        Show More
                      </a>
                    </i>
                  </u>
                </>
              ) : (
                <>
                  {content + "  "}
                  <u>
                    <i>
                      <a
                        style={{ color: "#0077d5" }}
                        onClick={() => setCollapsedContent(true)}
                      >
                        Show Less
                      </a>
                    </i>
                  </u>
                </>
              )
            ) : (
              content
            )}
          </div>
        </div>
      </div>
      <Divider style={{ margin: "5px 10px" }} />
    </>
  );
}
export default function SelectionList({
  cities,
  years,
  months,
  categories,
  nationalities,
  listExpanded,
  handleAccordian,
}) {
  return (
    <Accordion
      elevation={10}
      className="selection-list-paper"
      defaultExpanded
      expanded={listExpanded}
      onChange={handleAccordian}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <h1
          style={{
            fontWeight: "500",
            color: "#012745",
            width: "100%",
            borderBottom: "1px solid lightgrey",
          }}
        >
          <AssignmentTurnedIn
            style={{
              fontSize: "2rem",
              marginRight: "5px",
              marginBottom: "6px",
              color: "#03589c",
            }}
          />
          Selections Made
        </h1>
      </AccordionSummary>
      <AccordionDetails>
        <div className="selection-list-items">
          <CustomListItem
            icon={
              <LocationCity
                style={{
                  fontSize: "2rem",
                  borderRadius: "50%",
                  backgroundColor: "#cde7fa",
                  height: "3rem",
                  width: "3rem",
                  padding: "2px",
                  color: "#0077d5",
                }}
              />
            }
            header={"Cities and Zones"}
            content={cities}
            bgColor={"#cde7fa"}
          />
          <CustomListItem
            icon={
              <DateRange
                style={{
                  fontSize: "2rem",
                  borderRadius: "50%",
                  backgroundColor: "#e7fac8",
                  height: "3rem",
                  width: "3rem",
                  padding: "2px",
                  color: "#7cc305",
                }}
              />
            }
            header={"Years and Months"}
            content={
              (years.length == 0
                ? "Select Years"
                : "Years: " + years.join(", ")) +
              " and " +
              (months.length === 0
                ? "Select Months"
                : "Months: " +
                  (months.length === 12 ? "The Whole Year" : months.join(", ")))
            }
            bgColor={"#e7fac8"}
          />
          <CustomListItem
            icon={
              <ShoppingBasket
                style={{
                  fontSize: "2rem",
                  borderRadius: "50%",
                  backgroundColor: "#faf3cf",
                  height: "3rem",
                  width: "3rem",
                  padding: "2px",
                  color: "#ffd700",
                }}
              />
            }
            header={"Categories"}
            content={categories}
            bgColor={"#faf3cf"}
          />
          <CustomListItem
            icon={
              <GroupAdd
                style={{
                  fontSize: "2rem",
                  borderRadius: "50%",
                  backgroundColor: "#fce0ca",
                  height: "3rem",
                  width: "3rem",
                  padding: "2px",
                  color: "#f56d00",
                }}
              />
            }
            header={"Nationalities"}
            content={nationalities}
            bgColor={"#fce0ca"}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
