import { Paper } from "@material-ui/core";
import { Message as MessageIcon } from "@material-ui/icons";
import "../../../css/WebChat.css";
import Message from "../Message";

export default function ClientChat() {
  return (
    <div className="chat-outer">
      <Paper elevation={4} className="chat-container">
        <div className="chat-header">
          <div className="icon-outer">
            <MessageIcon className="custom-chat-css" />
            <h1>Messages</h1>
          </div>
        </div>
        <div className="chat-body">
          <Message />
          <Message />
          <Message />
          <Message />
          <Message />
          <Message />
          <Message />
          <Message />
          <Message />
          <Message />
          <Message />
        </div>
        <div className="chat-send">
          <div className="user-icon"></div>
          <input type="text" />
          <div className="send-button"></div>
        </div>
      </Paper>
    </div>
  );
}
