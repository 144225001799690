import React, { Component } from "react";
import SubscriptionFilters from "./SubscriptionPages/SubscriptionFilters";
export default class SubscribeMore extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.location.href = "/subscribe";
  }

  render() {
    return (
      <>
        <h1>Hello World</h1>
        <SubscriptionFilters forMore={true} />
      </>
    );
  }
}
