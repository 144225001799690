import React from "react";
import { Checkbox } from "antd";

import "../../css/Demographic.css";
import { useEffect } from "react";
import { Paper } from "@material-ui/core";

export default function TableTypesPage({ handleTypeCheck, types, cities }) {
  useEffect(() => {
    if (cities.length === 0) window.location.href = "/new-demographic";
  });
  function handleOnClick(e) {
    const new_types = types;

    if (e.target.checked) {
      new_types.push(e.target.value);
    } else {
      const idx = new_types.findIndex((c) => c === e.target.value);
      if (idx !== -1) new_types.splice(idx, 1);
    }

    console.log(new_types);

    handleTypeCheck(new_types);
  }

  return (
    <Paper
      className="flex flex-col align-items-start justify-content-start p-2 filter-paper"
      elevation={4}
    >
      <div className="filter-title ">What would you like to view?</div>
      <div className="check-box-group">
        {types.includes("income") ? (
          <Checkbox
            value="income"
            checked={true}
            onClick={(e) => handleOnClick(e)}
            className="type-check"
          >
            <span className="check-text">Income Levels</span>
          </Checkbox>
        ) : (
          <Checkbox
            value="income"
            checked={false}
            onClick={(e) => handleOnClick(e)}
            className="type-check"
          >
            <span className="check-text">Income Levels</span>
          </Checkbox>
        )}
        {types.includes("population") ? (
          <Checkbox
            value="population"
            checked={true}
            onClick={(e) => handleOnClick(e)}
            className="type-check"
          >
            <span className="check-text">Population</span>
          </Checkbox>
        ) : (
          <Checkbox
            value="population"
            checked={false}
            onClick={(e) => handleOnClick(e)}
            className="type-check"
          >
            <span className="check-text">Population</span>
          </Checkbox>
        )}
      </div>
    </Paper>
  );
}
