import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { Checkbox, Menu, Divider, Empty, Tag } from "antd";

import "../../css/Demographic.css";
import { Link } from "react-router-dom";
import { uniqueId } from "lodash";
import { Ballot, CheckCircle, NavigateNext, Public } from "@material-ui/icons";

const { SubMenu } = Menu;

export default class FiltersPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (localStorage.getItem("selectionsMade")) {
      let selectionsMade = JSON.parse(localStorage.getItem("selectionsMade"));
      if (selectionsMade.type === "demogpc") {
        this.props.setPostObject(selectionsMade);
        localStorage.removeItem("selectionsMade");
      }
    }
  }

  handleCitySelect = (e, city) => {
    const cities = this.props.citiesChecked;

    if (e.target.checked) {
      cities.push(city);
    } else {
      const idx = cities.findIndex((c) => c === city);
      if (idx !== -1) cities.splice(idx, 1);
    }

    this.props.handleCitiesCheck(cities);

    console.log(this.props.citiesChecked);
  };
  citiesMenu = (citiesOptions) => {
    const countries = [];
    for (let city of citiesOptions) {
      const idx = countries.findIndex(
        (country) => country.country === city.country.country
      );
      if (idx === -1) {
        countries.push({
          country: city.country.country,
          cities: [{ id: city.id, city: city.city }],
        });
      } else {
        countries[idx].cities.push({ id: city.id, city: city.city });
      }
    }

    return (
      <Paper
        elevation={4}
        style={{
          marginTop: "40px",
        }}
        className="city-menu-demo"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            lineHeight: "20px",
            margin: "10px",
            borderBottom: "1px solid grey",
          }}
        >
          <Public
            style={{
              fontSize: "5rem",
              color: "lightgreen",
              border: "1px solid white",
              borderRadius: "50%",
              overflow: "hidden",
              backgroundColor: "rgb(0, 119, 213)",
              margin: "0 5px 5px 5px",
            }}
          />
          <div
            style={{
              backgroundColor: "white",
              height: "auto",
              color: "black",
              fontSize: "2rem",
              textAlign: "left",
              fontWeight: "600",
            }}
          >
            Choose Cities
            <br />
          </div>
        </div>
        <div className="selection-menu-demo">
          <Menu mode="inline">
            {countries.map((country) => (
              <SubMenu
                className="mall-submenu"
                key={country.country}
                title={
                  <span
                    style={{
                      fontSize: "1.5rem",
                      lineHeight: "1.75",
                      fontWeight: "700",
                    }}
                  >
                    {country.country}
                  </span>
                }
              >
                {country.cities.map((city) => (
                  <Menu.Item key={uniqueId()}>
                    <Checkbox
                      value={city.city}
                      checked={this.props.citiesChecked.includes(city.city)}
                      onClick={(e) => this.handleCitySelect(e, city.city)}
                      key={uniqueId()}
                    >
                      <b>{city.city}</b>
                    </Checkbox>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </div>
      </Paper>
    );
  };

  getCheckedCitiesName = () => {
    const names = [];
    console.log(this.props.cities);
    this.props.cities.forEach((city) => {
      this.props.citiesChecked.forEach((cityChecked) => {
        if (cityChecked === city.city)
          names.push({ city: city.city, country: city.country.country });
      });
    });
    return names;
  };

  render() {
    return (
      <div className="page">
        <div className="filter-papers">
          {this.props.cities.length > 0 && this.citiesMenu(this.props.cities)}
          <div className="demo-list-container">
            <Paper
              style={{
                position: "relative",
              }}
              className="demo-selections-paper"
              elevation={4}
            >
              <div className="main-heading">
                <Ballot
                  style={{
                    fontSize: "2rem",
                    marginRight: "5px",
                    marginBottom: "6px",
                    color: "#03589c",
                  }}
                />
                Cities Selected{" "}
              </div>
              <div className="demo-selections-list">
                <div className="ml-2 d-flex flex-col ">
                  {this.props.citiesChecked.length > 0 ? (
                    this.getCheckedCitiesName().map((city) => (
                      <div className="city-list-item">
                        <CheckCircle
                          style={{
                            fontWeight: "800",
                            color: "#4f5bff",
                            marginRight: "10px",
                            fontSize: "2rem",
                          }}
                        />
                        <div>
                          <span className="bolder">{city.city}</span>
                          {", "}
                          <span>{city.country}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <Empty description={"Select Cities"} />
                    </>
                  )}
                </div>
              </div>
            </Paper>
            {this.props.citiesChecked.length > 0 ? (
              <Link to="/new-demographic/type">
                <button className="mt-2" style={{ minWidth: "200px" }}>
                  Next
                </button>
              </Link>
            ) : (
              <button
                disabled
                className="disabled mt-2"
                style={{ minWidth: "200px" }}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
