import { Room } from "@material-ui/icons";
import { Menu } from "antd";
import SubCity from "../Components/Dashboard/Menu/SubCity";

const { SubMenu } = Menu;

const displayCities = (cities, postObject, addZone, selectAllZones) => {
  console.log(postObject);
  console.log(cities);
  const countries = [];
  for (let city of cities) {
    const idx = countries.findIndex(
      (country) => country.name === city.country.country
    );
    if (idx === -1) {
      countries.push({
        id: city.country.id,
        name: city.country.country,
        cities: [],
      });
    }
  }
  for (let city of cities) {
    for (let country of countries) {
      if (city.country.country === country.name) {
        country.cities.push({ ...city, regions: [] });
      }
    }
  }
  for (let country of countries) {
    for (let city of country.cities) {
      let newZones = [];
      for (let zoneOption of city.zone) {
        if (zoneOption.hasOwnProperty("region")) {
          if (zoneOption.region) {
            const idx = city.regions.findIndex(
              (reg) => reg.name === zoneOption.region.region
            );
            if (idx !== -1) {
              city.regions[idx].zone.push(zoneOption);
            } else {
              city.regions.push({
                id: zoneOption.region.id,
                name: zoneOption.region.region,
                zone: [zoneOption],
              });
            }
          } else {
            newZones.push(zoneOption);
          }
        } else {
          newZones.push(zoneOption);
        }
      }
      city.zone = newZones;
    }
  }
  console.log(countries);
  countries.sort((c1, c2) => parseFloat(c1.id) - parseFloat(c2.id));
  console.log(countries);

  return (
    <SubMenu
      key="City"
      title={
        <span style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
          Countries and Cities
        </span>
      }
      style={{
        marginTop: "1rem",
        fontWeight: "600",
      }}
      className="round-menu-items"
      icon={<Room style={{ fontSize: "1.25rem", lineHeight: "1.75" }} />}
    >
      {countries.length > 1 &&
        countries.map((country) => (
          <SubCity
            key={country.name}
            cities={country.cities}
            zonesSelected={postObject.zones}
            country={country.name}
            addzone={addZone}
            selectallzones={selectAllZones}
          />
        ))}
    </SubMenu>
  );
};

export default displayCities;
