import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./index.css";
import getUnique from "./utils/getUnique";

import HomePage from "./Components/HomePage";
import Admin from "./Components/Admin";
import Dashboard from "./Components/Dashboard";
import Catchments from "./Components/Catchments";
import NewDemographic from "./Components/NewDemographic";
import MapComponent from "./Components/MapComponent";
import SubscriptionFilters from "./Components/SubscriptionPages/SubscriptionFilters";
import SubscribeMore from "./Components/SubscribeMore";
import TouristReports from "./Components/TouristReports";
import "./css/main.css";
import Chat from "./Components/Chat";
import StyleLinks from "./Components/Navs/StyleLinks";
import LoggedInNav from "./Components/Navs/LoggedInNav";
import UserContext from "./Components/UserContext";

import Nav from "./Components/Navs/Nav";
import NewFooter from "./Components/NewFooter";
import NewAdminChat from "./Components/NewChat/AdminChat";
import AdminChat from "./Components/Chat/AdminChat";
import ClientChat from "./Components/NewChat/ClientChat";
import { getCookie, setCookie, eraseCookie } from "./utils/cookie";
import axios from "axios";
import findGetParameter from "./utils/paramReader";
import MvMsgModal from "./Components/Modals/MvMsgModal";
import MallSubscription from "./Components/SubscriptionPages/MallSubscription";

// const USER_API = "http://localhost:8000/user_data/?session_id=";
const USER_API = "https://data.merd.online:8000/user_data/?session_id=";
// const LAST_UPDATE = "http://localhost:8000/lastupdate/";
const LAST_UPDATE = "https://data.merd.online:8000/lastupdate/";
const MALLS_URL = "https://data.merd.online:8000/catchments_info/";

function App() {
  const [userData, setUserData] = useState({
    username: false,
    subCombos: [],
    demographicCities: [],
    touristReportsCities: [],
    cityReportsCities: [],
    categories: [],
    cities: [],
    catchments: [],
    catchmentCities: [],
    someNotActive: false,
  });
  let session_id = findGetParameter("session_id");
  const [mvMsgModal, setMvMsgModal] = useState(false);

  function getUserDetail() {
    if (session_id) {
      eraseCookie("session_id");
      setCookie("session_id", session_id);
      console.log("new cookie set");
    } else if (getCookie("session_id")) {
      session_id = getCookie("session_id");
      console.log("cookie saved retrieved");
    } else {
      eraseCookie("session_id");
      console.log("all old cookies deleted ");

      return;
    }

    axios.get(MALLS_URL).then((res) => {
      const malls = res.data.data;
      console.log(malls);
      axios
        .get(USER_API + session_id)
        .then((res) => {
          const data = res.data.data;
          console.log(data);

          let subCombos = [];
          let demographicCities = [];
          let touristReportsCities = [];
          let cityReportsCities = [];
          let categories = [];
          let cities = [];
          let catchments = [];
          let someNotActive = false;
          const username = data[0][0];
          for (let sub of data) {
            console.log(sub);
            let active = sub[3] !== "0";
            active = true;
            if (sub[2] === "Demographic" && active) {
              demographicCities.push(sub[1]);
            } else if (sub[2] === "City Report" && active) {
              cityReportsCities.push(sub[1]);
            } else if (sub[2] === "Tourist Report" && active) {
              touristReportsCities.push(sub[1]);
            } else if (sub[2] === "Catchments" && active) {
              catchments.push(
                malls.filter((mall) => mall.name === sub[1].split("_")[1])
              );
            } else {
              if (active) {
                demographicCities.push(sub[1]);
                touristReportsCities.push(sub[1]);
                cityReportsCities.push(sub[1]);
                subCombos.push({
                  city: sub[1],
                  category: sub[2],
                  active,
                });
                cities.push(sub[1]);
                categories.push(sub[2]);
              } else {
                subCombos.push({
                  city: sub[1],
                  category: sub[2],
                  active,
                });
                cities.push(sub[1]);
                categories.push(sub[2]);
              }
            }
          }
          catchments = catchments.flat();
          const catchmentCities = getUnique(
            catchments.map((mall) => mall.city_name)
          );
          demographicCities = [
            ...new Set([...demographicCities, ...catchmentCities]),
          ];
          cityReportsCities = [
            ...new Set([...cityReportsCities, ...catchmentCities]),
          ];
          touristReportsCities = [
            ...new Set([...touristReportsCities, ...catchmentCities]),
          ];
          categories = [...new Set(categories)];
          cities = [...new Set(cities)];

          const newUserData = {
            username,
            subCombos,
            demographicCities,
            touristReportsCities,
            cityReportsCities,
            categories,
            cities,
            catchments,
            catchmentCities,
            someNotActive,
          };
          setUserData(newUserData);
          console.log("USER", newUserData);

          eraseCookie("session_id");

          setCookie("session_id", session_id, 7 * 24 * 60 * 60);
        })
        .catch((err) => {
          console.error(err);
          eraseCookie("session_id");
          setUserData({
            username: false,
            subCombos: [],
            demographicCities: [],
            touristReportsCities: [],
            cityReportsCities: [],
            categories: [],
            cities: [],
            catchments: [],
            catchmentCities: [],
            someNotActive: false,
          });
        });
    });
  }

  useEffect(() => {
    if (localStorage.getItem("option-data")) {
      axios.get(LAST_UPDATE).then((res) => {
        console.log(res.data.lastupdate);

        const lastupdatSaved = JSON.parse(
          localStorage.getItem("option-data")
        )[5][1].trim();
        if (lastupdatSaved !== res.data.lastupdate) localStorage.clear();
      });
    }
    const mvMsgSeen = getCookie("mv-msg-seen");
    if (window.innerWidth < 820) {
      if (!mvMsgSeen) {
        setCookie("mv-msg-seen", 1);
        setMvMsgModal(true);
        window.location.href = "#mv-msg-center";
      }
    }
    getUserDetail();
  }, [window.innerWidth]);

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      <div className="App">
        <StyleLinks />
        {userData.username ? <LoggedInNav /> : <Nav />}
        <div className="app-content">
          <BrowserRouter>
            <Switch>
              <Route path="/" exact component={HomePage} />

              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/catchments" component={Catchments} />
              <Route path="/new-demographic" component={NewDemographic} />
              <Route path="/map" exact component={MapComponent} />
              <Route path="/subscribe-more" exact component={SubscribeMore} />
              <Route path="/subscribe" component={SubscriptionFilters} />
              <Route path="/subscribe-mall" component={MallSubscription} />

              {/* <Route path="/cityreport" exact component={CityReports} /> */}
              <Route path="/touristreport" exact component={TouristReports} />
              <Route path="/chat" exact component={Chat} />
              <Route path="/clientchat" exact component={ClientChat} />
              <Route path="/admin" exact component={Admin} />
              <Route path="/newadminchat" component={NewAdminChat} />
              <Route path="/adminchat" component={AdminChat} />
              <Redirect from="*" to="/" />
            </Switch>
          </BrowserRouter>
          <MvMsgModal
            isModalOpen={mvMsgModal}
            handleClose={() => setMvMsgModal(false)}
          />
        </div>
        <NewFooter />
      </div>
    </UserContext.Provider>
  );
}

export default App;
