import React from "react";
import "../../css/modal.css";
import { Close, ErrorOutline } from "@material-ui/icons";
import { Modal } from "@material-ui/core";
import { v4 as uuid } from "uuid";

export default function MessageModal({
  title,
  message,
  isModalOpen,
  handleClose,
}) {
  return (
    <Modal key={uuid()} open={isModalOpen}>
      <div className="view-option-modal">
        <div className="top-header">
          <div>
            <ErrorOutline
              style={{
                marginRight: "10px",
                fontSize: "2rem",
                color: "#e84656",
                borderRadius: "50%",
                backgroundColor: "#fcaa9f",
              }}
            />
            <span>{title}</span>
          </div>
          <Close onClick={handleClose} className="modal-close-button" />
        </div>
        <div className="mall-options">
          <span>{message}</span>
        </div>
      </div>
    </Modal>
  );
}
