import React, { Component } from "react";
import { Checkbox, Collapse, Space, Tag } from "antd";
import { v4 as uuid } from "uuid";
import { PictureAsPdf } from "@material-ui/icons";

const { Panel } = Collapse;
const MAP_URL = "https://data.merd.online:8000/catchments_info/malls/";

export default class ZoneFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryZones: [],
      secondaryZones: [],
      allZones: [],
    };
  }
  componentDidMount() {
    this.setState({
      primaryZones: this.props.zoneOptions.filter((zone) => zone.type === 1),
      secondaryZones: this.props.zoneOptions.filter((zone) => zone.type === 2),
      allZones: this.props.zoneOptions.filter((zone) => zone.type === 3),
    });

    if (
      (this.props.mallSelected === null && this.props.citySelected === null) ||
      this.props.citySelected === null
    )
      window.location.href = "/catchments";
    else if (this.props.mallSelected == null)
      window.location.href = "/catchments/mall";
  }
  render() {
    if (this.props.mallSelected === null) {
      window.location.href = "/catchments";
      return null;
    }
    return (
      <div className="mr-5 selection-filters">
        <div>
          <h5 className=" mb-2">
            <span style={{ fontWeight: "600", fontSize: "2rem" }}>
              <u>{this.props.mallSelected.toUpperCase() + " "}</u> CATCHMENTS
              AREAS NOTE:
            </span>
            <br />
            We have carefully delineated the catchments areas based on drive
            time and competitive environment. However, if you want to be
            conservative and deselect any zone, then you can do that.
          </h5>
          <div className="d-flex align-items-center justify-end">
            <a
              href={`https://data.merd.online:8000/maps/preview_${this.props.mallSelected.replaceAll(
                " ",
                "_"
              )}.pdf`}
              target="_blank"
            >
              <div className="preview-map-btn map-dwnld">
                <PictureAsPdf /> Preview Map
              </div>
            </a>
          </div>
          <div className="zone-filter-container">
            <span>{this.props.mallSelected}</span>
            <Collapse className="zone-collapse">
              {this.state.allZones.length === 0 ? (
                <>
                  {this.state.primaryZones.length > 0 && (
                    <Panel
                      header={
                        <span className="sta-pta-p-header">Primary Zones</span>
                      }
                      key={"1"}
                    >
                      <div className="sta-pta">
                        <Space direction="vertical">
                          {this.state.primaryZones.map((zone) => (
                            <Checkbox
                              key={uuid()}
                              value={zone.name}
                              checked={this.props.zonesSelected.find(
                                (zoneIn) => zoneIn.name === zone.name
                              )}
                              onClick={(e) => this.props.handleZoneSelect(e)}
                            >
                              <b>{zone.name}</b>
                            </Checkbox>
                          ))}
                        </Space>
                      </div>
                    </Panel>
                  )}
                  {this.state.secondaryZones.length > 0 && (
                    <Panel
                      header={
                        <span className="sta-pta-s-header">
                          Secondary Zones
                        </span>
                      }
                      key={"2"}
                    >
                      <div className="sta-pta">
                        <Space direction="vertical">
                          {this.state.secondaryZones.map((zone) => (
                            <Checkbox
                              key={uuid()}
                              value={zone.name}
                              checked={this.props.zonesSelected.find(
                                (zoneIn) => zoneIn.name === zone.name
                              )}
                              onClick={(e) => this.props.handleZoneSelect(e)}
                            >
                              <b>{zone.name}</b>
                            </Checkbox>
                          ))}
                        </Space>
                      </div>
                    </Panel>
                  )}
                </>
              ) : (
                <Panel
                  header={
                    <span className="sta-pta-a-header border border-black">
                      All Zones
                    </span>
                  }
                  key={"1"}
                >
                  <div className="sta-pta">
                    <Space direction="vertical">
                      {this.state.allZones.map((zone) => (
                        <Checkbox
                          key={uuid()}
                          value={zone.name}
                          checked={this.props.zonesSelected.find(
                            (zoneIn) => zoneIn.name === zone.name
                          )}
                          onClick={(e) => this.props.handleZoneSelect(e)}
                        >
                          <b>{zone.name}</b>
                        </Checkbox>
                      ))}
                    </Space>
                  </div>
                </Panel>
              )}
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}
