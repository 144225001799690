import React, { Component } from "react";
import { Layout } from "antd";
import axios from "axios";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import UserList from "./UserList";
import ChatBox from "./ChatBox";

import "../../css/WebChat.css";

const CHAT_MESSAGES = process.env.REACT_APP_OLD_CHATS;
// const CHAT_MESSAGES = "http://localhost:8000/chat/";
const CHAT_USERS = CHAT_MESSAGES + "users/";
const CHAT_CLIENT = process.env.REACT_APP_CHAT_CLIENT;
// const CHAT_CLIENT = "ws://localhost:8000/chat/ws/chat/";

export default class AdminChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      unreadUsers: [],
      userSelected: null,
      messages: [],
    };
    this.client = null;
  }
  componentDidMount() {
    axios
      .get(`${CHAT_USERS}all/`)
      .then((res) => {
        console.log(res.data.users);
        this.setState({ users: res.data.users });
        const usersMessaged = res.data.users;
        axios.get(`${CHAT_USERS}unread/`).then((res) => {
          this.setState({ unreadUsers: res.data.users });
          this.selectUser(usersMessaged[0]);
        });
      })
      .then(() => {});
  }
  createWebSocket = (room) => {
    this.client = new W3CWebSocket(CHAT_CLIENT + room + "/");
    this.client.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    this.client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      console.log("got reply! ", dataFromServer.name);
      if (dataFromServer) {
        this.setState((state) => ({
          messages: [
            ...state.messages,
            {
              message: dataFromServer.message,
              user: dataFromServer.user,
              created_at: new Date().toLocaleString(),
            },
          ],
        }));
      }
    };
  };
  selectUser = (user) => {
    if (!user) return false;
    axios.get(`${CHAT_MESSAGES}${user.room}/`).then((res) => {
      console.log(res.data);
      this.setState({ userSelected: user, messages: res.data.messages });
    });
    this.createWebSocket(user.room);
    this.setRead(user.user);
  };
  setAdminMessage = (message) => {
    this.setState({ adminMessage: message });
  };
  sendMessage = (message) => {
    if (message.trim() === "") return false;
    this.client.send(
      JSON.stringify({
        type: "chatroom_message",
        message,
        user: "MERD Admin",
      })
    );
    this.setAdminMessage("");
  };
  setRead = (username) => {
    axios.get(`${CHAT_MESSAGES}setread/${username}/`).then((res) => {
      if (res.data.success) {
        const idx = this.state.unreadUsers.findIndex(
          (user) => user.user === username
        );
        if (idx !== -1) {
          let newUnreadUsers = this.state.unreadUsers;
          newUnreadUsers.splice(idx, 1);
          this.setState({ unreadUsers: newUnreadUsers });
        }
      }
    });
  };
  deleteChats = (username) => {
    axios.get(`${CHAT_MESSAGES}delete/${username}/`).then((res) => {
      if (res.data.success) {
        const idx = this.state.users.findIndex(
          (user) => user.user === username
        );
        if (idx !== -1) {
          const newUsers = this.state.users;
          newUsers.splice(idx, 1);
          this.setState({ users: newUsers });
          if (newUsers.length > 0) {
            this.selectUser(newUsers[0]);
          } else {
            this.selectUser(null);
          }
        }
      }
    });
  };
  render() {
    return (
      <Layout>
        <Layout.Content>
          <div className="main-outer">
            <UserList
              users={this.state.users}
              selectUser={this.selectUser}
              unreadUsers={this.state.unreadUsers}
              userSelected={this.state.userSelected}
            />
            {this.state.userSelected && (
              <ChatBox
                user={this.state.userSelected}
                messages={this.state.messages}
                adminMessage={this.state.adminMessage}
                setAdminMessage={this.setAdminMessage}
                sendMessage={this.sendMessage}
                deleteChats={this.deleteChats}
              />
            )}
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}
