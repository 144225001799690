import React, { Component } from "react";
import axios from "axios";
import "../css/Reports.css";
import getUserDetail from "../utils/getUserDetail";
import NotPaidPopUp from "./Dashboard/NotPaidPopUp";
import submitForm from "../utils/form";
import UserContext from "./UserContext";
import { Explore } from "@material-ui/icons";

const API_URL = "https://data.merd.online:8000/tourist_reports/";
const REGISTERATION_URL = process.env.REACT_APP_REGISTERATION_URL;
// const API_URL = "http://localhost:8000/tourist_reports/";
export class TouristReports extends Component {
  static contextType = UserContext;
  constructor() {
    super();
    this.state = {
      files: [],
      cities: [],
      subscribedCities: [],
      subscriptionAlertOpen: false,
      oneTimeSubPopUpOpen: false,
      cancelPopUpOpen: false,
      subscribed: true,
      allPaid: false,
      notPaidOpen: false,
    };
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  componentDidMount() {
    const user = getUserDetail();
    let files = [];

    if (!user.username) {
      this.setState({ subscribed: false });
    } else {
      this.setState({ subscribed: true });
      this.setState({ subscribedCities: user.cities });
    }
    axios
      .get(API_URL, {
        params: {
          filename: "all-files",
        },
      })
      .then((res) => {
        console.log(res.data);
        files = res.data.reports;
        const cities = files.map((file) => file.split(".pdf")[0]);
        this.setState({ cities });
        this.setState({ files: res.data.reports });
      })
      .catch((err) => {
        console.log(err);
      });

    const allPaid = this.context.userData.subCombos.every((sub) => sub.active);
    this.setState({ allPaid, notPaidOpen: !allPaid });
  }

  handleSubscriptionAlert = () => {
    this.setState({ subscriptionAlertOpen: false });
  };
  showOneTimeSubPopUp = () => {
    this.setState({ oneTimeSubPopUpOpen: true });
  };
  handleCancelPopUp = (state) => {
    this.setState({ cancelPopUpOpen: state });
  };

  hideOneTimeSubPopUp = () => {
    this.setState({ oneTimeSubPopUpOpen: false });
  };

  downloadBlob = (blob, name) => {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    console.log(blobUrl);
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  };
  handleExport = (filename) => {
    axios
      .get(API_URL, {
        responseType: "blob",
        params: {
          filename,
        },
      })
      .then((res) => {
        // const blob = new Blob([res.data], { type: "application/pdf" });
        // this.downloadBlob(blob, "test.pdf");
        console.log(res);
        this.downloadBlob(res.data, filename);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleBuyOnce = (file) => {
    const report = file.split(".pdf")[0];
    const dataString = report + ":Tourist Report";
    submitForm(
      {
        data: dataString,
        amount: "120",
      },
      "_blank",
      REGISTERATION_URL
    );
    console.log(report.trim());
  };

  render() {
    return (
      <div style={{ width: "100vw" }}>
        <NotPaidPopUp
          open={this.state.notPaidOpen}
          setOpen={(status) => this.setState({ notPaidOpen: status })}
        />
        <div className="header ">
          {/* <div className="back-img">&nbsp;</div> */}
          <img
            className="headerImg mb-3 brightness-50 "
            src="https://images.pexels.com/photos/823696/pexels-photo-823696.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            alt="building"
          />
          <div className="header-text">
            <span className="heading text-5xl">Tourist Reports</span>
          </div>
        </div>
        <div className="reports-container">
          {this.state.files.length ? (
            <>
              <div className="city-report">
                <span className="recent-report text-gray-800 text-3xl font-semibold">
                  Recent Reports
                </span>
              </div>

              <br />
              <ul className="grid  md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 gap-4">
                {this.state.files.map((file, idx) => (
                  <li
                    key={idx}
                    className="p-4 border-2 border-slate-900 rounded-lg drop-shadow-xl bg-white cstm-paper-border"
                    style={{
                      backgroundColor: idx % 2 ? "white" : "",
                    }}
                  >
                    <div className="pdf-title">
                      <div className="pdf-title-icon">
                        <Explore
                          fontSize="large"
                          style={{ color: "#05A081" }}
                        />
                      </div>
                      <span className="text-gray-800 text-2xl font-semibold">
                        {file}
                      </span>
                    </div>
                    <br />
                    {this.context.userData.touristReportsCities.includes(
                      this.state.cities[idx]
                    ) ? (
                      <div className="pb-4 mt-4 font-semibold">
                        <button key={idx}>
                          <a onClick={() => this.handleExport(file)}>
                            Download
                          </a>
                          <i class="fa-solid ml-2 text-grey fa-download font-semibold"></i>
                        </button>
                      </div>
                    ) : (
                      <div className="flex gap-4 pb-4 mt-4 font-semibold">
                        <button className="grow">
                          <a onClick={() => this.handleBuyOnce(file)}>
                            Buy USD 120
                          </a>
                        </button>
                        {/* <button
                          key={idx}
                          disabled
                          className="disabled font-semibold"
                        >
                          Download{" "}
                          <i class="fa-solid ml-2 text-grey fa-download font-semibold"></i>
                        </button> */}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <h3>No tourist reports uploaded.</h3>
          )}
        </div>
      </div>
    );
  }
}

export default TouristReports;
