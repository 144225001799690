import React, { useRef } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import FCExporter from "fusioncharts/fusioncharts.widgets";

ReactFusioncharts.fcRoot(FusionCharts, charts, FusionTheme, FCExporter);

export default function ZoneWiseColumnChart({
  data,
  categoriesChosen,
  allCategories,
  year,
  city,
  isMarketSize,
  comparisonType,
  yearlyComparisonOptions,
  allData,
}) {
  let category = data
    .filter((area) => area.name !== "Total")
    .map((area) => ({ label: area.name }));
  let dataset = [],
    caption;
  switch (comparisonType) {
    case "yearly":
      for (let year of yearlyComparisonOptions.years) {
        const newdata = {
          seriesname: String(year),
          data: [],
        };
        for (let area of allData[year]) {
          if (area.name === "Total") continue;
          if (isMarketSize)
            newdata.data.push({
              value: area[`ms_${yearlyComparisonOptions.category}`],
            });
          else
            newdata.data.push({
              value: area[`mscap_${yearlyComparisonOptions.category}`],
            });
        }
        dataset.push(newdata);
        caption = `City: ${city}, Category: ${
          allCategories.find(
            (cat) => cat.id === yearlyComparisonOptions.category
          ).name
        }, Years: ${yearlyComparisonOptions.years.join(", ")}`;
      }
      break;
    default:
      dataset = [];
      const reqdCategories = allCategories.filter((cat) =>
        categoriesChosen.includes(cat.id)
      );
      for (let cat of reqdCategories) {
        const newdata = {
          seriesname: cat.name,
          data: [],
        };
        for (let area of data) {
          if (area.name === "Total") continue;
          if (isMarketSize) newdata.data.push({ value: area[`ms_${cat.id}`] });
          else newdata.data.push({ value: area[`mscap_${cat.id}`] });
        }
        dataset.push(newdata);
      }

      caption = `City: ${city}, Categories: ${reqdCategories
        .map((cat) => cat.name)
        .join(", ")}, Year: ${year}`;
  }
  const chartRef = useRef(null);

  const dataSource = {
    chart: {
      caption,

      yaxisname: isMarketSize
        ? "Market Size (USD)"
        : "Market Size / Capita (USD)",
      numvisibleplot: "8",
      showValues: "1",
      forceDecimals: "1",
      decimals: "1",
      // numberScaleValue: "1000000",
      // numberScaleUnit: "M",
      labeldisplay: "auto",
      theme: "fusion",
      numberprefix: "$",
      animation: "0",
      yAxisNameFontSize: "18",
      yAxisNameFontColor: "#3a3a3a",
      yAxisNameFontBold: "1",
      xAxisNameFontSize: "18",
      xAxisNameFontColor: "#3a3a3a",
      xAxisNameFontBold: "1",
      exportEnabled: "1",
      exportFormats: "PNG=Export as PNG",

      exportfilename: caption,
    },
    categories: [
      {
        category,
      },
    ],
    annotations: {
      autoScale: "1",
      groups: [
        {
          id: "detailed-line-chart",
          items: [
            {
              id: "new-id",
              type: "text",
              //Define the attributes needed to create a text annotation
              text: "<u>Source: INTELLIGENT INSIGHTS</u>",

              fillcolor: "#000000",
              fontsize: "12",
              bold: "1",
              wrap: "1",
              wrapWidth: "350",
              x: "$canvasEndX-100",
              y: "$canvasEndY + 100",
            },
          ],
        },
      ],
    },
    dataset,
  };

  const handleExport = () => {
    chartRef.current.chartObj.exportChart({
      exportFileName: caption,
    });
  };
  return (
    <div>
      <div className="w-100 d-flex align-items-center justify-content-end">
        <button onClick={handleExport}>Download Chart</button>
      </div>
      <ReactFusioncharts
        type="mscolumn2d"
        fcEvent-exportLinkedChart="handleExport"
        ref={chartRef}
        width="100%"
        height="400"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
}
