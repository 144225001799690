export default function getUserDetail() {
  const details = document.cookie;
  const cookieList = details.split(";");
  const reqdCookie = cookieList.find((cookie) =>
    cookie.includes("user-details")
  );
  let detailStr;
  if (reqdCookie) {
    detailStr = reqdCookie.split("=")[1];
  }
  try {
    const usrData = detailStr.split("$$");

    const username = usrData[0]
      .split(",")
      [usrData[0].split(",").length - 1].trim();

    let cities = [];
    let cityReports = [];
    let touristReports = [];
    let demographic = [];
    let categories = [];
    let subCombos = [];
    let allSubs = [];
    let catchments = [];

    console.log(usrData);

    for (const data of usrData) {
      const city = data.split(",")[0].trim();
      const category = data.split(",")[1].trim();
      allSubs.push({
        city,
        category,
      });
      console.log("active", data.split(",")[2]);
      cities.push(city);
      categories.push(category);
      if (category === "City Report") {
        cityReports.push(city);
      } else if (category === "Tourist Report") {
        touristReports.push(city);
      } else if (category === "Demographic") {
        demographic.push(city);
      } else {
        cityReports.push(city);
        touristReports.push(city);
        demographic.push(city);
        catchments.push(city);
        subCombos.push({
          city,
          category,
          active: true,
        });
      }
    }

    cities = [...new Set(cities)];
    categories = [...new Set(categories)];
    touristReports = [...new Set(touristReports)];
    demographic = [...new Set(demographic)];
    cityReports = [...new Set(cityReports)];
    catchments = [...new Set(catchments)];

    const data = {
      username,
      cities,
      categories,
      subCombos,
      touristReports,
      cityReports,
      demographic,
      catchments,
      allSubs,
    };
    console.log(data);

    return data;
  } catch (err) {
    console.warn(err, "user not logged in");
    return {
      username: false,
      cities: [],
      categories: [],
      subCombos: [],
      demographic: [],
      touristReports: [],
      cityReports: [],
      allSubs: [],
      catchments: [],
      err,
    };
  }
}
