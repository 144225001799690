import React, { useRef } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import FCExporter from "fusioncharts/fusioncharts.widgets";

// Resolves charts dependancy
ReactFusioncharts.fcRoot(FusionCharts, charts, FusionTheme, FCExporter);

export default function MarimekkoByZoneByNationality({
  type,
  regions,
  nationalities,
  city,
  year,
  category,
}) {
  const chartRef = useRef(null);

  const dataset = [];
  const sortedRegions = regions.sort((reg1, reg2) => reg1.id - reg2.id);
  for (let nationality of nationalities) {
    const newDataSet = {
      seriesname: nationality.nationality,
      data: [],
    };
    for (let region of sortedRegions) {
      if (type === "Market Size") {
        for (let key of Object.keys(region)) {
          if (
            key.includes("ms_") &&
            Number(key.split("_")[2]) === nationality.id &&
            Number(key.split("_")[1]) === category.id
          ) {
            newDataSet.data.push({
              value: region[key],
            });
          }
        }
      }
    }
    dataset.push(newDataSet);
  }
  const caption = `City: ${city}, Year: ${year}, Category: ${category.name}`;
  const dataSource = {
    chart: {
      caption,
      forceDecimals: "1",
      decimals: "1",
      yaxisname: "Market Size (USD)",
      xaxisname: `Regions (${city})`,
      stack100percent: "1",
      theme: "fusion",
      showXAxisPercentValues: "0",
      yaxismaxvalue: "1000000000",
      decimals: "1",
      drawcrossline: "0",
      numberprefix: "$",
      exportenabled: "1",
      exportFormats: "PNG=Export as PNG",

      exportfilename: caption,
      labelDisplay: "rotate",
      maxLabelHeight: "50",
      rotateValues: "1",
      legendIconBorderThickness: "3",
      yAxisNameFontSize: "18",
      yAxisNameFontColor: "#3a3a3a",
      yAxisNameFontBold: "1",
      xAxisNameFontSize: "18",
      xAxisNameFontColor: "#3a3a3a",
      xAxisNameFontBold: "1",
      captionPadding: "40",
    },
    categories: [
      {
        category: sortedRegions.map((region) => ({ label: region.name })),
      },
    ],
    annotations: {
      autoScale: "1",
      groups: [
        {
          id: "detailed-line-chart",
          items: [
            {
              id: "new-id",
              type: "text",
              //Define the attributes needed to create a text annotation
              text: "<u>Source: INTELLIGENT INSIGHTS</u>",

              fillcolor: "#000000",
              fontsize: "12",
              bold: "1",
              wrap: "1",
              wrapWidth: "350",
              x: "$canvasEndX-100",
              y: "$canvasEndY + 150",
            },
          ],
        },
      ],
    },
    dataset,
  };
  const handleExport = () => {
    chartRef.current.chartObj.exportChart({
      exportFileName: caption,
    });
  };
  return (
    <div>
      <div className="w-100 d-flex align-items-center justify-content-end">
        <button onClick={handleExport}>Download Chart</button>
      </div>
      <ReactFusioncharts
        type="marimekko"
        fcEvent-exportLinkedChart="handleExport"
        ref={chartRef}
        width="100%"
        height="600"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
}
