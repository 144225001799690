import React, { useReducer } from "react";
import { v4 as uuid } from "uuid";
import { Dropdown, Menu, Table, Radio, Divider, Checkbox } from "antd";
import { Modal, Paper } from "@material-ui/core";

import TitleHeader from "../TitleHeader";
import DetailedLineChart from "./Charts/DetailedLineChart";
import { ArrowRight, AvTimer, Close, NavigateNext } from "@material-ui/icons";
import getExcel from "../../../utils/getExcel";
import { useCallback } from "react";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ACTIONS = {
  CATEGORY_CHANGE: "CATEGORY_CHANGE",
  YEAR_CHANGE: "YEAR_CHANGE",
  AREA_CHANGE: "AREA_CHANGE",
  CHANGE_DISPLAY: "CHANGE_DISPLAY",
  YEARLY_MODAL_OPEN: "YEARLY_MODAL_OPEN",
  SET_YEARLY_OPTIONS: "SET_YEARLY_OPTIONS",
  YEARLY_MODAL_CLOSE: "YEARLY_MODAL_CLOSE",
  COMPARE_YEARS: "COMPARE_YEARS",
  CATG_MODAL_OPEN: "CATG_MODAL_OPEN",
  CATG_MODAL_CLOSE: "CATG_MODAL_CLOSE",
  SET_CATG_OPTIONS: "SET_CATG_OPTIONS",
  COMPARE_CATGS: "COMPARE_CATGS",
  SET_ERRORS: "SET_ERRORS",
};

function reducer(chartSelections, action) {
  switch (action.type) {
    case ACTIONS.CATEGORY_CHANGE:
      return { ...chartSelections, category: action.category };
    case ACTIONS.AREA_CHANGE:
      return { ...chartSelections, area: action.area, zone: action.zone };
    case ACTIONS.YEAR_CHANGE:
      return { ...chartSelections, year: action.year };
    case ACTIONS.ZONE_CHANGE:
      return { ...chartSelections, zone: action.zone };
    case ACTIONS.CHANGE_DISPLAY:
      return {
        ...chartSelections,
        viewRegionChart: !chartSelections.viewRegionChart,
      };
    case ACTIONS.YEARLY_MODAL_OPEN:
      return { ...chartSelections, yearlyModalOpen: true };
    case ACTIONS.YEARLY_MODAL_CLOSE:
      return { ...chartSelections, yearlyModalOpen: false };
    case ACTIONS.SET_YEARLY_OPTIONS:
      return {
        ...chartSelections,
        yearlyComparisonOptions: action.newYearComparisonOptions,
      };
    case ACTIONS.COMPARE_YEARS:
      return {
        ...chartSelections,
        comparisonType: "year",
        yearlyModalOpen: false,
      };
    case ACTIONS.CATG_MODAL_OPEN:
      return { ...chartSelections, catgModalOpen: true };
    case ACTIONS.CATG_MODAL_CLOSE:
      return { ...chartSelections, catgModalOpen: false };
    case ACTIONS.SET_CATG_OPTIONS:
      return {
        ...chartSelections,
        catgComparisonOptions: action.newCatgComparisonOptions,
      };
    case ACTIONS.COMPARE_CATGS:
      return {
        ...chartSelections,
        comparisonType: "category",
        catgModalOpen: false,
      };
    case ACTIONS.SET_ERRORS:
      return { ...chartSelections, errors: action.newErrors };

    default:
      // console.log();
      throw "ACTION INVALID";
  }
}

export default function DetailedComparisonTable({
  data,
  city,
  year,
  categories,
  nationality,
  monthsSelected,
  allData,
  getRegionData,
  getZoneDataOfRegion,
  getAllDataFormatted,
  columns,
  setToolTipOpen,
  toolTipOpen,
}) {
  console.log(categories);

  const allCategories = [
    ...categories.categories.map((cat) => ({ name: cat.name, id: cat.id })),
    ...categories.subcategories.map((subcat) => ({
      name: subcat.name,
      id: subcat.id,
    })),
    ...categories.subsubcategories.map((subsubcat) => ({
      name: subsubcat.name,
      id: subsubcat.id,
    })),
  ];

  const [chartSelections, dispatch] = useReducer(reducer, {
    category: allCategories[0].id,
    area: data.zones[0].region_id,
    zone: data.zones[0].id,
    year,
    viewRegionChart: true,
    yearlyModalOpen: false,
    catgModalOpen: false,
    regionModalOpen: false,
    yearlyComparisonOptions: {
      years: allData.map((year) => year.year).slice(0, 2),
      category: allCategories[0].id,
    },
    catgComparisonOptions: {
      year: year,
      categories: allCategories.map((cat) => cat.id),
    },
    errors: {},
    comparisonType: "year",
  });

  const rqdRegionData = getRegionData(data, allCategories, monthsSelected);

  const allDataFormatted = getAllDataFormatted(
    allData,
    city,
    nationality,
    allCategories,
    monthsSelected
  );

  function displaySubTable(row) {
    const dataSource = getZoneDataOfRegion(row, allCategories);

    return (
      <Paper
        style={{
          backgroundColor: "#002140",
          padding: "10px",
        }}
      >
        <Table
          columns={columns(false, allCategories, monthsSelected)}
          bordered
          scroll={{
            x: (monthsSelected.length + 1) * allCategories.length * 400 + 800,
            y: 300,
          }}
          dataSource={dataSource}
          pagination={false}
          rowClassName={(row, idx) =>
            row.name === "Total"
              ? "region-last-row"
              : idx % 2
              ? "region-light-row"
              : "region-dark-row"
          }
        />
      </Paper>
    );
  }

  function chooseYearForYearly(year) {
    const newYearComparisonOptions = {
      ...chartSelections.yearlyComparisonOptions,
    };
    const idx = newYearComparisonOptions.years.findIndex(
      (yearIn) => yearIn === year
    );
    if (idx === -1) newYearComparisonOptions.years.push(year);
    else newYearComparisonOptions.years.splice(idx, 1);
    if (newYearComparisonOptions.years.length >= 2) {
      const newErrors = { ...chartSelections.errors };

      if (newErrors.hasOwnProperty("yearErrors")) {
        delete newErrors["yearErrors"];
        dispatch({ type: ACTIONS.SET_ERRORS, newErrors });
      }
    }
    dispatch({ type: ACTIONS.SET_YEARLY_OPTIONS, newYearComparisonOptions });
  }

  function chooseCatForYearly(cat) {
    const newYearComparisonOptions = {
      ...chartSelections.yearlyComparisonOptions,
    };
    if (newYearComparisonOptions.category === cat) return;
    newYearComparisonOptions.category = cat;
    dispatch({ type: ACTIONS.SET_YEARLY_OPTIONS, newYearComparisonOptions });
  }
  const compareYears = useCallback(
    (options) => {
      if (options.years.length < 1) {
        const newErrors = { ...chartSelections.errors };
        newErrors.yearErrors = "Please Select Atleast 1 Year.";
        dispatch({ type: ACTIONS.SET_ERRORS, newErrors });
        return;
      }
      dispatch({ type: ACTIONS.COMPARE_YEARS });
    },
    [chartSelections.errors]
  );

  function YearComparisonModal({ open }) {
    return (
      <Modal open={open}>
        <div className="view-option-modal">
          <div className="top-header">
            <span>
              <AvTimer
                style={{
                  marginRight: "10px",
                  fontSize: "2rem",
                  color: "royalblue",
                  borderRadius: "50%",
                  backgroundColor: "#bbf7fc",
                }}
              />
              Compare Yearly Data
            </span>
            <Close
              onClick={() => dispatch({ type: ACTIONS.YEARLY_MODAL_CLOSE })}
              className="modal-close-button"
            />
          </div>
          <div className="display-options">
            {chartSelections.errors.hasOwnProperty("yearErrors") && (
              <div className="pop-top-error">
                {chartSelections.errors.yearErrors}
              </div>
            )}
            <div style={{ fontSize: "1.25rem" }}>
              <b>
                Region Chosen:{" "}
                <u>
                  {
                    rqdRegionData.find((reg) => reg.id === chartSelections.area)
                      .name
                  }
                </u>
              </b>
            </div>
            <Divider />
            <div style={{ fontSize: "1.25rem" }}>Select Any Two Years:</div>
            {allData.map((year) =>
              chartSelections.yearlyComparisonOptions.years.length >= 2 ? (
                chartSelections.yearlyComparisonOptions.years.includes(
                  year.year
                ) ? (
                  <div className="options-styled">
                    <Checkbox
                      className="w-100"
                      checked={true}
                      onClick={() => chooseYearForYearly(year.year)}
                    >
                      {year.year}
                    </Checkbox>
                  </div>
                ) : (
                  <div className="options-styled disabled-year">
                    <Checkbox className="w-100" disabled={true}>
                      {year.year}
                    </Checkbox>
                  </div>
                )
              ) : chartSelections.yearlyComparisonOptions.years.includes(
                  year.year
                ) ? (
                <div className="options-styled">
                  <Checkbox
                    className="w-100"
                    checked={true}
                    onClick={() => chooseYearForYearly(year.year)}
                  >
                    {year.year}
                  </Checkbox>
                </div>
              ) : (
                <div className="options-styled">
                  <Checkbox
                    className="w-100"
                    onClick={() => chooseYearForYearly(year.year)}
                  >
                    {year.year}
                  </Checkbox>
                </div>
              )
            )}
            <Divider />
            <div style={{ fontSize: "1.25rem" }}>Select Any Category:</div>
            {allCategories.map((cat) => (
              <div className="options-styled">
                <Radio
                  className="w-100"
                  checked={
                    chartSelections.yearlyComparisonOptions.category === cat.id
                  }
                  onClick={() => chooseCatForYearly(cat.id)}
                >
                  {cat.name}
                </Radio>
              </div>
            ))}
          </div>
          <div className="submit-btn-div">
            <div
              onClick={() =>
                compareYears(chartSelections.yearlyComparisonOptions)
              }
            >
              Compare Data <NavigateNext />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  const chooseCatgForCatgs = useCallback(
    (catId) => {
      const newCatgComparisonOptions = {
        ...chartSelections.catgComparisonOptions,
      };
      const idx = newCatgComparisonOptions.categories.findIndex(
        (cat) => cat === catId
      );
      if (idx === -1) {
        newCatgComparisonOptions.categories.push(catId);
      } else {
        newCatgComparisonOptions.categories.splice(idx, 1);
      }
      if (newCatgComparisonOptions.categories.length >= 2) {
        const newErrors = { ...chartSelections.errors };
        if (newErrors.hasOwnProperty("catgErrors")) {
          delete newErrors["catgErrors"];
          dispatch({ type: ACTIONS.SET_ERRORS, newErrors });
        }
      }
      dispatch({ type: ACTIONS.SET_CATG_OPTIONS, newCatgComparisonOptions });
    },
    [chartSelections.catgComparisonOptions]
  );
  const chooseYearForCatg = useCallback(
    (year) => {
      const newCatgComparisonOptions = {
        ...chartSelections.catgComparisonOptions,
      };
      if (newCatgComparisonOptions.year === year) return;
      newCatgComparisonOptions.year = year;
      dispatch({ type: ACTIONS.SET_CATG_OPTIONS, newCatgComparisonOptions });
    },
    [chartSelections.catgComparisonOptions]
  );

  const compareCatgs = useCallback(
    (options) => {
      if (options.categories.length < 1) {
        const newErrors = { ...chartSelections.errors };
        newErrors.catgErrors = "Please Select Atleast 1 Category.";
        dispatch({ type: ACTIONS.SET_ERRORS, newErrors });
        return;
      }

      dispatch({ type: ACTIONS.COMPARE_CATGS });
    },
    [chartSelections.errors]
  );

  const CatComparisonModal = useCallback(
    ({ open }) => {
      return (
        <Modal open={open}>
          <div className="view-option-modal">
            <div className="top-header">
              <span>
                <AvTimer
                  style={{
                    marginRight: "10px",
                    fontSize: "2rem",
                    color: "royalblue",
                    borderRadius: "50%",
                    backgroundColor: "#bbf7fc",
                  }}
                />
                Compare Yearly Data
              </span>
              <Close
                onClick={() => dispatch({ type: ACTIONS.CATG_MODAL_CLOSE })}
                className="modal-close-button"
              />
            </div>
            <div className="display-options">
              {chartSelections.errors.hasOwnProperty("catgErrors") && (
                <div className="pop-top-error">
                  {chartSelections.errors.catgErrors}
                </div>
              )}
              <div style={{ fontSize: "1.25rem" }}>
                <b>
                  Region Chosen:{" "}
                  <u>
                    {
                      rqdRegionData.find(
                        (reg) => reg.id === chartSelections.area
                      ).name
                    }
                  </u>
                </b>
              </div>
              <Divider />
              <div style={{ fontSize: "1.25rem" }}>Select Any Year:</div>
              {allData.map((year) => (
                <div className="options-styled">
                  <Radio
                    className="w-100"
                    checked={
                      chartSelections.catgComparisonOptions.year === year.year
                    }
                    onClick={() => chooseYearForCatg(year.year)}
                  >
                    {year.year}
                  </Radio>
                </div>
              ))}
              <Divider />
              <div style={{ fontSize: "1.25rem" }}>
                Select Any 2 Categories:
              </div>
              {allCategories.map((cat) =>
                chartSelections.catgComparisonOptions.categories.length >= 2 ? (
                  chartSelections.catgComparisonOptions.categories.includes(
                    cat.id
                  ) ? (
                    <div className="options-styled">
                      <Checkbox
                        className="w-100"
                        checked={true}
                        onClick={() => chooseCatgForCatgs(cat.id)}
                      >
                        {cat.name}
                      </Checkbox>
                    </div>
                  ) : (
                    <div className="options-styled disabled-year">
                      <Checkbox className="w-100" disabled={true}>
                        {cat.name}
                      </Checkbox>
                    </div>
                  )
                ) : chartSelections.catgComparisonOptions.categories.includes(
                    cat.id
                  ) ? (
                  <div className="options-styled">
                    <Checkbox
                      className="w-100"
                      checked={true}
                      onClick={() => chooseCatgForCatgs(cat.id)}
                    >
                      {cat.name}
                    </Checkbox>
                  </div>
                ) : (
                  <div className="options-styled">
                    <Checkbox
                      className="w-100"
                      onClick={() => chooseCatgForCatgs(cat.id)}
                    >
                      {cat.name}
                    </Checkbox>
                  </div>
                )
              )}
            </div>
            <div className="submit-btn-div">
              <div
                onClick={() =>
                  compareCatgs(chartSelections.catgComparisonOptions)
                }
              >
                Compare Data <NavigateNext />
              </div>
            </div>
          </div>
        </Modal>
      );
    },
    [chartSelections.catgComparisonOptions]
  );
  const title = `Months: ${
    monthsSelected.length === 12
      ? "The Whole Year"
      : monthsSelected.map((mon) => months[mon - 1]).join(", ")
  }, Categories: ${allCategories
    .map((cat) => cat.name)
    .join(", ")}, City: ${city}, Nationality: ${nationality}`;

  const Memoizedtable = React.memo((props) => <Table {...props} />);

  return (
    <Paper className="p-1 m-2">
      <Memoizedtable
        columns={columns(true, allCategories, monthsSelected)}
        bordered
        scroll={{
          x: (monthsSelected.length + 1) * allCategories.length * 400 + 800,
          y: 400,
        }}
        title={() => (
          <TitleHeader
            setToolTipOpen={setToolTipOpen}
            toolTipOpen={toolTipOpen}
            main={`Detailed Market Size, ${nationality}`}
            city={city}
            year={year}
            categories={allCategories.map((cat) => cat.name)}
            nationalities={nationality}
            months={
              monthsSelected.length == 12
                ? "The Whole Year"
                : monthsSelected.map((mon) => months[mon - 1])
            }
            handleExcelDownload={() =>
              getExcel(
                columns(true, allCategories, monthsSelected),
                allDataFormatted,
                title,
                getZoneDataOfRegion,
                allCategories,
                null
              )
            }
          />
        )}
        dataSource={rqdRegionData}
        pagination={false}
        expandable={{
          expandedRowRender: displaySubTable,
          rowExpandable: (row) => row.name !== "Total",
        }}
        rowClassName={(row, idx) =>
          row.name === "Total"
            ? "region-last-row"
            : idx % 2
            ? "region-light-row"
            : "region-dark-row"
        }
      />
      <div className="mt-2" key={uuid()}>
        <div key={uuid()}>
          <div className="d-flex justify-content-between" key={uuid()}>
            <div className="d-flex justify-content-start" key={uuid()}>
              {chartSelections.viewRegionChart ? (
                <Dropdown
                  key={uuid()}
                  overlay={
                    <Menu key={uuid()}>
                      <Radio.Group
                        key={uuid()}
                        value={chartSelections.area}
                        onChange={(e) =>
                          dispatch({
                            type: ACTIONS.AREA_CHANGE,
                            area: e.target.value,
                            zone: null,
                          })
                        }
                      >
                        {rqdRegionData.map((region) => (
                          <Menu.Item key={uuid()}>
                            <Radio key={uuid()} value={region.id}>
                              {region.name}
                            </Radio>
                          </Menu.Item>
                        ))}
                      </Radio.Group>
                    </Menu>
                  }
                >
                  <div className="dpdown-pill" key={uuid()}>
                    Choose Region <ArrowRight key={uuid()} />
                  </div>
                </Dropdown>
              ) : (
                <Dropdown
                  key={uuid()}
                  overlay={
                    <Menu key={uuid()}>
                      <Radio.Group
                        key={uuid()}
                        value={chartSelections.zone}
                        onChange={(e) =>
                          dispatch({
                            type: ACTIONS.ZONE_CHANGE,
                            zone: e.target.value,
                          })
                        }
                      >
                        {getZoneDataOfRegion(
                          rqdRegionData.find(
                            (reg) => reg.id === chartSelections.area
                          ),
                          allCategories
                        ).map((zone) => (
                          <Menu.Item key={uuid()}>
                            <Radio key={uuid()} value={zone.id}>
                              {zone.name}
                            </Radio>
                          </Menu.Item>
                        ))}
                      </Radio.Group>
                    </Menu>
                  }
                >
                  <div className="dpdown-pill" key={uuid()}>
                    Choose Zone <ArrowRight key={uuid()} />
                  </div>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
        <DetailedLineChart
          area={chartSelections.area}
          category={allCategories.find(
            (cat) => cat.id === chartSelections.yearlyComparisonOptions.category
          )}
          allCategories={allCategories}
          catgComparisonOptions={chartSelections.catgComparisonOptions}
          nationality={nationality}
          yearlyComparisonOptions={chartSelections.yearlyComparisonOptions}
          allData={allDataFormatted}
          isRegionWise={chartSelections.viewRegionChart}
          comparisonType={"category"}
          monthsSelected={monthsSelected}
        />
      </div>
      <YearComparisonModal open={chartSelections.yearlyModalOpen} />
      <CatComparisonModal open={chartSelections.catgModalOpen} />
    </Paper>
  );
}
