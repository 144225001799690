import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { isArray } from "lodash";

async function createTable(
  ws,
  tableStartCell,
  columns,
  yearData,
  title,
  getZoneDataOfRegion,
  detailedAllCategories
) {
  const dataSource = yearData;
  const zonalColumns = [...columns];
  zonalColumns.splice(1, 0, {
    titleText: "Zone",
    rSpan: columns[0].rSpan,
    dataIndex: "zone",
  });
  // console.log(zonalColumns);
  const newDatasource = [];
  // console.log(columns, dataSource);
  if (detailedAllCategories) {
    for (let row of dataSource) {
      if (row.name !== "Total") {
        for (let zone of getZoneDataOfRegion(row, detailedAllCategories)) {
          if (zone.name !== "Total") {
            newDatasource.push({
              ...zone,
              zone: zone.name,
              name: row.name,
            });
          }
        }
      }
    }
  } else {
    for (let row of dataSource) {
      if (row.name !== "Total") {
        for (let zone of getZoneDataOfRegion(row)) {
          if (zone.name !== "Total") {
            newDatasource.push({
              ...zone,
              zone: zone.name,
              name: row.name,
            });
          }
        }
      }
    }
  }
  newDatasource.push({
    ...dataSource[dataSource.length - 1],
    name: "Total",
    zone: "Total",
  });

  function columnMaker(children, startCell, size) {
    for (let col of children) {
      const cell = ws.getCell(startCell[0], startCell[1]);
      cell.value = col.titleText;
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = {
        size,
        bold: true,
      };
      cell.border = {
        top: { style: "thick", color: { argb: "00000000" } },
        left: { style: "thick", color: { argb: "00000000" } },
        bottom: { style: "thick", color: { argb: "00000000" } },
        right: { style: "thick", color: { argb: "00000000" } },
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9CC6F0" },
      };
      if (col.hasOwnProperty("rSpan")) {
        const span = col.rSpan;
        const lastCellAddress = ws.getCell(
          startCell[0] + (span - 1),
          startCell[1]
        )._address;
        ws.mergeCells(`${cell._address}:${lastCellAddress}`);
      }
      if (col.hasOwnProperty("cSpan")) {
        const span = col.cSpan;
        const lastCellAddress = ws.getCell(
          startCell[0],
          startCell[1] + (span - 1)
        )._address;
        ws.mergeCells(`${cell._address}:${lastCellAddress}`);
        if (col.hasOwnProperty("children")) {
          if (isArray(col.children)) {
            columnMaker(
              col.children,
              [startCell[0] + 1, startCell[1]],
              size - 1
            );
          }
        }
        startCell = [startCell[0], startCell[1] + span];
      } else {
        startCell = [startCell[0], startCell[1] + 1];
      }
    }
  }

  let firstCell = [...tableStartCell];
  columnMaker(zonalColumns, firstCell, 16);

  //after insertion of columns
  const colLastCell = [ws.lastRow._number, ws.lastColumn._number];
  //merging for title

  const titleFirstCell = ws.getCell(1, tableStartCell[1]);
  ws.getRow(1).height = 45;

  const titleLastCell = ws.getCell(1, colLastCell[1]);
  ws.mergeCells(`${titleFirstCell._address}:${titleLastCell._address}`);

  const startRow = tableStartCell[0] + columns[0].rSpan;

  function createData(children, coords, dataRow) {
    children.forEach((col) => {
      if (col.hasOwnProperty("children")) {
        if (isArray(col.children)) {
          createData(col.children, coords, dataRow);
          coords[2]++;
        }
      } else {
        const cell = ws.getCell(startRow + coords[0], coords[1]);
        cell.value = dataRow[col.dataIndex];
        cell.alignment = { vertical: "middle", horizontal: "center" };

        if (col.hasOwnProperty("numFmt")) {
          cell.numFmt = col.numFmt;
        }
        coords[1]++;
      }
    });
  }

  for (let i = 0; i < newDatasource.length; i++) {
    const dataRow = newDatasource[i];
    let coords = [i, tableStartCell[1], 0];
    createData(zonalColumns, coords, dataRow);
  }

  const lastRow = startRow + newDatasource.length - 1;
  const botLeftCell = ws.getCell(lastRow, tableStartCell[1]);
  const botLeftSecondCell = ws.getCell(lastRow, tableStartCell[1] + 1);
  ws.mergeCells(`${botLeftCell._address}:${botLeftSecondCell._address}`);

  for (let i = tableStartCell[1]; i <= colLastCell[1]; i++) {
    const cell = ws.getCell(2, i);
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    };
    cell.font = {
      color: { argb: "FFFFFF" },
      ...cell?.font,
    };
    const lastRowCell = ws.getCell(lastRow, i);
    lastRowCell.font = {
      bold: true,
    };
    lastRowCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "DDEBF7" },
    };
  }

  for (let i = startRow; i < newDatasource.length + 5; i++) {
    ws.getRow(i).height = 30;
  }

  const titleCell = ws.getCell(1, tableStartCell[1]);
  titleCell.value = title;
  titleCell.font = {
    bold: true,
    size: 16,
    underline: true,
  };
  titleCell.alignment = {
    vertical: "middle",
    // horizontal: "center",
  };
}

export default async function getExcel(
  columns,
  years,
  title,
  getZoneDataOfRegion,
  detailedAllCategories,
  alreadyWb
) {
  let wb = new Workbook();
  if (alreadyWb) {
    wb = alreadyWb;
  }

  let ws;
  if (detailedAllCategories) {
    const titleSplit = title.split(",");
    ws = wb.addWorksheet(
      titleSplit[titleSplit.length - 1].split(":")[1].trim()
    );
    console.log(titleSplit[titleSplit.length - 1].split(":")[1].trim());
  } else {
    ws = wb.addWorksheet();
  }
  ws.properties.defaultColWidth = 40;
  ws.properties.defaultRowHeight = 30;

  for (let year in years) {
    let startCell;
    try {
      startCell = [2, ws.lastColumn._number + 2];
    } catch {
      startCell = [2, 1];
    }
    createTable(
      ws,
      startCell,
      columns,
      years[year],
      `Year: ${year}, ${title}`,
      getZoneDataOfRegion,
      detailedAllCategories
    );
  }
  const lastRow = ws.lastRow._number;
  const wmbotLeftCell = ws.getCell(lastRow + 1, 1);
  const wmbotLeftSecondCell = ws.getCell(lastRow + 1, 2);
  ws.mergeCells(`${wmbotLeftCell._address}:${wmbotLeftSecondCell._address}`);
  const cell = ws.getCell(lastRow + 1, 1);
  cell.value = "Source: INTELLIGENT INSIGHTS";
  cell.font = {
    bold: true,
    size: 16,
    underline: true,
  };
  cell.alignment = { vertical: "middle", horizontal: "center" };

  if (!alreadyWb) {
    const buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `Data_Years_${Object.keys(years).join("_")}.xlsx`);
  }
}
