export function getDeleteIconSvg() {
    return `
	<svg version="1.1" viewBox="0 0 384 384">
		<path
		d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
		<polygon
		points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 			" />
	</svg>
	`;
}

export function getEditIconSvg() {
    return `
	<svg version="1.1" viewBox="0 0 383.947 383.947">
		<polygon points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893" />
		<path
		d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04C386.027,77.92,386.027,64.373,377.707,56.053z" />
	</svg>
	`;
}

export function getPlusIconSvg() {
    return `
	<svg version="1.1" viewBox="0 0 42 42">
		<path d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059
		C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z" />
	</svg>
	`;
}
