import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import getExcel from "./getExcel";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default async function getDetailedExcel(
  columns,
  allData,
  getAllDataFormatted,
  getZoneDataOfRegion
) {
  const wb = new Workbook();

  const natDone = [];

  for (let year of allData) {
    for (let nationality of year.nationalities) {
      if (natDone.includes(nationality.nationality)) continue;
      for (let city of nationality.cities) {
        const allCategories = [
          ...year.categories.categories.map((cat) => ({
            name: cat.name,
            id: cat.id,
          })),
          ...year.categories.subcategories.map((subcat) => ({
            name: subcat.name,
            id: subcat.id,
          })),
          ...year.categories.subsubcategories.map((subsubcat) => ({
            name: subsubcat.name,
            id: subsubcat.id,
          })),
        ];

        const title = `Months: ${
          year.months.length === 12
            ? "The Whole Year"
            : year.months.map((mon) => months[mon - 1])
        }, Categories: ${allCategories.map((cat) => cat.name)}, City: ${
          city.name
        }, Nationality: ${nationality.nationality}`;

        const allDataFormatted = getAllDataFormatted(
          allData,
          city.name,
          nationality.nationality,
          allCategories,
          year.months
        );
        const regionColumns = columns(true, allCategories, year.months);
        getExcel(
          regionColumns,
          allDataFormatted,
          title,
          getZoneDataOfRegion,
          allCategories,
          wb
        );
        natDone.push(nationality.nationality);
      }
    }
  }
  const buf = await wb.xlsx.writeBuffer();
  saveAs(
    new Blob([buf]),
    `Data_Years_${allData.map((year) => year.year).join("_")}.xlsx`
  );
}
