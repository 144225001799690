import { Paper } from "@material-ui/core";
import { Dropdown, Menu, Radio, Table } from "antd";
import { round } from "lodash";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import getExcel from "../../../utils/getExcel";
import TitleHeader from "../TitleHeader";
import MarimekkoByZoneByNationality from "./Charts/MarimekkoByZoneByNationality";
import { ArrowRight } from "@material-ui/icons";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function NationalityZoneTable({
  zones,
  year,
  city,
  nationalities,
  categories,
  monthsSelected,
  allData,
  toolTipOpen,
  setToolTipOpen,
}) {
  const allCategories = [
    ...categories.categories.map((cat) => ({ name: cat.name, id: cat.id })),
    ...categories.subcategories.map((subcat) => ({
      name: subcat.name,
      id: subcat.id,
    })),
    ...categories.subsubcategories.map((subsubcat) => ({
      name: subsubcat.name,
      id: subsubcat.id,
    })),
  ];

  const [categorySelected, setCategorySelected] = useState(
    allCategories[0].name
  );

  const columns = (isRegionWise) => {
    const titleFormatter = (title) =>
      isRegionWise ? (
        <h4 style={{ fontWeight: 700, textAlign: "center" }}>{title}</h4>
      ) : (
        <h6 style={{ textAlign: "center" }}>{title}</h6>
      );
    const dataFormatter = (data) =>
      isRegionWise ? <span style={{ fontWeight: "500" }}>{data}</span> : data;
    return [
      {
        title: titleFormatter(isRegionWise ? "Region" : "Zone"),
        dataIndex: "name",
        key: isRegionWise ? "region" : "zone",
        width: 300,
        fixed: "left",
        className: "column-custom",
        titleText: isRegionWise ? "Region" : "Zone",
        rSpan: allCategories.length > 1 ? 3 : 2,

        render: (x) => dataFormatter(x),
        onCell: (cell) =>
          cell.name === "Total" ? { className: "region-last-row" } : "",
      },
      ...nationalities
        .sort((nat1, nat2) => nat1.id - nat2.id)
        .map((nat) => ({
          title: titleFormatter(nat.nationality),
          titleText: nat.nationality,
          key: uuid(),
          className: "column-custom bordered",
          cSpan: allCategories.length * 2 + 1,
          children: [
            {
              title: "Population",
              className: "column-custom",
              rSpan: allCategories.length > 1 ? 2 : 1,
              titleText: "Population",
              numFmt: "#,##0",

              dataIndex: `population_${nat.id}`,
              render: (x) =>
                !x
                  ? "N/A"
                  : dataFormatter(
                      `${x.toLocaleString("en", { useGrouping: true })}`
                    ),
              key: uuid(),
            },
            ...(allCategories.length > 1
              ? allCategories.map((cat) => ({
                  title: cat.name,
                  titleText: cat.name,
                  cSpan: 2,
                  key: uuid(),
                  className: "column-custom",

                  children: [
                    {
                      title: "Market Size (USD)",
                      titleText: "Market Size (USD)",
                      key: uuid(),
                      className: "column-custom",
                      numFmt: "$#,##0",
                      dataIndex: `ms_${cat.id}_${nat.id}`,
                      render: (x) =>
                        !x
                          ? "N/A"
                          : dataFormatter(
                              `$${x.toLocaleString("en", {
                                useGrouping: true,
                              })}`
                            ),
                    },
                    {
                      title: "Market Size/Capita (USD)",
                      titleText: "Market Size/Capita (USD)",
                      key: uuid(),
                      className: "column-custom",
                      numFmt: "$#,##0",
                      dataIndex: `mscap_${cat.id}_${nat.id}`,
                      render: (x) =>
                        !x
                          ? "N/A"
                          : dataFormatter(
                              `$${x.toLocaleString("en", {
                                useGrouping: true,
                              })}`
                            ),
                    },
                  ],
                }))
              : [
                  {
                    title: "Market Size (USD)",
                    titleText: "Market Size (USD)",
                    key: uuid(),
                    dataIndex: `ms_${allCategories[0].id}_${nat.id}`,
                    className: "column-custom",
                    numFmt: "$#,##0",
                    render: (x) =>
                      !x
                        ? "N/A"
                        : dataFormatter(
                            `$${x.toLocaleString("en", { useGrouping: true })}`
                          ),
                  },
                  {
                    title: "Market Size/Capita (USD)",
                    titleText: "Market Size/Capita (USD)",
                    key: uuid(),
                    dataIndex: `mscap_${allCategories[0].id}_${nat.id}`,
                    className: "column-custom",
                    numFmt: "$#,##0",
                    render: (x) =>
                      !x
                        ? "N/A"
                        : dataFormatter(
                            `$${x.toLocaleString("en", { useGrouping: true })}`
                          ),
                  },
                ]),
          ],
        })),
      {
        title: titleFormatter("Total"),
        titleText: "Total",
        key: uuid(),
        className: "column-custom bordered",
        cSpan: allCategories.length * 2 + 1,
        children: [
          {
            title: "Population",
            titleText: "Population",
            rSpan: allCategories.length > 1 ? 2 : 1,
            numFmt: "#,##0",

            dataIndex: `population_Total`,
            className: "column-custom",
            onCell: (cell, idx) =>
              cell.name === "Total"
                ? { className: "region-last-row" }
                : idx % 2
                ? { classname: "total-light-blue" }
                : { className: "total-dark-blue" },
            render: (x) =>
              !x
                ? "N/A"
                : dataFormatter(
                    `${x.toLocaleString("en", { useGrouping: true })}`
                  ),
            key: uuid(),
          },
          ...(allCategories.length > 1
            ? allCategories.map((cat) => ({
                title: cat.name,
                titleText: cat.name,
                cSpan: 2,
                key: uuid(),
                className: "column-custom",

                children: [
                  {
                    title: "Market Size (USD)",
                    titleText: "Market Size (USD)",
                    key: uuid(),

                    dataIndex: `ms_${cat.id}_Total`,
                    className: "column-custom",
                    numFmt: "$#,##0",
                    render: (x) =>
                      !x
                        ? "N/A"
                        : dataFormatter(
                            `$${x.toLocaleString("en", { useGrouping: true })}`
                          ),
                  },
                  {
                    title: "Market Size/Capita (USD)",
                    titleText: "Market Size/Capita (USD)",
                    key: uuid(),
                    className: "column-custom",
                    numFmt: "$#,##0",
                    dataIndex: `mscap_${cat.id}_Total`,
                    render: (x) =>
                      !x
                        ? "N/A"
                        : dataFormatter(
                            `$${x.toLocaleString("en", { useGrouping: true })}`
                          ),
                  },
                ],
              }))
            : [
                {
                  title: "Market Size (USD)",
                  titleText: "Market Size (USD)",
                  numFmt: "$#,##0",
                  key: uuid(),
                  className: "column-custom",
                  onCell: (cell, idx) =>
                    cell.name === "Total"
                      ? { className: "region-last-row" }
                      : idx % 2
                      ? { classname: "total-light-blue" }
                      : { className: "total-dark-blue" },

                  dataIndex: `ms_${allCategories[0].id}_Total`,
                  render: (x) =>
                    !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),
                },
                {
                  title: "Market Size/Capita (USD)",
                  titleText: "Market Size/Capita (USD)",
                  numFmt: "$#,##0",
                  key: uuid(),
                  className: "column-custom",
                  onCell: (cell, idx) =>
                    cell.name === "Total"
                      ? { className: "region-last-row" }
                      : idx % 2
                      ? { classname: "total-light-blue" }
                      : { className: "total-dark-blue" },
                  dataIndex: `mscap_${allCategories[0].id}_Total`,
                  render: (x) =>
                    !x
                      ? "N/A"
                      : dataFormatter(
                          `$${x.toLocaleString("en", { useGrouping: true })}`
                        ),
                },
              ]),
        ],
      },
    ];
  };

  function getDataSource(zones) {
    const dataSource = [];

    for (let zone of zones) {
      const idx = dataSource.findIndex((reg) => reg.id === zone.region_id);
      if (idx === -1) {
        const newRegion = {
          id: zone.region_id,
          name: zone.region_name,
          key: zone.region_id,
          zones: [zone],
        };
        for (let nationality of zone.nationalities) {
          newRegion[`population_${nationality.id}`] = round(
            nationality.population,
            -2
          );
          for (let cat of allCategories) {
            const reqdCategory = nationality.market_size_data.find(
              (ms) => ms.name === cat.name
            );
            if (reqdCategory) {
              newRegion[`ms_${cat.id}_${nationality.id}`] = round(
                reqdCategory.market_size,
                -3
              );
            } else {
              newRegion[`ms_${cat.id}_${nationality.id}`] = 0;
            }
            newRegion[`mscap_${cat.id}_${nationality.id}`] = 0;
          }
        }
        dataSource.push(newRegion);
      } else {
        const reqdRegion = dataSource[idx];
        reqdRegion.zones.push(zone);
        for (let nationality of zone.nationalities) {
          reqdRegion[`population_${nationality.id}`] += round(
            nationality.population,
            -2
          );
          for (let cat of allCategories) {
            const reqdCategory = nationality.market_size_data.find(
              (ms) => ms.name === cat.name
            );
            if (reqdCategory) {
              reqdRegion[`ms_${cat.id}_${nationality.id}`] += round(
                reqdCategory.market_size,
                -3
              );
            }
          }
        }
      }
    }

    for (let region of dataSource) {
      let regionPopulation = 0;
      for (let key of Object.keys(region)) {
        if (key.includes("population")) regionPopulation += region[key];
      }
      region["population_Total"] = regionPopulation;
    }

    console.log(dataSource);

    const totalRegion = Object.fromEntries(
      Object.keys(dataSource[0]).map((k) => [k, 0])
    );
    totalRegion.name = "Total";

    for (let region of dataSource) {
      for (let key of Object.keys(region)) {
        if (key !== "name" && !key.includes("mscap_")) {
          // console.log(key);
          totalRegion[key] += region[key];
        }
        if (key.includes("ms_")) {
          const catId = key.split("_")[1].trim();
          const totalKey = `ms_${catId}_Total`;
          if (region.hasOwnProperty(totalKey)) region[totalKey] += region[key];
          else region[totalKey] = region[key];
          if (totalRegion.hasOwnProperty(totalKey))
            totalRegion[totalKey] += region[key];
          else totalRegion[totalKey] = region[key];

          //const totalMsRegionKey = key.replace(key.split("_")[2], "Total");
          const msCapKey = key.replace("ms_", "mscap_");
          const natId = key.split("_")[2].trim();
          const popKey = Object.keys(region).find(
            (regKey) =>
              regKey.split("_")[0].trim() === "population" &&
              regKey.split("_")[1].trim() === natId
          );

          const population = region[popKey];

          const msCap = round(region[key] / population) || 0;
          region[msCapKey] = msCap;
        }
      }
    }
    dataSource.push(totalRegion);
    for (let region of dataSource) {
      for (let key of Object.keys(region)) {
        if (key.includes("ms_")) {
          const msCapKey = key.replace("ms_", "mscap_");
          const natId = key.split("_")[2].trim();
          const popKey = Object.keys(totalRegion).find(
            (regKey) =>
              regKey.split("_")[0].trim() === "population" &&
              regKey.split("_")[1].trim() === natId
          );

          const population = region[popKey];

          const msCap = round(region[key] / population) || 0;
          region[msCapKey] = msCap;
        }
      }
    }

    dataSource.sort((reg1, reg2) => reg1.id - reg2.id);

    return dataSource;
  }

  const allDataFormatted = {};
  const dataSource = getDataSource(zones);
  for (let year of allData) {
    for (let _city of year.cities) {
      if (_city.city === city) {
        allDataFormatted[year.year] = getDataSource(_city.zones);
      }
    }
  }

  function getZoneDataOfRegion(row) {
    const dataSource = [];

    for (let zone of row.zones) {
      const newZone = {
        name: zone.zone,
        id: zone.id,
        key: zone.id,
      };
      let totalPopulation = 0;
      for (let nationality of zone.nationalities) {
        newZone[`population_${nationality.id}`] = round(
          nationality.population,
          -2
        );
        totalPopulation += round(nationality.population, -2);
        for (let ms of nationality.market_size_data) {
          const reqdCategory = allCategories.find(
            (cat) => ms.name === cat.name
          );
          if (reqdCategory) {
            newZone[`ms_${reqdCategory.id}_${nationality.id}`] = round(
              ms.market_size,
              -3
            );
            const msCap = round(ms.market_size / nationality.population) || 0;
            newZone[`mscap_${reqdCategory.id}_${nationality.id}`] = msCap;
            if (newZone.hasOwnProperty(`ms_${reqdCategory.id}_Total`)) {
              newZone[`ms_${reqdCategory.id}_Total`] += round(
                ms.market_size,
                -3
              );
            } else {
              newZone[`ms_${reqdCategory.id}_Total`] = round(
                ms.market_size,
                -3
              );
            }
          } else {
            newZone[`ms_${reqdCategory.id}_${nationality.id}`] = 0;
            newZone[`mscap_${reqdCategory.id}_${nationality.id}`] = 0;
          }
        }
      }
      newZone["population_Total"] = totalPopulation;
      for (let key of Object.keys(newZone)) {
        if (key.includes("ms_") && key.includes("Total")) {
          const msCapKey = key.replace("ms_", "mscap_");
          const msCap = round(newZone[key] / totalPopulation) || 0;
          newZone[msCapKey] = msCap;
        }
      }
      dataSource.push(newZone);
    }
    dataSource.sort((zon1, zon2) => zon1.id - zon2.id);
    const totalZone = { ...row };
    totalZone.name = "Total";
    totalZone.zones = null;
    dataSource.push(totalZone);
    return dataSource;
  }

  function displaySubTable(row) {
    console.log(row.zones);

    const dataSource = getZoneDataOfRegion(row);

    return (
      <Paper key={row.id}>
        <Table
          columns={columns(false)}
          dataSource={dataSource}
          bordered
          pagination={false}
          scroll={{
            x:
              (nationalities.length + 1) * (1 + allCategories.length * 2) * 250,
            y: 300,
          }}
          rowClassName={(row, idx) =>
            row.name === "Total"
              ? "region-last-row"
              : idx % 2
              ? "region-light-row"
              : "region-dark-row"
          }
        />
      </Paper>
    );
  }

  const title = `Months: ${
    monthsSelected.length === 12
      ? "The Whole Year"
      : monthsSelected.map((mon) => months[mon - 1]).join(", ")
  }, Categories: ${allCategories
    .map((cat) => cat.name)
    .join(", ")}, City: ${city}, Nationalities: ${nationalities
    .map((nat) => nat.nationality)
    .join(", ")}`;

  return (
    <Paper className="p-1 mt-2">
      <Table
        title={() => (
          <TitleHeader
            main={"By Zone By Nationality"}
            toolTipOpen={toolTipOpen}
            setToolTipOpen={setToolTipOpen}
            city={city}
            year={year}
            categories={allCategories.map((cat) => cat.name)}
            nationalities={nationalities.map((nat) => nat.nationality)}
            months={
              monthsSelected.length == 12
                ? "The Whole Year"
                : monthsSelected.map((mon) => months[mon - 1])
            }
            handleExcelDownload={() =>
              getExcel(
                columns(true),
                allDataFormatted,
                title,
                getZoneDataOfRegion,
                null,
                null
              )
            }
          />
        )}
        dataSource={dataSource}
        expandable={{
          expandedRowRender: displaySubTable,
          rowExpandable: (row) => row.name !== "Total",
        }}
        rowClassName={(row, idx) =>
          row.name === "Total"
            ? "region-last-row"
            : idx % 2
            ? "region-light-row"
            : "region-dark-row"
        }
        bordered
        columns={columns(true)}
        pagination={false}
        scroll={{
          x: (nationalities.length + 1) * (1 + allCategories.length * 2) * 250,
          y: 350,
        }}
      />
      <div className="mt-2">
        <div className="d-flex justify-content-between" key={uuid()}>
          <div className="d-flex justify-content-start" key={uuid()}>
            {allCategories.length > 1 && (
              <Dropdown
                key={uuid()}
                overlay={
                  <Menu key={uuid()}>
                    <Radio.Group
                      key={uuid()}
                      value={categorySelected}
                      onChange={(e) => setCategorySelected(e.target.value)}
                    >
                      {allCategories.map((cat) => (
                        <Menu.Item key={uuid()}>
                          <Radio key={uuid()} value={cat.name}>
                            {cat.name}
                          </Radio>
                        </Menu.Item>
                      ))}
                    </Radio.Group>
                  </Menu>
                }
              >
                <div className="dpdown-pill" key={uuid()}>
                  Choose Category <ArrowRight key={uuid()} />
                </div>
              </Dropdown>
            )}
          </div>
        </div>
        <MarimekkoByZoneByNationality
          type={"Market Size"}
          regions={dataSource.filter((reg) => reg.name !== "Total")}
          nationalities={nationalities}
          city={city}
          year={year}
          category={allCategories.find((cat) => cat.name === categorySelected)}
        />
      </div>
    </Paper>
  );
}
