import { createContext } from "react";

const UserContext = createContext({
  username: false,
  subCombos: [],
  demographicCities: [],
  touristReportsCities: [],
  cityReportsCities: [],
  categories: [],
  cities: [],
  catchments: [],
  catchmentCities: [],
  someNotActive: false,
});
export default UserContext;
